import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';
import GeneralError from '../../frontend/components/GeneralError';
import Features from '../../shared/helpers/Features';
import LocationClosed from '../components/LocationClosed';
import ServicesUnavailable from '../components/ServicesUnavailable';
import { MEETING_METHODS } from '../constants';
import { LocationContext } from '../contexts/LocationContext';
import { MeetingMethodContext } from '../contexts/MeetingMethodContext';
import { VendorContext } from '../contexts/VendorContext';
import CallbackService from '../pages/CallBackService';
import LocationSearch from '../pages/LocationSearch';
import Manage from '../pages/Manage';
import WaitTimeWebsocket from '../websockets/WaitTimeWebsocket';
import WalkInWebsocket from '../websockets/WalkInWebsocket';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: `
      ${theme.typography.pxToRem(theme.spacing(2))}
      ${theme.typography.pxToRem(theme.spacing(2))}
      ${theme.typography.pxToRem(theme.spacing(6))}
      ${theme.typography.pxToRem(theme.spacing(2))}
    `,
  },
  searchContainer: {
    height: `calc(100% - ${theme.typography.pxToRem(theme.spacing(8))})`,
  },
}));

const Page = () => {
  const { vendor } = useContext(VendorContext);
  const { location } = useContext(LocationContext);
  const { meetingMethod } = useContext(MeetingMethodContext);

  const classes = useStyles();
  const showServicesUnavailable = () => {
    if (!location) {
      return false;
    }

    if (
      !Features.isCoconutConnectLobbyEnabled(vendor) &&
      meetingMethod === MEETING_METHODS.VIDEO
    ) {
      return true;
    }

    if (!Features.isAppointmentMeetingMethodsEnabled(vendor)) {
      return meetingMethod !== MEETING_METHODS.AT_BUSINESS;
    }

    return false;
  };

  if (!Features.isLobbyEnabled(vendor)) {
    const title = <FormattedMessage id="CallbackRequest.not_available" />;
    const subtitle = (
      <FormattedMessage id="CallbackRequest.callback_request_disabled" />
    );

    return (
      <GeneralError
        bookAnother={false}
        icon
        subtitle={subtitle}
        title={title}
      />
    );
  }

  const onManagePage =
    window.location?.href?.includes('/manage') ||
    window.location?.href?.includes('/confirmation');

  if (showServicesUnavailable() && !onManagePage) {
    return (
      <Grid className={classes.formContainer} container justify="center">
        <ServicesUnavailable />
      </Grid>
    );
  }

  const callbackService = () => {
    if (
      location &&
      location.restrict_callback_request_hours &&
      !location.is_currently_open
    ) {
      return <LocationClosed />;
    }

    if (!location) {
      return (
        <Grid className={classes.searchContainer} container justify="center">
          <LocationSearch />
        </Grid>
      );
    }

    return (
      <Grid className={classes.formContainer} container justify="center">
        <CallbackService />
      </Grid>
    );
  };

  const manage = (
    <Grid className={classes.formContainer} container justify="center">
      <Manage />
    </Grid>
  );

  return (
    <WalkInWebsocket>
      <WaitTimeWebsocket>
        <Switch>
          <Route exact path="/callback-service/manage" render={() => manage} />
          <Route
            exact
            path="/callback-service/confirmation"
            render={() => manage}
          />
          <Route
            exact
            path="/callback-service/:id"
            render={() => callbackService()}
          />
          <Route
            exact
            path="/callback-service"
            render={() => callbackService()}
          />
        </Switch>
      </WaitTimeWebsocket>
    </WalkInWebsocket>
  );
};

export default Page;
