export default {
    "Alert.error": "Ошибка",
    "Alert.info": "Сведения",
    "Alert.success": "успешно",
    "Alert.warning": "Предупреждение",
    "AppointmentCancelled.title": "Эта встреча отменена",
    "BackButton.back": "Назад",
    "BackButton.back_results": "Вернуться к результатам",
    "CallbackRequest.callback_request_disabled": "В настоящий момент мы не принимаем запросы на обратные вызовы.",
    "CallbackRequest.not_available": "Недоступно",
    "CallbackService.approximately": "Примерно",
    "CallbackService.approximately_x_minutes": "Примерно :minutes мин.",
    "CallbackService.callback_button": "Перезвоните мне",
    "CallbackService.callback_service": "Услуга обратных вызовов :vendor ",
    "CallbackService.cancel_appointment": "Отменить встречу",
    "CallbackService.cancel_appointment_confirm": "Действительно отменить эту встречу? Это необратимое действие.",
    "CallbackService.cancel_callback": "Отменить обратный вызов",
    "CallbackService.changes_to_your_appointment": "Изменения в вашей встрече",
    "CallbackService.curbside.radio.car_details": "Марка, модель и цвет вашего автомобиля",
    "CallbackService.curbside.radio.curbside": "Доставка к машине",
    "CallbackService.curbside.radio.joining_line": "Как вы встаете в очередь?",
    "CallbackService.curbside.radio.parking_details": "Номер места на парковке",
    "CallbackService.curbside.radio.pickup_information": "Сведения для доставки к машине",
    "CallbackService.curbside.radio.walkin": "Встреча без записи",
    "CallbackService.curbsideservices": "Услуги доставки к машине",
    "CallbackService.email": "Электронная почта",
    "CallbackService.en": "Английский",
    "CallbackService.es": "Испанский",
    "CallbackService.first_name": "Имя",
    "CallbackService.footer": "На платформе",
    "CallbackService.fr": "Французский",
    "CallbackService.instructions": "Добавьте себя, чтобы вас сегодня приняли. Когда сотрудник будет готов вас обслужить, мы попросим вас вернуться.",
    "CallbackService.join_by_phone": "Присоединиться по номеру телефона: <a>{number}<\/a>",
    "CallbackService.join_the_call": "Присоединиться к звонку",
    "CallbackService.ko": "Корейский",
    "CallbackService.last_name": "Фамилия",
    "CallbackService.location_closed": "Сейчас закрыто",
    "CallbackService.location_closed_info": "Наши представители отвечают на эти запросы в течение указанного времени",
    "CallbackService.location_info": "Для обслуживания обратитесь непосредственно на данную площадку.",
    "CallbackService.missing_phone_and_email": "Мы сообщим, когда придет ваша очередь. Укажите номер телефона или адрес электронной почты",
    "CallbackService.no_staff_warning": "Встав на очередь, вас будет обслуживать первый освободившийся сотрудник. Возможно, это не тот сотрудник, {name} , которого вы выбрали ранее.",
    "CallbackService.not_taking_callbacks": "В настоящее время эта площадка не принимает запросы на обратные телефонные звонки.",
    "CallbackService.not_taking_online_queue": "В настоящее время эта площадка не принимает запросы на постановку в онлайн-очередь.",
    "CallbackService.notes": "Примечания",
    "CallbackService.online_queuing": "Онлайн-очередь {vendorName}",
    "CallbackService.optional": "Необязательно",
    "CallbackService.phone": "Номер, по которому вам можно позвонить",
    "CallbackService.phone_type": "Телефон",
    "CallbackService.pin": "PIN-код: {pin}",
    "CallbackService.pl": "польский",
    "CallbackService.pt": "португальский",
    "CallbackService.queue_appointment_details": "Сведения о встрече из очереди",
    "CallbackService.required": "Обязательно",
    "CallbackService.ru": "Русский",
    "CallbackService.sms_opt_in": "Я хочу получать SMS-сообщения о моей встрече",
    "CallbackService.sms_required": "На этот номер будут приходить SMS-сообщения с информацией о вашей встрече. За них может взиматься плата согласно вашему тарифу.",
    "CallbackService.submit_form": "Отправить",
    "CallbackService.successfully_added": "Ваш запрос успешно добавлен",
    "CallbackService.topic": "Выберите услугу",
    "CallbackService.topic_prefill": "{name}, выберите услугу",
    "CallbackService.topic_wait_time": "Примерное время ожидания: {waitTime}",
    "CallbackService.video_type": "Видео",
    "CallbackService.walkinservices": "Услуги встреч без записи",
    "CallbackService.we_will_notify_you": "Мы сообщим, когда вы будете на первом месте в очереди",
    "CallbackService.we_will_notify_you_turn": "Мы сообщим, когда придет ваша очередь",
    "CallbackService.well_call_you_back_at": "Мы перезвоним вам на номер :phoneNumber",
    "CallbackService.without_updates": "Нет, зарегистрироваться без получения обновлений",
    "CallbackService.x_minutes": "{minutes} мин",
    "CallbackService.you_are_next": "Вы на первом месте в очереди на обратный вызов.",
    "CallbackService.your_appointment": "Ваша встреча",
    "CallbackService.zh": "Китайский",
    "CircularProgress.loading": "Загрузка",
    "Convert.body": "Вы можете перенести встречу на более позднюю дату, если вам так удобнее. Если вы продолжите и завершите запись на встречу, то потеряете свое место в очереди.",
    "Convert.button": "Преобразовать во встречу",
    "Convert.title": "Надоело ждать?",
    "CountrySelectInput.label": "Страна",
    "Days.friday": "Пятница",
    "Days.monday": "Понедельник",
    "Days.saturday": "Суббота",
    "Days.sunday": "Воскресенье",
    "Days.thursday": "Четверг",
    "Days.tuesday": "Вторник",
    "Days.wednesday": "Среда",
    "Details.confirmation_number": "Номер для подтверждения",
    "Details.curbside_appointment_title": "Сведения о встрече для доставки к машине",
    "Details.curbside_details_label": "Сведения для доставки к машине",
    "Details.date": "Дата",
    "Details.location": "Площадка",
    "Details.phone_call": "Телефонный звонок",
    "Details.phone_number_ending_in": "Последние цифры номера телефона: {number}",
    "Details.queue_appointment_details": "Сведения о встрече из очереди",
    "Details.service": "Услуга",
    "Details.staff": "Сотрудники",
    "Details.video_call": "Видеозвонок",
    "Details.we_will_call_you": "Мы позвоним вам на указанный номер",
    "Details.we_will_send_link": "Когда придет ваша очередь, мы отправим вам ссылку для присоединения к звонку",
    "DetailsForm.agree_tos": "Назначая встречу, вы принимаете {terms} и {privacy} Coconut Software.",
    "DetailsForm.agree_tos_with_privacy": "Назначая встречу, вы принимаете {terms} и {privacy} Coconut Software, а также {vendorPrivacy} {vendor}.",
    "DetailsForm.agree_tos_with_tos": "Назначая встречу, вы принимаете {terms} и {privacy} Coconut Software, а также {vendorTerms} {vendor}.",
    "DetailsForm.agree_tos_with_vendor": "Назначая встречу, вы принимаете {terms} и {privacy} Coconut Software, а также {vendorTerms} и {vendorPrivacy} {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy": "Назначая встречу, вы принимаете {vendorTerms} и {vendorPrivacy} {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_policy": "Назначая встречу, вы принимаете {vendor} {vendorPrivacy}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_tos": "Назначая встречу, вы принимаете {vendor} {vendorTerms}.",
    "DetailsForm.coconut_privacy_url": "https:\/\/www.coconutsoftware.com\/privacy-policy\/",
    "DetailsForm.coconut_terms_url": "https:\/\/www.coconutsoftware.com\/terms-of-use\/",
    "DetailsForm.explicit_compliance_agreement": "Принимаю",
    "DetailsForm.privacy": "Уведомление о конфиденциальности",
    "DetailsForm.terms_of_use": "Условия эксплуатации",
    "DetailsForm.walkin_policy_action": "",
    "EmptyState.contact_vendor": "Повторите попытку или свяжитесь с нами",
    "Error": "При обработке вашего запроса возникла ошибка",
    "Feedback.error": "Перед отправкой укажите оценку",
    "Feedback.experience": "Ваш опыт",
    "Feedback.google_description": "Для нас очень важны ваши отзывы и предложения. Мы уверены, что они будут полезны и другим нашим клиентам. Если у вас есть немного времени, чтобы оставить в Google отзыв о своем опыте работы с нами, нажмите расположенную ниже кнопку, и мы перенаправим вас на нужную страницу.",
    "Feedback.google_submit": "Оставить отзыв в Google",
    "Feedback.neutral": "Удовлетворительно",
    "Feedback.new_title": "Отзыв",
    "Feedback.rating": "Оценка (обязательно)",
    "Feedback.response_prompt": "Поделитесь с нами своим мнением о том, что можно улучшить",
    "Feedback.send": "Отправить отзыв",
    "Feedback.sending": "Отзыв отправляется",
    "Feedback.subtitle": "Ваш отзыв анонимен. Мы используем эти сведения, чтобы улучшить службу бронирования.",
    "Feedback.success": "Отзыв отправлен. Благодарим вас!",
    "Feedback.thank_you": "Спасибо за отзыв!",
    "Feedback.title": "Какие у вас впечатления от процесса назначения этой встречи?",
    "Feedback.very_dissatisfied": "Отвратительно",
    "Feedback.very_satisfied": "Отлично",
    "GenericError.subtitle": "Страница, которую вы ищете, не найдена",
    "GenericError.title": "Что-то пошло не так",
    "Instructions.title": "Инструкции",
    "Location.select_location": "Выберите площадку",
    "LocationDetails.location_details": "Сведения о площадке",
    "LocationDetails.select_location": "Выбрать эту площадку",
    "LocationDirectionsButton.text": "Маршрут",
    "LocationHours.closed": "Закрыто",
    "LocationHours.timespan": "{open} - {closed}",
    "LocationHours.title": "Время работы",
    "LocationList.no_locations": "Площадки не найдены",
    "LocationList.title": "Выберите площадку",
    "LocationPhoneButton.text": "Позвонить {number}",
    "LocationPhoneCallButton.text": "Позвонить {number}",
    "Manage.book_another": "Назначить другую встречу",
    "Manage.restart": "Начать заново с домашней страницы",
    "Questions.missing_both": "Пожалуйста, укажите адрес электронной почты или номер мобильного телефона, прежде чем отвечать на вопросы",
    "Questions.missing_email": "Пожалуйста, укажите адрес электронной почты, прежде чем отвечать на вопросы",
    "Questions.missing_phone": "Пожалуйста, укажите номер мобильного телефона, прежде чем отвечать на вопросы",
    "SelectInput.default": "Выбрать",
    "Svg.altText.back": "Назад",
    "Svg.altText.coconutLogo": "Логотип Coconut Software",
    "Svg.altText.dropdown_arrow": "Стрелка раскрытия списка",
    "Svg.altText.location_pin": "Маркер площадки",
    "Svg.altText.search": "Поиск",
    "Svg.altText.select_language": "Выбор языка",
    "Svg.alt_text.appointment": "Встреча",
    "Svg.alt_text.back": "Назад",
    "Svg.alt_text.cancelled": "Отменено",
    "Svg.alt_text.coconut_logo": "Логотип Coconut Software",
    "Svg.alt_text.curbside": "Значок услуги доставки к машине",
    "Svg.alt_text.dropdown_arrow": "Значок стрелки вниз",
    "Svg.alt_text.location_error": "Значок ошибки выбора площадки",
    "Svg.alt_text.location_pin": "Площадка",
    "Svg.alt_text.search": "Поиск",
    "Svg.alt_text.select_language": "Выбор языка",
    "Svg.alt_text.up_arrow": "Значок стрелки вверх",
    "Svg.alt_text.walk_in": "Значок услуги без записи",
    "Ui.cancel": "Отменить встречу",
    "Ui.continue": "Далее",
    "Ui.keep": "Сохранить",
    "Ui.loading": "Загрузка",
    "Ui.lobby_disabled": "",
    "Ui.no_available_staff_inperson": "В настоящее время это место не принимает посетителей",
    "Ui.no_available_staff_phone": "В настоящее время это местоположение не принимает телефонные звонки.",
    "Ui.no_available_staff_video": "В настоящее время это место не принимает видеообратные вызовы.",
    "Ui.private_location": "Это место является частным, и вы не можете присоединиться к линии здесь.",
    "Ui.search": "Поиск...",
    "Ui.search.instructions": "Пожалуйста, начните вводить для поиска.",
    "Ui.search.plain": "Поиск"
}