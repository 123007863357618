import PropTypes from 'prop-types';
import React, { createContext, useState, useContext } from 'react';

const ServiceContext = createContext({ service: null, setService: () => {} });

const useService = () => useContext(ServiceContext);

const ServiceProvider = ({ children }) => {
  const [service, setService] = useState(window.state?.walkIn?.service);

  const changeLang = (lang) => {
    service?.translations?.forEach((translation) => {
      if (translation.locale === lang) {
        service[translation.field] = translation.content;
      }
    });
    setService(service);
  };

  return (
    <ServiceContext.Provider
      value={{
        service,
        setService,
        changeLang,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};

ServiceProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { ServiceContext, ServiceProvider, useService };
