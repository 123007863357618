import {
  Grid,
  Paper,
  Divider,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LocationClosedImage from '../../../images/location-closed.svg';
import LocationHours from './LocationHours';

const useStyles = makeStyles((theme) => ({
  content: {
    margin: `${theme.typography.pxToRem(theme.spacing(2))} auto`,
    maxWidth: theme.typography.pxToRem(theme.spacing(150)),
  },
  divider: {
    height: theme.typography.pxToRem(theme.spacing(50)),
    width: '1px',
  },
  hoursTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    paddingRight: theme.typography.pxToRem(theme.spacing(3)),
    marginBottom: theme.typography.pxToRem(theme.spacing(2)),
  },
  logo: {
    margin: 'auto',
    maxHeight: theme.typography.pxToRem(theme.spacing(16)),
    minHeight: theme.typography.pxToRem(theme.spacing(6)),
  },
  logoWrapper: {
    margin: `${theme.typography.pxToRem(theme.spacing(2))} auto`,
  },
  root: {
    height: theme.typography.pxToRem(theme.spacing(80)),
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.typography.pxToRem(theme.spacing(2)),
    padding: `0 ${theme.typography.pxToRem(theme.spacing(7))}`,
    textAlign: 'center',
  },
}));

const LocationClosed = () => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Paper className={classes.content}>
      <Grid
        alignItems="center"
        className={classes.root}
        container
        item
        justify="space-evenly"
        xs={12}
      >
        <Grid
          alignItems="center"
          container
          direction="column"
          item
          md={7}
          xs={12}
        >
          <img
            alt={intl.formatMessage({ id: 'CallbackService.location_closed' })}
            src={LocationClosedImage}
          />
          <Typography className={classes.title} variant="h5">
            <FormattedMessage id="CallbackService.location_closed" />
          </Typography>
        </Grid>
        {isBigScreen ? (
          <Grid container item justify="center" xs={1}>
            <Divider className={classes.divider} />
          </Grid>
        ) : null}
        <Grid item md={4} xs={6}>
          <Typography className={classes.hoursTitle} variant="body1">
            <FormattedMessage id="CallbackService.location_closed_info" />
          </Typography>
          <LocationHours />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LocationClosed;
