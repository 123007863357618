import React, { useContext } from 'react';
import { IntlProvider } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import synonymReplacement from '../../shared/helpers/SynonymReplacement';
import { LocaleContext } from '../contexts/LocaleContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import messages from '../locales';
import NavBar from './NavBar';
import Page from './Page';

const useStyles = createUseStyles((theme) => ({
  app: {
    height: '100vh',
    fontFamily: theme.fontFamilies.sansSerif,
  },
}));

const App = () => {
  const classes = useStyles({ theme: useTheme() });
  const viewMode = useContext(ViewModeContext);
  const { locale } = useContext(LocaleContext);
  const translatedMessages = synonymReplacement(
    messages[locale],
    window.state.vendor.synonyms[locale],
  );

  return (
    <IntlProvider key={locale} locale={locale} messages={translatedMessages}>
      <div className={viewMode === MOBILE ? null : classes.app}>
        <NavBar />
        <Page />
      </div>
    </IntlProvider>
  );
};

export default App;
