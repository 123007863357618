import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Typography from '../Typography';
import HelpText from './HelpText';
import InputErrors from './InputErrors';

const useStyles = createUseStyles((theme) => ({
  input: {
    border: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: theme.borderRadius.sm,
    color: theme.palette.black,
    display: 'block',
    fontSize: theme.textSizes.base,
    padding: '0.75rem',
    transition: theme.transitions.border,
    width: '100%',
    '&:focus': {
      borderColor: theme.palette.info[400],
      boxShadow: theme.shadows.input,
      outline: 0,
    },
  },
  label: {
    cursor: 'pointer',
    display: 'inline-block',
    marginBottom: '0.5rem',
  },
}));

const TextInput = ({
  errors,
  helpText,
  id,
  label,
  name,
  onBlur,
  onChange,
  required,
  type,
  value,
}) => {
  const classes = useStyles({ theme: useTheme() });

  const [val, setVal] = useState(null);

  const handleChange = (event) => {
    setVal(event.target.value);
  };

  return (
    <label htmlFor={id}>
      <div className={classes.label}>
        <Typography variant="caption1">{label}</Typography>
      </div>
      <input
        aria-required={required}
        className={classes.input}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange || handleChange}
        required={required}
        type={type}
        value={val === null ? value : val}
      />
      {errors.length > 0 ? (
        <InputErrors errors={errors} />
      ) : (
        <HelpText value={helpText} />
      )}
    </label>
  );
};

TextInput.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  helpText: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'email']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextInput.defaultProps = {
  errors: [],
  helpText: null,
  onBlur: null,
  onChange: null,
  required: true,
  type: 'text',
  value: null,
};

export default TextInput;
