import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Button from './forms/Button';

const useStyles = makeStyles((theme) => ({
  dialogTitleWrapper: {
    paddingBottom: 0,
  },
  dialogTitle: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  scrollPaperMobile: {
    alignItems: 'flex-end',
  },
  paperScrollPaperMobile: {
    maxWidth: '100%',
  },
  paperMobile: {
    margin: theme.typography.pxToRem(theme.spacing(1.5)),
  },
  actionsMobile: {
    flexWrap: 'wrap',
    flexDirection: 'column-reverse',
  },
  marginBottom: {
    marginBottom: theme.typography.pxToRem(theme.spacing(1)),
  },
}));

const ConfirmationDialog = ({
  isOpen,
  setOpen,
  onSubmit,
  title,
  body,
  confirmPrompt,
  cancelPrompt,
}) => {
  const intl = useIntl();

  const classes = useStyles();
  const mode = useContext(ViewModeContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);

    // Wrapping onSubmit in an async function to handle functions which don't return promises
    async function submit() {
      onSubmit();
    }
    submit()
      .then(() => {
        setOpen(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      classes={{
        scrollPaper: mode === MOBILE ? classes.scrollPaperMobile : '',
        paperScrollPaper: mode === MOBILE ? classes.paperScrollPaperMobile : '',
        paper: mode === MOBILE ? classes.paperMobile : '',
      }}
      maxWidth="xs"
      onClose={() => setOpen(false)}
      open={isOpen}
    >
      <DialogTitle
        classes={{
          root: classes.dialogTitleWrapper,
        }}
      >
        <Typography
          classes={{
            root: classes.dialogTitle,
          }}
          component="div"
          variant="h6"
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{body}</DialogContentText>
        <DialogActions
          classes={{
            root: mode === MOBILE ? classes.actionsMobile : '',
          }}
          disableSpacing={mode === MOBILE}
        >
          <Button
            fullWidth={mode === MOBILE}
            onClick={() => setOpen(false)}
            type="button"
            variant="tertiary"
          >
            {cancelPrompt || intl.formatMessage({ id: 'Ui.cancel' })}
          </Button>
          <Button
            className={mode === MOBILE ? classes.marginBottom : ''}
            disabled={loading}
            fullWidth={mode === MOBILE}
            isLoading={loading}
            onClick={handleSubmit}
            type="submit"
          >
            {confirmPrompt || intl.formatMessage({ id: 'Ui.continue' })}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  confirmPrompt: PropTypes.string,
  cancelPrompt: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  cancelPrompt: '',
  confirmPrompt: '',
  isOpen: false,
};

export default ConfirmationDialog;
