import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment, useReducer, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import BackButton from '../../../frontend/components/BackButton';
import SearchableInput from '../../../frontend/components/forms/SearchableInput';
import LocationDetails from '../../../frontend/components/LocationDetails';
import OldMapOverlay from '../../../frontend/components/OldMapOverlay';
import Typography from '../../../frontend/components/Typography';
import useClickAway from '../../../frontend/hooks/useClickAway';
import LocationList from './LocationList';
import MapContainer from './MapContainer';

const useStyles = createUseStyles((theme) => ({
  content: {
    height: 'calc(100% - 12px)',
    maxWidth: '1200px',
  },
  empty: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    background: theme.palette.white,
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: '4rem',
    width: '100%',
    padding: '1.125rem 0',
  },
  list: {
    background: theme.palette.white,
    flexGrow: 1,
    overflowY: 'auto',
    padding: '1.25rem',
    listStyle: 'none',
  },
  section: {
    marginBottom: '1.25rem',
  },
  title: {
    display: 'block',
    marginBottom: '1.25rem',
  },
}));

const DesktopSearch = ({
  chosen,
  fetchSuggestions,
  isLoading,
  locations,
  onClickAway,
  suggestions,
  selectSuggestion,
  setChosen,
  setLocation,
}) => {
  const classes = useStyles({ theme: useTheme() });
  const [markers, setMarkers] = useReducer((state, newState) => newState, {});

  const element = useRef(null);
  useClickAway(element, onClickAway);

  if (isLoading) {
    return <div />;
  }

  return (
    <Grid className={classes.content} item ref={element} xs={12}>
      <MapContainer
        locations={chosen ? [chosen] : locations}
        onClickMarker={setChosen}
        onCreateMarker={setMarkers}
        singular={Boolean(chosen)}
      />
      <OldMapOverlay>
        {chosen ? (
          <>
            <header className={classes.header}>
              <BackButton
                previous={() => setChosen(null)}
                text={<FormattedMessage id="BackButton.back_results" />}
              />
            </header>
            <LocationDetails
              location={chosen}
              selectLocation={setLocation}
              showHours={false}
            />
          </>
        ) : (
          <div
            className={classNames(
              classes.list,
              locations.length ? '' : classes.empty,
            )}
            data-testid="location-list"
          >
            <Typography
              classes={{ root: classes.title }}
              component="h1"
              variant="h5"
            >
              <FormattedMessage id="Location.select_location" />
            </Typography>
            <div className={classes.section}>
              <SearchableInput
                onChange={({ currentTarget: { value } }) => {
                  fetchSuggestions(value);
                }}
                onClick={selectSuggestion}
                options={suggestions}
              />
            </div>
            <LocationList
              locations={locations}
              markers={markers}
              setChosen={setChosen}
            />
          </div>
        )}
      </OldMapOverlay>
    </Grid>
  );
};

DesktopSearch.propTypes = {
  chosen: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  fetchSuggestions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(Object).isRequired,
  onClickAway: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
  selectSuggestion: PropTypes.func.isRequired,
  setChosen: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.element).isRequired,
};

DesktopSearch.defaultProps = {
  chosen: null,
};

export default DesktopSearch;
