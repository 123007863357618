import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  root: theme.components.icons.default,
}));

const Info = ({ altText, classes: injectedClasses }) => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <svg
      className={classNames(injectedClasses.root, classes.root)}
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {altText ? <title>{altText}</title> : null}
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
    </svg>
  );
};

Info.propTypes = {
  altText: PropTypes.string,
  classes: PropTypes.shape({ root: PropTypes.string }).isRequired,
};

Info.defaultProps = {
  altText: '',
};

export default Info;
