import {
  CircularProgress,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FULFILLMENT_METHODS } from '../../../backend/constants';
import { visibilities } from '../../../shared/constants';
import Features from '../../../shared/helpers/Features';
import Item from '../../../shared/helpers/Item';
import Url from '../../../shared/helpers/Url';
import { MEETING_METHODS } from '../../constants';
import { LocationContext } from '../../contexts/LocationContext';
import { useServices } from '../../contexts/ServicesContext';
import { VendorContext } from '../../contexts/VendorContext';
import { useQueryStringValue } from '../../hooks/url';
import Alert from '../Alert';
import DropDownArrow from '../icons/DropDownArrow';
import UpArrow from '../icons/UpArrow';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: '1rem',
  },
  dropDownArrow: {
    cursor: 'pointer',
    position: 'absolute',
    height: theme.typography.pxToRem(theme.spacing(3)),
    right: theme.typography.pxToRem(theme.spacing(1)),
  },
  selectLabel: {
    paddingBottom: '.5rem',
  },
  select: {
    width: '100%',
  },
  selectContainer: {
    width: '100%',
  },
  menu: {
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 6rem)',
    },
    [theme.breakpoints.up('md')]: {
      width: 'calc(75% - 5.5rem)',
    },
    [theme.breakpoints.up('lg')]: {
      width: 'calc(50% - 5rem)',
    },
  },
  menuItem: {
    width: '100%',
    whiteSpace: 'normal',
  },
  servicesLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ServiceSelect = ({ formData, setFormData, topic, topics }) => {
  const { vendor } = useContext(VendorContext);
  const { location } = useContext(LocationContext);
  const { services, loading: loadingServices } = useServices();
  const urlMeetingMethod = useQueryStringValue('meeting_method');
  const intl = useIntl();

  const curbsidePickupEnabled =
    Features.isCurbsidePickupEnabled(vendor) &&
    location?.supports_curbside_pickup &&
    window.location.href.includes(
      `meeting_method=${MEETING_METHODS.AT_BUSINESS}`,
    );
  const classes = useStyles();

  const curbsideTopics = topics.filter((topic) => topic.supportsCurbsidePickup);
  const walkinTopics = topics.filter((topic) => topic.supportsWalkin);
  const { service } = Url.params(window?.location.search);

  const alertMessage = useMemo(() => {
    if (location.visibility === visibilities.PRIVATE) {
      return 'Ui.private_location';
    }

    switch (parseInt(urlMeetingMethod, 10)) {
      case MEETING_METHODS.AT_BUSINESS:
        return 'Ui.no_available_staff_inperson';
      case MEETING_METHODS.PHONE_CALL:
        return 'Ui.no_available_staff_phone';
      default:
        return 'Ui.no_available_staff_video';
    }
  }, [urlMeetingMethod]);

  useEffect(() => {
    const walkinAvailable = Item.find(
      walkinTopics,
      (_service) => _service.value === Number(service),
    );
    const curbsideAvailable = Item.find(
      curbsideTopics,
      (_service) => _service.value === Number(service),
    );

    if (walkinAvailable || curbsideAvailable) {
      setFormData({
        topic: Number(service),
        fulfillmentMethod: walkinAvailable
          ? FULFILLMENT_METHODS.WALK_IN
          : FULFILLMENT_METHODS.CURBSIDE,
      });
    }
    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topics]);

  const [open, setOpen] = useState(false);

  //This is to allow for a late load of no services to disable the dropdown
  const handleSelect = () => {
    if (!loadingServices && !services.length) {
      setOpen(false);
      return;
    }

    setOpen(!open);
  };

  return (
    <>
      {(!loadingServices && !services.length) ||
      location.visibility === visibilities.PRIVATE ? (
        <div className={classes.alert} data-testid="services-unavailable">
          <Alert
            message={intl.formatMessage({ id: alertMessage })}
            variant="warning"
          />
        </div>
      ) : null}

      <InputLabel className={classes.selectLabel} id="service-select">
        <FormattedMessage
          id={
            formData?.firstName
              ? 'CallbackService.topic_prefill'
              : 'CallbackService.topic'
          }
          values={{ name: formData?.firstName }}
        />
      </InputLabel>

      <Select
        autoWidth
        classes={{
          select: classes.select,
        }}
        className={classes.selectContainer}
        IconComponent={() => (
          <span
            className={classes.dropDownArrow}
            onClick={handleSelect}
            onKeyDown={handleSelect}
            role="button"
            tabIndex={0}
          >
            {open ? <UpArrow /> : <DropDownArrow />}
          </span>
        )}
        id="select-topic"
        inputProps={{
          'data-testid': 'topic',
          'aria-required': 'true',
        }}
        MenuProps={{
          classes: { paper: classes.menu },
          width: '100%',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        name="topic"
        onChange={(event) => {
          const id = event.target.value.split('-')[0];
          const type = event.target.value.split('-')[1];
          setFormData({
            topic: parseInt(id, 10),
            fulfillmentMethod: parseInt(type, 10),
          });
        }}
        onClick={handleSelect}
        open={open}
        value={`${topic}-${formData.fulfillmentMethod}`}
        variant="outlined"
      >
        {loadingServices ? (
          <MenuItem className={classes.servicesLoading} disabled>
            <CircularProgress />
          </MenuItem>
        ) : null}

        {!loadingServices &&
        curbsidePickupEnabled &&
        walkinTopics.length &&
        curbsideTopics.length ? (
          <ListSubheader>
            <FormattedMessage id="CallbackService.walkinservices" />
          </ListSubheader>
        ) : null}

        {!loadingServices
          ? walkinTopics.map((topic) => {
              return (
                <MenuItem
                  className={classes.menuItem}
                  key={`walkin-${topic.value}`}
                  value={`${topic.value}-${FULFILLMENT_METHODS.WALK_IN}`}
                >
                  {topic.text}
                </MenuItem>
              );
            })
          : null}

        {!loadingServices &&
        curbsidePickupEnabled &&
        curbsideTopics.length &&
        walkinTopics.length ? (
          <ListSubheader>
            <FormattedMessage id="CallbackService.curbsideservices" />
          </ListSubheader>
        ) : null}

        {!loadingServices && curbsidePickupEnabled
          ? curbsideTopics.map((topic) => {
              return (
                <MenuItem
                  className={classes.menuItem}
                  key={`curbside-${topic.value}`}
                  value={`${topic.value}-${FULFILLMENT_METHODS.CURBSIDE}`}
                >
                  {topic.text}
                </MenuItem>
              );
            })
          : null}
      </Select>
    </>
  );
};

ServiceSelect.propTypes = {
  formData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
  setFormData: PropTypes.func.isRequired,
  topic: PropTypes.number.isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
  ).isRequired,
};

export default ServiceSelect;
