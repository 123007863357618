import { Link, Grid, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import mode from '../../shared/helpers/Mode';
import synonymReplacement from '../../shared/helpers/SynonymReplacement';
import { LocaleContext } from '../contexts/LocaleContext';
import { VendorContext } from '../contexts/VendorContext';
import messages from '../locales';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  statement: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
    marginTop: '1rem',
    paddingTop: '1rem',
    '& a': {
      color: theme.palette.secondary[400],
      borderBottom: `1px dotted ${theme.palette.secondary[400]}`,
      textDecoration: 'none',
      target: '_blank',
      '&:focus': {
        outline: `2px ${theme.palette.secondary[500]} solid`,
        '& $text': {
          color: theme.palette.secondary[500],
        },
      },
      '&:hover': {
        borderBottomColor: theme.palette.secondary[500],
        color: theme.palette.secondary[500],
      },
    },
    '& ol li': {
      listStyle: 'decimal',
      listStylePosition: 'inside',
    },
    '& ul li': {
      listStyle: 'circle',
      listStylePosition: 'inside',
    },
  },
  'checkbox-form': {
    display: 'flex',
    'align-items': 'center',
  },
}));

const Policies = ({ accepted, setAccepted }) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const { vendor } = useContext(VendorContext);
  const { settings } = window.state;

  const privacyPolicyUrl = settings.privacy_policy_url;
  const termsOfServiceUrl = settings.terms_of_service_url;
  const preBooking = settings.pre_booking_statement;
  const fullPrivateLabel = vendor.private_label;

  const vendorName = vendor.name;
  const { locale } = useContext(LocaleContext);

  const translations = synonymReplacement(
    messages[locale],
    window.state.synonyms ? window.state.synonyms[locale] : null,
  );

  const preBookingStatement = { __html: preBooking };

  const requiresExplicitConsent = mode.isKiosk()
    ? false
    : settings.explicit_compliance_agreement;

  const values = {
    privacy: (
      <Link
        data-testid="coconut-privacy"
        href={translations['DetailsForm.coconut_privacy_url']}
        rel="noreferrer"
        target="_blank"
      >
        <FormattedMessage id="DetailsForm.privacy" />
      </Link>
    ),
    terms: (
      <Link
        data-testid="coconut-terms"
        href={translations['DetailsForm.coconut_terms_url']}
        rel="noreferrer"
        target="_blank"
      >
        <FormattedMessage id="DetailsForm.terms_of_use" />
      </Link>
    ),
    vendor: vendorName,
    vendorPrivacy: (
      <Link
        data-testid="vendor-privacy"
        href={privacyPolicyUrl}
        rel="noreferrer"
        target="_blank"
      >
        <FormattedMessage id="DetailsForm.privacy" />
      </Link>
    ),
    vendorTerms: (
      <Link
        data-testid="vendor-terms"
        href={termsOfServiceUrl}
        rel="noreferrer"
        target="_blank"
      >
        <FormattedMessage id="DetailsForm.terms_of_use" />
      </Link>
    ),
    action: <FormattedMessage id="DetailsForm.walkin_policy_action" />,
  };

  const getId = () => {
    if (privacyPolicyUrl && termsOfServiceUrl && !fullPrivateLabel) {
      return 'DetailsForm.agree_tos_with_vendor';
    }

    if (privacyPolicyUrl && !termsOfServiceUrl && !fullPrivateLabel) {
      return 'DetailsForm.agree_tos_with_privacy';
    }

    if (!privacyPolicyUrl && termsOfServiceUrl && !fullPrivateLabel) {
      return 'DetailsForm.agree_tos_with_tos';
    }

    if (fullPrivateLabel && privacyPolicyUrl && !termsOfServiceUrl) {
      return 'DetailsForm.agree_tos_with_vendor_full_privacy_policy';
    }

    if (fullPrivateLabel && !privacyPolicyUrl && termsOfServiceUrl) {
      return 'DetailsForm.agree_tos_with_vendor_full_privacy_tos';
    }

    if (fullPrivateLabel && privacyPolicyUrl && termsOfServiceUrl) {
      return 'DetailsForm.agree_tos_with_vendor_full_privacy';
    }

    return 'DetailsForm.agree_tos';
  };

  return !mode.isKiosk() || preBooking ? (
    <Grid data-testid="compliance-policies" item role="contentinfo" xs={12}>
      <span className={classes['checkbox-form']}>
        {requiresExplicitConsent ? (
          <Checkbox
            bold
            checked={accepted}
            inputProps={{
              'aria-label': intl.formatMessage({
                id: 'DetailsForm.explicit_compliance_agreement',
              }),
            }}
            onChange={() => {
              setAccepted(!accepted);
            }}
          />
        ) : null}
        {!mode.isKiosk() ? (
          <Typography component="p" variant="subtitle">
            <FormattedMessage id={getId()} values={values} />
          </Typography>
        ) : null}
      </span>
      {preBooking ? (
        <Typography variant="body2">
          <div
            className={
              mode.isKiosk() ? classes.kioskStatement : classes.statement
            }
            dangerouslySetInnerHTML={preBookingStatement}
            data-testid="pre-booking-statement"
          />
        </Typography>
      ) : null}
    </Grid>
  ) : null;
};

Policies.propTypes = {
  accepted: PropTypes.bool.isRequired,
  setAccepted: PropTypes.func.isRequired,
};

export default Policies;
