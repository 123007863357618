import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { withTheme } from 'react-jss';
import { SettingsContext } from '../contexts/SettingsContext';
import Map from '../helpers/Map';
import DesktopCard from './desktop/Card';
import LocationDistanceIndicator from './LocationDistanceIndicator';
import OldWaitTime from './OldWaitTime';

const LocationListItem = ({ location, markers, setChosen, theme }) => {
  const { showWaitTime } = useContext(SettingsContext);
  const useDefaultPin = window?.state?.theme?.useDefaultPin || false;
  const colour = theme.palette.secondary[400];

  const handleMouseOver = () => {
    const marker = markers[location.id] || false;

    if (marker) {
      marker.setIcon(
        useDefaultPin ? Map.defaultIcon() : Map.icon(Map.LARGE, colour),
      );
    }
  };

  const handleMouseOut = () => {
    const marker = markers[location.id] || false;

    if (marker) {
      marker.setIcon(useDefaultPin ? null : Map.icon(Map.SMALL, colour));
    }
  };

  const handleClick = () => {
    setChosen(location);
  };

  return (
    <div>
      <DesktopCard
        action={handleClick}
        adornment={
          location.physical && location.distance ? (
            <LocationDistanceIndicator
              distance={location.distance.toFixed(1)}
              unit={location.unit}
            />
          ) : null
        }
        data-testid={`location-list-item-${location.id}`}
        header={
          location.physical && showWaitTime ? (
            <OldWaitTime location={location} />
          ) : null
        }
        id={location.id}
        key={location.id}
        onBlur={handleMouseOut}
        onFocus={handleMouseOver}
        onMouseOut={handleMouseOut}
        onMouseOver={handleMouseOver}
        primary={location.name}
        primaryId={`locationName-${location.id}`}
        secondary={location.physical ? location.formattedAddress : null}
      />
    </div>
  );
};

LocationListItem.propTypes = {
  location: PropTypes.shape({
    formattedAddress: PropTypes.string,
    hours: PropTypes.objectOf(
      PropTypes.shape({ closed: PropTypes.string, open: PropTypes.string }),
    ),
    id: PropTypes.number,
    name: PropTypes.string,
    physical: PropTypes.bool,
  }).isRequired,
  markers: PropTypes.objectOf(PropTypes.objectOf(PropTypes.func)).isRequired,
  setChosen: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      secondary: PropTypes.objectOf(PropTypes.string),
    }),
  }).isRequired,
};

export default withTheme(LocationListItem);
