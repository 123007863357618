export default {
    "Alert.error": "오류",
    "Alert.info": "정보",
    "Alert.success": "성공",
    "Alert.warning": "경고",
    "AppointmentCancelled.title": "이 약속은 취소됨",
    "BackButton.back": "뒤로",
    "BackButton.back_results": "결과로 돌아가기",
    "CallbackRequest.callback_request_disabled": "현재 콜백 요청을 받지 않고 있습니다.",
    "CallbackRequest.not_available": "이용할 수 없음",
    "CallbackService.approximately": "약",
    "CallbackService.approximately_x_minutes": "약 :minutes 분",
    "CallbackService.callback_button": "회신 요청",
    "CallbackService.callback_service": ":vendor 콜백 서비스",
    "CallbackService.cancel_appointment": "약속 취소",
    "CallbackService.cancel_appointment_confirm": "이 약속을 취소하시겠습니까? 약속을 취소하면 실행 취소할 수 없습니다.",
    "CallbackService.cancel_callback": "콜백 취소",
    "CallbackService.changes_to_your_appointment": "약속에 대한 변경 사항",
    "CallbackService.curbside.radio.car_details": "차 제조사, 차종과 색상",
    "CallbackService.curbside.radio.curbside": "커브사이드",
    "CallbackService.curbside.radio.joining_line": "라인에 어떻게 참여하시겠습니까?",
    "CallbackService.curbside.radio.parking_details": "주차 공간 번호",
    "CallbackService.curbside.radio.pickup_information": "커브사이드 픽업 정보",
    "CallbackService.curbside.radio.walkin": "워크인",
    "CallbackService.curbsideservices": "커브사이드 서비스",
    "CallbackService.email": "이메일",
    "CallbackService.en": "영어",
    "CallbackService.es": "스페인어",
    "CallbackService.first_name": "이름",
    "CallbackService.footer": "제공",
    "CallbackService.fr": "프랑스어",
    "CallbackService.instructions": "오늘 예약자로 사용자 본인을 추가합니다. 스태프 멤버가 서비스를 제공할 준비가 되면 알려드릴 테니 그때 돌아오시면 됩니다.",
    "CallbackService.join_by_phone": "전화로 참여: <a>{number}<\/a>",
    "CallbackService.join_the_call": "통화 참여",
    "CallbackService.ko": "한국어",
    "CallbackService.last_name": "성",
    "CallbackService.location_closed": "현재 종료됨",
    "CallbackService.location_closed_info": "상담원이 이러한 요청에 응답하는 시간",
    "CallbackService.location_info": "해당 위치에 직접 서비스를 문의하세요.",
    "CallbackService.missing_phone_and_email": "차례가 되면 알려드리겠습니다. 전화번호나 이메일을 입력하세요.",
    "CallbackService.no_staff_warning": "해당 라인에 합류하면 가장 먼저 이용 가능한 직원이 서비스를 제공하게 됩니다. 이 사람은 이전에 선택한 직원 {name} 이 아닐 수 있습니다.",
    "CallbackService.not_taking_callbacks": "이 위치는 현재 전화 콜백을 받지 않습니다.",
    "CallbackService.not_taking_online_queue": "이 위치는 현재 온라인 큐 요청을 받지 않습니다.",
    "CallbackService.notes": "메모",
    "CallbackService.online_queuing": "{vendorName} 온라인 큐",
    "CallbackService.optional": "선택 사항",
    "CallbackService.phone": "연락받을 번호",
    "CallbackService.phone_type": "전화",
    "CallbackService.pin": "PIN: {pin}",
    "CallbackService.pl": "폴란드어",
    "CallbackService.pt": "포르투갈어",
    "CallbackService.queue_appointment_details": "큐 약속 세부 정보",
    "CallbackService.required": "필수",
    "CallbackService.ru": "러시아어",
    "CallbackService.sms_opt_in": "약속에 관한 문자 메시지를 받아보겠습니다.",
    "CallbackService.sms_required": "이 번호로 약속에 관한 업데이트를 문자로 보내드립니다. 표준 문자 전송 요금이 부과될 수 있습니다.",
    "CallbackService.submit_form": "제출",
    "CallbackService.successfully_added": "요청이 추가됨",
    "CallbackService.topic": "서비스 선택",
    "CallbackService.topic_prefill": "{name}, 서비스 선택",
    "CallbackService.topic_wait_time": "{waitTime} 예상 대기 시간",
    "CallbackService.video_type": "동영상",
    "CallbackService.walkinservices": "워크인 서비스",
    "CallbackService.we_will_notify_you": "다음이 귀하 차례가 되면 알려드리겠습니다.",
    "CallbackService.we_will_notify_you_turn": "차례가 되면 알려드리겠습니다.",
    "CallbackService.well_call_you_back_at": ":phoneNumber(으)로 전화드리겠습니다.",
    "CallbackService.without_updates": "아니요, 업데이트 받지 않고 체크인",
    "CallbackService.x_minutes": "{minutes}분",
    "CallbackService.you_are_next": "콜백 다음 차례입니다.",
    "CallbackService.your_appointment": "나의 약속",
    "CallbackService.zh": "중국인",
    "CircularProgress.loading": "로드 중",
    "Convert.body": "편의상 더 나은 나중 날짜에 약속을 예약할 수 있습니다. 계속하기로 선택하면 약속 예약 프로세스를 완료한 경우에만 스폿을 잃게 됩니다.",
    "Convert.button": "약속으로 변환",
    "Convert.title": "기다리기 지루하신가요?",
    "CountrySelectInput.label": "국가",
    "Days.friday": "금요일",
    "Days.monday": "월요일",
    "Days.saturday": "토요일",
    "Days.sunday": "일요일",
    "Days.thursday": "목요일",
    "Days.tuesday": "화요일",
    "Days.wednesday": "수요일",
    "Details.confirmation_number": "확인 번호",
    "Details.curbside_appointment_title": "커브사이드 약속 세부 정보",
    "Details.curbside_details_label": "커브사이드 픽업 정보",
    "Details.date": "날짜",
    "Details.location": "위치",
    "Details.phone_call": "전화 통화",
    "Details.phone_number_ending_in": "{number}(으)로 끝나는 전화번호",
    "Details.queue_appointment_details": "큐 약속 세부 정보",
    "Details.service": "서비스",
    "Details.staff": "스태프",
    "Details.video_call": "영상 통화",
    "Details.we_will_call_you": "제공하신 번호로 전화드리겠습니다.",
    "Details.we_will_send_link": "차례가 되면 통화에 참여하기 위한 링크를 보내드리겠습니다.",
    "DetailsForm.agree_tos": "약속을 예약하면 Coconut Software의 {terms} 및 {privacy}에 동의하는 것이 됩니다.",
    "DetailsForm.agree_tos_with_privacy": "약속을 예약하면 Coconut Software의 {terms} 및 {privacy}, {vendor}의 {vendorPrivacy}에 동의하는 것이 됩니다.",
    "DetailsForm.agree_tos_with_tos": "약속을 예약하면 Coconut Software의 {terms} 및 {privacy}, {vendor}의 {vendorTerms}에 동의하는 것이 됩니다.",
    "DetailsForm.agree_tos_with_vendor": "약속을 예약하면 Coconut Software의 {terms} 및 {privacy}, {vendor}의 {vendorTerms} 및 {vendorPrivacy}에 동의하는 것이 됩니다.",
    "DetailsForm.agree_tos_with_vendor_full_privacy": "약속을 예약하면 {vendor}의 {vendorTerms} 및 {vendorPrivacy}에 동의하는 것이 됩니다.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_policy": "약속을 예약하면 {vendor}의 {vendorPrivacy}에 동의하는 것이 됩니다.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_tos": "약속을 예약하면 {vendor}의 {vendorTerms}에 동의하는 것이 됩니다.",
    "DetailsForm.coconut_privacy_url": "https:\/\/www.coconutsoftware.com\/privacy-policy\/",
    "DetailsForm.coconut_terms_url": "https:\/\/www.coconutsoftware.com\/terms-of-use\/",
    "DetailsForm.explicit_compliance_agreement": "동의",
    "DetailsForm.privacy": "개인정보 보호정책",
    "DetailsForm.terms_of_use": "이용약관",
    "DetailsForm.walkin_policy_action": "",
    "EmptyState.contact_vendor": "다시 시도하거나 문의하세요.",
    "Error": "요청에 오류가 발생함",
    "Feedback.error": "제출하기 전에 평점을 기재하세요.",
    "Feedback.experience": "내 경험",
    "Feedback.google_description": "당사는 사용자의 피드백을 진심으로 중시하며 다른 고객에게도 유익할 것이라고 생각합니다. 잠시 시간을 들여 Google 리뷰를 통해 당사와의 경험에 관해 다른 고객에게도 알려주실 의향이 있다면 아래의 버튼을 클릭하세요. 곧 리디렉션됩니다.",
    "Feedback.google_submit": "Google 리뷰 제출",
    "Feedback.neutral": "보통",
    "Feedback.new_title": "피드백",
    "Feedback.rating": "평점(필수)",
    "Feedback.response_prompt": "어떤 면에서 개선의 여지가 있는지 알려주세요.",
    "Feedback.send": "피드백 보내기",
    "Feedback.sending": "피드백 보내는 중",
    "Feedback.subtitle": "피드백은 익명 처리됩니다. 이 정보는 예약 경험을 개선하는 데 사용됩니다.",
    "Feedback.success": "피드백 제출이 완료되었습니다. 감사합니다.",
    "Feedback.thank_you": "피드백을 주셔서 감사합니다.",
    "Feedback.title": "이 약속을 예약한 경험이 어땠나요?",
    "Feedback.very_dissatisfied": "매우 불만족",
    "Feedback.very_satisfied": "매우 만족",
    "GenericError.subtitle": "요청하신 페이지를 찾을 수 없음",
    "GenericError.title": "죄송합니다. 문제가 발생했습니다.",
    "Instructions.title": "지침",
    "Location.select_location": "위치 선택",
    "LocationDetails.location_details": "위치 세부 정보",
    "LocationDetails.select_location": "이 위치 선택",
    "LocationDirectionsButton.text": "길 찾기",
    "LocationHours.closed": "종료됨",
    "LocationHours.timespan": "{open} - {closed}",
    "LocationHours.title": "운영 시간",
    "LocationList.no_locations": "위치를 찾을 수 없음",
    "LocationList.title": "위치 선택",
    "LocationPhoneButton.text": "{number}에 전화 걸기",
    "LocationPhoneCallButton.text": "{number}에 전화 걸기",
    "Manage.book_another": "다른 약속 예약",
    "Manage.restart": "홈페이지에서 다시 시작",
    "Questions.missing_both": "질문에 답하기 전에 이메일 주소 또는 휴대폰 번호를 제공하십시오",
    "Questions.missing_email": "질문에 답하기 전에 이메일 주소를 입력하세요.",
    "Questions.missing_phone": "질문에 답하기 전에 휴대전화 번호를 입력하세요.",
    "SelectInput.default": "선택",
    "Svg.altText.back": "뒤로",
    "Svg.altText.coconutLogo": "Coconut Software 로고",
    "Svg.altText.dropdown_arrow": "드롭다운 화살표",
    "Svg.altText.location_pin": "위치 핀",
    "Svg.altText.search": "검색",
    "Svg.altText.select_language": "언어 선택",
    "Svg.alt_text.appointment": "약속",
    "Svg.alt_text.back": "뒤로",
    "Svg.alt_text.cancelled": "취소됨",
    "Svg.alt_text.coconut_logo": "Coconut Software 로고",
    "Svg.alt_text.curbside": "커브사이드 서비스 아이콘",
    "Svg.alt_text.dropdown_arrow": "아래쪽 화살표 아이콘",
    "Svg.alt_text.location_error": "위치 오류 아이콘",
    "Svg.alt_text.location_pin": "위치",
    "Svg.alt_text.search": "검색",
    "Svg.alt_text.select_language": "언어 선택",
    "Svg.alt_text.up_arrow": "위쪽 화살표 아이콘",
    "Svg.alt_text.walk_in": "워크인 서비스 아이콘",
    "Ui.cancel": "약속 취소",
    "Ui.continue": "계속",
    "Ui.keep": "유지",
    "Ui.loading": "로드 중",
    "Ui.lobby_disabled": "",
    "Ui.no_available_staff_inperson": "이 위치는 현재 직접 방문을 받지 않습니다.",
    "Ui.no_available_staff_phone": "이 위치는 현재 전화 회신을 받지 않습니다.",
    "Ui.no_available_staff_video": "이 위치는 현재 비디오 콜백을 받지 않습니다.",
    "Ui.private_location": "이 위치는 비공개이므로 여기서 줄에 합류할 수 없습니다",
    "Ui.search": "검색 ...",
    "Ui.search.instructions": "검색하려면 입력을 시작하십시오.",
    "Ui.search.plain": "찾다"
}