import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

const WalkInContext = createContext({});

const WalkInProvider = ({ children }) => {
  const [walkIn, setWalkIn] = useState({});
  const [waitTime, setWaitTime] = useState(null);
  const [feedback, setFeedback] = useState(null);

  return (
    <WalkInContext.Provider
      value={{
        walkIn,
        setWalkIn,
        waitTime,
        setWaitTime,
        feedback,
        setFeedback,
      }}
    >
      {children}
    </WalkInContext.Provider>
  );
};

WalkInProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { WalkInContext, WalkInProvider };
