import PropTypes from 'prop-types';
import React from 'react';
import Appointment from '../../shared/icons/Appointment';
import Curbside from '../../shared/icons/Curbside';
import { FULFILLMENT_METHODS } from '../constants';
import AppointmentDetailsCard from './AppointmentDetailsCard';
import CardTitle from './CardTitle';

const AppointmentDetails = ({ walkIn, viewMode }) => {
  const isCurbside =
    walkIn?.attributes?.fulfillment_method === FULFILLMENT_METHODS.CURBSIDE;

  const title = isCurbside
    ? 'Details.curbside_appointment_title'
    : 'Details.queue_appointment_details';
  const icon = isCurbside ? Curbside : Appointment;

  return (
    <>
      <CardTitle icon={icon} title={title} />
      <AppointmentDetailsCard viewMode={viewMode} />
    </>
  );
};

AppointmentDetails.propTypes = {
  walkIn: PropTypes.shape({
    attributes: PropTypes.shape({ fulfillment_method: PropTypes.number }),
  }),
  viewMode: PropTypes.number.isRequired,
};

AppointmentDetails.defaultProps = {
  walkIn: null,
};

export default AppointmentDetails;
