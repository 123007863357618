import { features } from '../constants';

export default {
  isAllClientsVisibleEnabled(vendor) {
    return vendor.features.includes(features.ALL_CLIENTS_VISIBLE);
  },

  isBuilderEnabled(vendor) {
    return vendor.features.includes(features.BUILDER);
  },

  isClientGoogleLoginEnabled(vendor) {
    return vendor.features.includes(features.CLIENT_GOOGLE_LOGIN);
  },

  isClientGoogleRecaptchaEnabled(vendor) {
    return vendor.features.includes(features.CLIENT_GOOGLE_RECAPTCHA);
  },

  isDynamicsIntegrationEnabled(vendor) {
    return vendor.features.includes(features.MICROSOFT_DYNAMICS_INTEGRATION);
  },

  isExchangeSyncEnabled(vendor) {
    return vendor.features.includes(features.EXCHANGE_CALENDAR_SYNC);
  },

  isGliaEnabled(vendor) {
    return vendor.features.includes(features.GLIA);
  },

  isGSuiteEnabled(vendor) {
    return vendor.features.includes(features.G_SUITE);
  },

  isLobbyEnabled(vendor) {
    return vendor.features.includes(features.LOBBY);
  },

  isAppointmentBookingChannelEnabled(vendor) {
    return vendor.features.includes(features.APPOINTMENT_BOOKING_CHANNEL);
  },

  isSftpDestinationSyncEnabled(vendor) {
    return vendor.features.includes(features.EXTERNAL_DATA_DESTINATION);
  },

  isStaffDashboardEnabled() {
    return true;
  },

  isSortStaffByAvailabilityEnabled(vendor) {
    return vendor.features.includes(features.SORT_STAFF_BY_AVAILABILITY);
  },

  isLegacySalesforceEnabled(vendor) {
    return vendor.features.includes(features.ENABLE_SALESFORCE);
  },

  isSyncReportingEnabled(vendor) {
    return vendor.features.includes(features.SYNC_REPORTING);
  },

  isUtcTimesEnabled(vendor) {
    return vendor.features.includes(features.UTC_EVENT_TIME);
  },

  isReserveWithGoogleEnabled(vendor) {
    return vendor.features.includes(features.RESERVE_WITH_GOOGLE);
  },

  isSSOSettingsToolEnabled(vendor) {
    return vendor.features.includes(features.SSO_SETTINGS_TOOL);
  },

  isShortcutsEnabled() {
    return true;
  },

  isAppointmentMeetingMethodsEnabled(vendor) {
    return vendor.features.includes(features.APPOINTMENT_MEETING_METHODS);
  },

  isRoomBookingsEnabled(vendor) {
    return vendor.features.includes(features.ROOM_BOOKINGS);
  },

  isMicrosoftEntraIdEnabled(vendor) {
    return vendor.features.includes(features.MICROSOFT_ENTRA_ID);
  },

  isResourceCategoriesEnabled(vendor) {
    return vendor.features.includes(features.RESOURCE_CATEGORIES);
  },

  isHoursWeekViewEnabled(vendor) {
    return vendor.features.includes(features.HOURS_WEEK_VIEW);
  },

  isMultiStaffAppointmentsEnabled(vendor) {
    return vendor.features.includes(features.MULTIPLE_STAFF_APPOINTMENTS);
  },

  isMultipleStaffAppointmentsEnabled(vendor) {
    return vendor.features.includes(features.MULTIPLE_STAFF_APPOINTMENTS);
  },

  isRestrictResourceAccessEnabled(vendor) {
    return vendor.features.includes(features.RESTRICT_RESOURCE_ACCESS);
  },

  isGuideNavItemEnabled(vendor) {
    return vendor.features.includes(features.GUIDE_NAV_ITEM);
  },

  isDisplayLocationIdentifiersEnabled(vendor) {
    return vendor.features.includes(features.DISPLAY_LOCATION_IDENTIFIERS);
  },

  isContactCentreLocationSearchEnabled(vendor) {
    return vendor.features.includes(features.CONTACT_CENTRE_LOCATION_SEARCH);
  },

  isCallCentreEnabled(vendor) {
    return vendor.features.includes(features.CALL_CENTRE);
  },

  isCoconutConnectAppointmentsEnabled(vendor) {
    return vendor.features.includes(features.COCONUT_CONNECT_APPOINTMENTS);
  },

  isCoconutConnectContactCentreEnabled(vendor) {
    return vendor.features.includes(features.COCONUT_CONNECT_CONTACT_CENTRE);
  },

  isCoconutConnectLobbyEnabled(vendor) {
    return vendor.features.includes(features.COCONUT_CONNECT_LOBBY);
  },

  isCoBrowsingEnabled(vendor) {
    return vendor.features.includes(features.CO_BROWSING);
  },

  isAdminViewSettingsEnabled(vendor) {
    return vendor.features.includes(features.ADMIN_VIEW_SETTINGS);
  },

  isCurbsidePickupEnabled(vendor) {
    return vendor.features.includes(features.CURBSIDE_PICKUP);
  },

  isIntegrationDashboardEnabled(vendor) {
    return vendor.features.includes(features.INTEGRATION_DASHBOARD);
  },

  isHideReportsAndDataManagementEnabled(vendor) {
    return vendor.features.includes(features.HIDE_REPORTS_AND_DATA_MANAGEMENT);
  },

  isCoconutConnectEnabled(vendor) {
    return (
      this.isCoconutConnectAppointmentsEnabled(vendor) ||
      this.isCoconutConnectContactCentreEnabled(vendor) ||
      this.isCoconutConnectLobbyEnabled(vendor)
    );
  },

  isCoconutConnectInstantMeetingsEnabled(vendor) {
    return vendor.features.includes(features.CONNECT_INSTANT_MEETINGS);
  },

  isBranchLocatorEnabled(vendor) {
    return vendor.features.includes(features.BRANCH_LOCATOR);
  },

  isUploadQuestionTypeEnabled(vendor) {
    return vendor.features.includes(features.UPLOAD_QUESTION_TYPE);
  },

  isESignaturesEnabled(vendor) {
    return vendor.features.includes(features.E_SIGNATURES);
  },

  isIdVerificationEnabled(vendor) {
    return vendor.features.includes(features.CONNECT_ID_VERIFICATION);
  },

  isAdvisorChatEnabled(vendor) {
    return vendor.features.includes(features.ADVISOR_CHAT);
  },

  isLiveAgentChatEnabled(vendor) {
    return vendor.features.includes(features.LIVE_AGENT_CHAT);
  },

  isWorkflowManagementEnabled(vendor) {
    return vendor.features.includes(features.WORKFLOW_MANAGEMENT);
  },

  isMultipleOrganizationsEnabled(vendor) {
    return vendor.features.includes(features.MULTIPLE_ORGANIZATIONS);
  },

  isAdminSharingBookingShortcutsEnabled(vendor) {
    return vendor.features.includes(features.ADMIN_SHARING_BOOKING_SHORTCUTS);
  },

  isSalesforceEnabled(vendor) {
    return (
      vendor.features.includes(features.SALESFORCE) &&
      !this.isLegacySalesforceEnabled(vendor)
    );
  },

  isGoogleTagManagerEnabled() {
    return true;
  },

  isTealiumTagManagerEnabled(vendor) {
    return vendor.features.includes(features.TEALIUM);
  },

  isAdobeTagManagerEnabled(vendor) {
    return vendor.features.includes(features.ADOBE);
  },

  isAllowCustomSortOrderEnabled(vendor) {
    return vendor.features.includes(features.ALLOW_CUSTOM_SORT_ORDER);
  },

  isBannoIntegrationEnabled(vendor) {
    return vendor.features.includes(features.BANNO_INTEGRATION);
  },

  isDispatchAfterResponseEnabled(vendor) {
    return vendor.features.includes(features.DISPATCH_AFTER_RESPONSE);
  },

  isRemoteInBranchEnabled(vendor) {
    return vendor.features.includes(features.REMOTE_IN_BRANCH);
  },
};
