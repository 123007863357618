export const DEFAULT_COUNTRY = 'US';
export const POPULAR_COUNTRIES = ['CA', 'US'];
export const QUESTION_TYPES = {
  TEXT: 1,
  SELECT: 2,
  CHECKBOX: 3,
  RADIO: 4,
  TEXTAREA: 5,
};
export const BOOKED_THROUGH = {
  ONLINE_QUEUE: 13,
  KIOSK: 14,
  TV: 15,
};
export const HEADER_HEIGHT = {
  DESKTOP: '4.5rem',
  MOBILE: '4rem',
};

export const MEETING_METHODS = {
  AT_BUSINESS: 1,
  PHONE_CALL: 2,
  VIDEO: 3,
};

export const FULFILLMENT_METHODS = {
  WALK_IN: 1,
  CURBSIDE: 2,
};

export const MANAGE_CONFIRMATION_PARAMS = ['id', 'code'];

export const QUEUE_APPOINTMENT_STATUSES = {
  CONFIRMED: 1,
  COMPLETE: 2,
  NO_SHOW: 3,
  CANCELLED: 4,
  IN_PROGRESS: 5,
};

export const DEFAULT_ATTENDEE_STATE = {
  email: '',
  firstName: '',
  lastName: '',
  notes: '',
  cellPhone: '',
  topic: 0,
  fulfillmentMethod: FULFILLMENT_METHODS.WALK_IN,
  carDetails: '',
  receiveSms: false,
};
