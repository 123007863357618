import PropTypes from 'prop-types';
import React, { createContext, useState, useContext } from 'react';

const VendorContext = createContext({ vendor: null });

const useVendor = () => useContext(VendorContext);

const VendorProvider = ({ children }) => {
  const [vendor] = useState(window.state.vendor);

  return (
    <VendorContext.Provider value={{ vendor }}>
      {children}
    </VendorContext.Provider>
  );
};

VendorProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { VendorContext, VendorProvider, useVendor };
