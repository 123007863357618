import 'core-js';
import 'url-polyfill';
import 'polyfill-array-includes';
import 'promise-polyfill/src/polyfill';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React from 'react';
import { render } from 'react-dom';
import { jss, ThemeProvider } from 'react-jss';
import { BrowserRouter } from 'react-router-dom';
import reset from 'reset-jss';
import { CxSnippetProvider } from '../shared/contexts/CxSnippetContext';
import { GoogleMapsApiProvider } from '../shared/contexts/GoogleMapsApiContext';
import { ResetProvider } from '../shared/contexts/ResetContext';
import App from './containers/App';
import { FeatureDecisionProvider } from './contexts/FeatureDecisionContext';
import { LocaleProvider } from './contexts/LocaleContext';
import { LocationProvider } from './contexts/LocationContext';
import { MeetingMethodProvider } from './contexts/MeetingMethodContext';
import { SelectionProvider } from './contexts/SelectionContext';
import { ServiceProvider } from './contexts/ServiceContext';
import { ServicesProvider } from './contexts/ServicesContext';
import { VendorProvider } from './contexts/VendorContext';
import { ViewModeProvider } from './contexts/ViewModeContext';
import { WaitTimeProvider } from './contexts/WaitTimeContext';
import { WalkInProvider } from './contexts/WalkInContext';
import Theme from './helpers/Theme';

jss.setup({
  insertionPoint: document.getElementById('jss-insertion-point'),
});
jss.createStyleSheet(reset).attach();

const CallbackService = () => {
  const { theme } = window.state;
  const primary = theme?.primary || '#2979FF';
  const secondary = theme?.secondary || '#2979FF';
  const MuiTheme = createMuiTheme({
    typography: {
      fontFamily: theme?.mcv_font_family || 'Roboto',
      fontWeightRegular: theme?.mcv_font_normal_weight || '400',
      fontWeightMedium: theme?.mcv_font_bold_weight || '500',
    },
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
    },
  });

  return (
    <BrowserRouter>
      <GoogleMapsApiProvider
        apiKey={window.state.env.GOOGLEMAPS_API_KEY}
        countries={window.state.countries}
      >
        <ViewModeProvider>
          <ThemeProvider theme={Theme.create()}>
            <VendorProvider>
              <LocationProvider>
                <WaitTimeProvider>
                  <ServicesProvider>
                    <ServiceProvider>
                      <LocaleProvider>
                        <MeetingMethodProvider>
                          <ResetProvider>
                            <WalkInProvider>
                              <FeatureDecisionProvider>
                                <CxSnippetProvider>
                                  <SelectionProvider>
                                    <MuiThemeProvider theme={MuiTheme}>
                                      <>
                                        <CssBaseline />
                                        <App />
                                      </>
                                    </MuiThemeProvider>
                                  </SelectionProvider>
                                </CxSnippetProvider>
                              </FeatureDecisionProvider>
                            </WalkInProvider>
                          </ResetProvider>
                        </MeetingMethodProvider>
                      </LocaleProvider>
                    </ServiceProvider>
                  </ServicesProvider>
                </WaitTimeProvider>
              </LocationProvider>
            </VendorProvider>
          </ThemeProvider>
        </ViewModeProvider>
      </GoogleMapsApiProvider>
    </BrowserRouter>
  );
};

render(<CallbackService />, document.getElementById('app'));
