import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import Websockets from '../../shared/helpers/Websockets';
import { WalkInContext } from '../contexts/WalkInContext';

const WalkInWebsocket = ({ children }) => {
  const { walkIn, setWalkIn } = useContext(WalkInContext);

  useEffect(() => {
    Websockets.instance()
      .channel(`App.Models.QueueAppointment.${walkIn?.id}.public`)
      .listen('ManageQueueAppointmentUpdated', ({ data }) => {
        setWalkIn({
          ...walkIn,
          attributes: {
            ...walkIn?.attributes,
            dial_in: data?.attributes?.dial_in,
            status: data?.attributes?.status,
            meeting_link: data?.attributes?.meeting_link,
            user: data?.attributes?.user,
          },
        });
      });

    return () => {
      Websockets.instance()
        .channel(`App.Models.QueueAppointment.${walkIn?.id}.public`)
        .stopListening('ManageQueueAppointmentUpdated');
    };

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walkIn]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

WalkInWebsocket.propTypes = {
  children: PropTypes.element.isRequired,
};

export default WalkInWebsocket;
