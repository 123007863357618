import PropTypes from 'prop-types';
import React, { createContext, useState, useContext } from 'react';
import TagManager from '../../shared/helpers/TagManager';
import { VendorContext } from '../contexts/VendorContext';

const LocationContext = createContext({
  location: null,
  setLocation: () => {},
});

const useLocation = () => useContext(LocationContext);

const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(window.state.location);
  const { vendor } = useContext(VendorContext);

  const changeLang = (lang) => {
    location?.translations?.forEach((translation) => {
      if (translation.locale === lang) {
        location[translation.field] = translation.content;
      }
    });
    setLocation(location);
  };

  const contextSetLocation = (newLocation) => {
    TagManager.trackEngagement(
      vendor,
      TagManager.engagements.SELECT_LOCATION,
      newLocation.id,
    );
    // TODO this is a hacky solution to navigate to callback service form and ensure proper data is loaded
    if (newLocation) {
      const path = window.location.pathname.replace(/\/$/, '');
      window.location.href = `${path}/${newLocation.id}${window.location.search}`;
    }
  };

  return (
    <LocationContext.Provider
      value={{
        location,
        setLocation: contextSetLocation,
        changeLang,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

LocationProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { LocationContext, LocationProvider, useLocation };
