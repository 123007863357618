import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  root: theme.components.icons.default,
}));

const Success = ({ altText, classes: injectedClasses }) => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <svg
      className={classNames(injectedClasses.root, classes.root)}
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {altText ? <title>{altText}</title> : null}
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  );
};

Success.propTypes = {
  altText: PropTypes.string,
  classes: PropTypes.shape({ root: PropTypes.string }).isRequired,
};

Success.defaultProps = {
  altText: '',
};

export default Success;
