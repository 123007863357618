import { Grid, Paper, Box, Snackbar, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import axios from 'axios';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import ReserveWithGoogleConversion from '../../frontend/helpers/ReserveWithGoogleConversion';
import { SELECTION_STORAGE_KEY } from '../../shared/constants';
import { API_ROUTE } from '../../shared/helpers/api/open/QueueAppointments';
import mode from '../../shared/helpers/Mode';
import Storage from '../../shared/helpers/Storage';
import TagManager from '../../shared/helpers/TagManager';
import Url from '../../shared/helpers/Url';
import UTM from '../../shared/helpers/UtmParams';
import Footer from '../components/Footer';
import Form from '../components/Form';
import Instructions from '../components/Instructions';
import { BOOKED_THROUGH } from '../constants';
import { LocaleContext } from '../contexts/LocaleContext';
import { LocationContext } from '../contexts/LocationContext';
import { MeetingMethodContext } from '../contexts/MeetingMethodContext';
import { VendorContext } from '../contexts/VendorContext';
import { WalkInContext } from '../contexts/WalkInContext';
import Tracker from '../helpers/Tracker';

const useStyles = createUseStyles({
  content: {
    marginTop: '1rem !important',
    maxWidth: '1200px !important',
  },
  card: {
    marginBottom: '2rem',
  },
  instructions: {
    padding: '2rem',
  },
});

const formatForWalkInContext = ({ data, included }) => {
  if (!data || !included || !Array.isArray(included)) {
    return;
  }

  const attributes = {
    ...data.attributes,
    id: data.id,
  };

  // loop through included and add keys to main attributes
  included?.map((i) => {
    // need to unpluralize key names. ex services -> service
    const key = i.type?.replace(/s+$/, '');

    attributes[key] = i.attributes;

    // And add id to each, as it doesn't come with typical JSON attributes
    attributes[key].id = parseInt(i.id, 10);

    return i;
  });

  return {
    id: data.id,
    attributes,
  };
};

const CallbackService = () => {
  const classes = useStyles();
  const { locale } = useContext(LocaleContext);
  const { location } = useContext(LocationContext);
  const { meetingMethod } = useContext(MeetingMethodContext);
  const { vendor } = useContext(VendorContext);
  const { setWalkIn } = useContext(WalkInContext);

  const history = useHistory();

  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const { helpText } = window.state;

  const workflow = Url.params(history.location.search)?.workflow;
  const utm = UTM.getUtmParamsObject(Url.params(history.location.search));

  useEffect(() => {
    Tracker.view(window.location.pathname);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(null);
  };

  const submit = ({
    answers,
    firstName: first_name,
    lastName: last_name,
    cellPhone: cell_phone,
    email,
    topic: service_id,
    notes,
    receiveSms: receive_sms,
    carDetails: car_details,
    fulfillmentMethod: fulfillment_method,
  }) => {
    setSubmitting(true);

    if (vendor.callback_contact_options.sms_notifications === 'required') {
      receive_sms = true;
    }

    const currentMode = Url.params(history.location.search)?.mode;
    const origin = currentMode
      ? BOOKED_THROUGH[currentMode?.toUpperCase()]
      : BOOKED_THROUGH.ONLINE_QUEUE;

    const externalIdForPrefill =
      Storage.get(SELECTION_STORAGE_KEY)?.attendee?.externalId || null;

    const data = {
      type: 'queue-appointments',
      data: {
        attributes: {
          booked_through: origin,
          service_id,
          location_id: location.id,
          meeting_method: meetingMethod,
          fulfillment_method,
          car_details,
          notes,
          workflow_id: workflow,
          ...utm,
        },
        relationships: {
          client: {
            data: {
              type: 'client',
              attributes: {
                external_id: externalIdForPrefill,
                first_name,
                last_name,
                cell_phone,
                email,
                notes,
                lang: locale,
              },
              receive_sms,
            },
          },
        },
      },
    };

    if (Object.keys(answers).length > 0) {
      data.data.relationships.client.data.relationships = {
        answers: {
          data: Object.keys(answers)
            .map((key) => {
              const answer = answers[key];

              if (answer) {
                return {
                  type: 'answers',
                  attributes: {
                    question_id: key,
                    value: answer,
                  },
                };
              }

              return null;
            })
            .filter((answer) => answer !== null),
        },
      };
    }

    const setResponseData = (data) => {
      const formatted = formatForWalkInContext(data);
      setWalkIn(formatted);
    };

    axios
      .post(API_ROUTE, data)
      .then((response) => {
        setSubmitted(true);
        Tracker.view(`${window.location.pathname}/confirmation`);
        TagManager.trackEngagement(
          vendor,
          TagManager.engagements.BOOK_QUEUE_APPOINTMENT,
          'successful',
        );
        Storage.clear(SELECTION_STORAGE_KEY);

        setResponseData(response?.data);

        ReserveWithGoogleConversion.handleConversion(location.id);

        const confirmCode = `code=${response?.data?.data?.attributes?.confirm_code}`;
        const id = `&id=${response?.data?.data?.id}`;
        const lang = `&lang=${locale || 'en'}`;
        const kiosk = mode.checkAndGetKioskParam();
        const url = `/callback-service/confirmation?${confirmCode}${id}${kiosk}${lang}`;

        history.push(url);
      })
      .catch((e) => {
        setError(e);
        setSubmitting(false);
        TagManager.trackEngagement(
          vendor,
          TagManager.engagements.BOOK_QUEUE_APPOINTMENT,
          'failed',
        );
        setWalkIn({});
      });
  };

  return (
    <>
      {error ? (
        <Snackbar
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={handleClose}
              size="small"
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          }
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          className={classes.error}
          message={<FormattedMessage id="Error" />}
          onClose={handleClose}
          open={!!error}
        />
      ) : null}
      <Grid className={classes.content} item lg={6} md={9} xs={12}>
        <Box clone order={{ xs: 2, md: 1 }}>
          <Paper className={classNames(classes.card, classes.instructions)}>
            <Instructions
              bookingInstructions={helpText}
              showBookingInstructions
            />
          </Paper>
        </Box>
        {!submitted ? (
          <>
            <Form submit={submit} submitting={submitting} />
            <Footer />
          </>
        ) : null}
      </Grid>
    </>
  );
};

export default CallbackService;
