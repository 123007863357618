import axios from 'axios';
import PropTypes from 'prop-types';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from './LocationContext';
import { WaitTimeContext } from './WaitTimeContext';

const ServicesContext = createContext({
  services: null,
  setServices: () => {},
});

const useServices = () => useContext(ServicesContext);

const ServicesProvider = ({ children }) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const { location } = useLocation();
  const { setLoading: setWaitTimesLoading, setWaitTimes } =
    useContext(WaitTimeContext);

  useEffect(() => {
    setLoading(true);
    setWaitTimesLoading(true);
    if (location) {
      axios
        .get(`/api/v2/open/queue-appointments-services/${location.id}`)
        .then((response) => {
          setLoading(false);
          setWaitTimesLoading(false);
          setServices(response?.data[0]);
          setWaitTimes(response?.data[1]);
        })
        .catch(() => {
          setLoading(false);
          setWaitTimesLoading(false);
        });
    }
    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ServicesContext.Provider
      value={{
        loading,
        services,
        setServices,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};

ServicesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { ServicesContext, ServicesProvider, useServices };
