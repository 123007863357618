import { Paper, Grid, Typography, Link } from '@material-ui/core';
import React, { Fragment, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import TagManager from '../../shared/helpers/TagManager';
import { MEETING_METHODS } from '../constants';
import { LocaleContext } from '../contexts/LocaleContext';
import { LocationContext } from '../contexts/LocationContext';
import { MeetingMethodContext } from '../contexts/MeetingMethodContext';
import { VendorContext } from '../contexts/VendorContext';
import LocationErrorIcon from './icons/LocationErrorIcon';

const useStyle = createUseStyles((theme) => ({
  centered: {
    textAlign: 'center',
  },
  root: {
    padding: '2rem 0',
  },
  subtitle: {
    padding: '0 2rem 1rem 2rem',
  },
  info: {
    padding: '0 2rem',
  },
  title: {
    '& > span': {
      fontWeight: theme.fontWeights.medium,
    },
    padding: '0 2rem 1rem 2rem',
  },
}));

const ServicesUnavailable = () => {
  const classes = useStyle({ theme: useTheme() });
  const intl = useIntl();

  const {
    location: { address, city, phone, name, postal_zip: zip, prov_state: prov },
  } = useContext(LocationContext);
  const { locale } = useContext(LocaleContext);
  const { vendor } = useContext(VendorContext);
  const { meetingMethod } = useContext(MeetingMethodContext);

  useEffect(() => {
    const url = window.location.pathname;

    TagManager.trackVirtualPage(
      url,
      TagManager.pageNames.UNAVAILABLE,
      vendor,
      locale,
    );

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFormattedAddress = () => {
    let formattedAddress = '';
    [address, city, prov, zip].forEach((item) => {
      if (item) {
        formattedAddress = formattedAddress.concat(`${item}, `);
      }
    });

    return formattedAddress.trim().slice(0, -1);
  };

  const notTakingTypeMessage = () => {
    const messageId =
      meetingMethod === MEETING_METHODS.PHONE_CALL
        ? 'CallbackService.phone_type'
        : 'CallbackService.video_type';

    return (
      <FormattedMessage
        id="CallbackService.not_taking_callbacks"
        values={{
          type: intl.formatMessage({ id: messageId }).toLowerCase(),
        }}
      />
    );
  };

  return (
    <Paper data-testid="services-unavailable">
      <Grid
        alignItems="center"
        className={classes.root}
        container
        direction="row"
        justify="center"
        spacing={2}
      >
        <Grid className={classes.centered} item xs={12}>
          <LocationErrorIcon />
        </Grid>

        <Grid item xs={12}>
          <Typography align="center" className={classes.title} variant="h5">
            {meetingMethod === MEETING_METHODS.AT_BUSINESS ? (
              <FormattedMessage id="CallbackService.not_taking_online_queue" />
            ) : (
              notTakingTypeMessage()
            )}
          </Typography>
          {vendor.callback_closed_location_info ? (
            <>
              <Typography
                align="center"
                className={classes.subtitle}
                variant="body1"
              >
                <FormattedMessage id="CallbackService.location_info" />
              </Typography>
              <Typography
                align="center"
                className={classes.info}
                variant="body1"
              >
                {name}
              </Typography>
              <Typography
                align="center"
                className={classes.info}
                variant="body1"
              >
                {getFormattedAddress()}
              </Typography>
              {phone ? (
                <Typography
                  align="center"
                  className={classes.info}
                  variant="body1"
                >
                  <Link href={`tel:${phone}`}>{phone}</Link>
                </Typography>
              ) : null}
            </>
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ServicesUnavailable;
