import { Grid, Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { queueAppointmentStatuses } from '../../backend/constants';
import { localizeDuration } from '../../backend/helpers/Dashboard/Durations';
import GeneralError from '../../frontend/components/GeneralError';
import { SUPPORTED_LOCALES } from '../../shared/constants';
import mode from '../../shared/helpers/Mode';
import TagManager from '../../shared/helpers/TagManager';
import Url from '../../shared/helpers/Url';
import Cancelled from '../../shared/icons/Cancelled';
import EventBusyRounded from '../../shared/icons/EventBusyRounded';
import AppointmentActions from '../components/AppointmentActions';
import AppointmentDetails from '../components/AppointmentDetails';
import CardTitle from '../components/CardTitle';
import Confirmation from '../components/Confirmation';
import Feedback from '../components/Feedback';
import Button from '../components/forms/Button';
import Instructions from '../components/Instructions';
import Typography from '../components/Typography';
import { MANAGE_CONFIRMATION_PARAMS } from '../constants';
import { useFeature } from '../contexts/FeatureDecisionContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { useVendor } from '../contexts/VendorContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import { WalkInContext } from '../contexts/WalkInContext';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.typography.pxToRem(theme.spacing(2)),
  },
  button: {
    marginTop: theme.typography.pxToRem(theme.spacing(1.25)),
  },
  card: {
    padding: theme.typography.pxToRem(theme.spacing(3)),
    marginBottom: theme.typography.pxToRem(theme.spacing(1)),
  },
  offsetPadding: {
    paddingLeft: theme.typography.pxToRem(theme.spacing(4.75)),
  },
  pageTitle: {
    marginBottom: theme.typography.pxToRem(theme.spacing(1.5)),
    fontWeight: theme.typography.fontWeightMedium,
  },
  cancelContainer: {
    height: '89vh',
    maxWidth: theme.typography.pxToRem(theme.spacing(29)),
    margin: '0 auto',
    textAlign: 'center',
    paddingBottom: theme.typography.pxToRem(theme.spacing(10)),
    [theme.breakpoints.down(768)]: {
      height: '100%',
    },
  },
  cancelIcon: {
    fill: theme.palette.secondary.main,
    height: theme.typography.pxToRem(theme.spacing(7.5)),
    width: theme.typography.pxToRem(theme.spacing(7.5)),
    marginBottom: theme.typography.pxToRem(theme.spacing(2)),
  },
  spacing: {
    marginTop: theme.typography.pxToRem(theme.spacing(4)),
  },
}));

const Manage = () => {
  const classes = useStyles();
  const viewMode = useContext(ViewModeContext);
  const { locale } = useContext(LocaleContext);
  const { vendor } = useVendor();
  const historyLocation = useLocation();
  const { shouldShowQueueAppointmentFeedback } = useFeature();
  const { walkIn, waitTime, setWalkIn, setWaitTime, feedback, setFeedback } =
    useContext(WalkInContext);

  const { settings, location } = window.state;

  const walkInStatus = parseInt(walkIn?.attributes?.status, 10);
  const isComplete = walkInStatus === queueAppointmentStatuses.COMPLETE;
  const showHeader =
    walkInStatus === queueAppointmentStatuses.CONFIRMED ||
    walkInStatus === queueAppointmentStatuses.IN_PROGRESS;

  const showInstructions = !isComplete && location?.instructions;
  const showWaitTime = settings.show_wait_time && !isComplete;
  const showFeedback =
    !feedback &&
    shouldShowQueueAppointmentFeedback &&
    isComplete &&
    vendor.show_queue_appointment_feedback;

  useEffect(() => {
    const url = window.location.pathname;

    TagManager.trackVirtualPage(
      url,
      TagManager.pageNames.CONFIRMATION,
      vendor,
      locale,
    );

    if (!walkIn?.id && window.state.walkIn) {
      setWalkIn({
        id: window.state.walkIn.id,
        attributes: window.state.walkIn,
      });
      setWaitTime(localizeDuration(window.state.expectedStart, locale));
      setFeedback(window.state.feedback);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walkIn, locale, setWaitTime, setWalkIn, vendor]);

  mode.handleKioskConfirmation(
    null,
    () => {},
    true,
    window?.state?.location?.lang,
  );

  // Validates that both required parameters exist
  const isValid = () => {
    const params = Url.params(historyLocation.search);

    if (params?.id?.toString() !== walkIn?.attributes?.id?.toString()) {
      return false;
    }

    if (
      params?.code?.toString() !== walkIn?.attributes?.confirm_code?.toString()
    ) {
      return false;
    }

    return true;
  };

  const onError = () => {
    const params = Url.params(window.location.search);
    const query = Object.keys(params)
      .filter((key) => !MANAGE_CONFIRMATION_PARAMS.includes(key)) // filter out id/code params
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    const base = '/callback-service';

    window.location.assign(query ? `${base}?${query}` : base);
  };

  const bookAnother = () => {
    const { location } = window.state;
    const meetingMethod =
      window.state?.walkIn?.meeting_method || window.state.meeting_method;
    const lang = Object.values(SUPPORTED_LOCALES).includes(locale)
      ? locale
      : SUPPORTED_LOCALES.en;
    const url = `/callback-service/${location?.id}?meeting_method=${meetingMethod}&lang=${lang}`;
    window.location.assign(url);
  };

  const isKiosk = mode.isKiosk();

  return isValid() ? (
    walkInStatus === queueAppointmentStatuses.CANCELLED ? (
      <Grid
        alignItems="center"
        className={classes.cancelContainer}
        container
        direction="column"
        justify="center"
      >
        <Cancelled className={classes.cancelIcon} />
        <Typography component="h3" variant="h5">
          <FormattedMessage id="AppointmentCancelled.title" />
        </Typography>
        <div className={classes.spacing}>
          <Button onClick={bookAnother}>
            <FormattedMessage id="Manage.book_another" />
          </Button>
        </div>
      </Grid>
    ) : (
      <Grid container item md={7} xs={12}>
        <Grid alignItems="center" container item justify="center">
          {showHeader ? (
            <Grid className={classes.card} item xs={12}>
              <Confirmation
                showWaitTime={showWaitTime}
                waitTime={waitTime}
                walkIn={walkIn}
              />
            </Grid>
          ) : null}

          {showInstructions ? (
            <Grid item xs={12}>
              <Paper className={classes.card}>
                <CardTitle icon={InfoOutlined} title="Instructions.title" />
                <Box
                  className={viewMode !== MOBILE ? classes.offsetPadding : null}
                >
                  <Instructions showServiceInstructions />
                </Box>
              </Paper>
            </Grid>
          ) : null}
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.card}>
            <AppointmentDetails viewMode={viewMode} walkIn={walkIn} />
          </Paper>
        </Grid>

        {showFeedback ? (
          <Grid item xs={12}>
            <Feedback />
          </Grid>
        ) : null}

        {walkInStatus === queueAppointmentStatuses.CONFIRMED && !isKiosk ? (
          <Grid item xs={12}>
            <Paper className={classes.card}>
              <CardTitle
                icon={EventBusyRounded}
                title="CallbackService.changes_to_your_appointment"
              />
              <Box
                className={viewMode !== MOBILE ? classes.offsetPadding : null}
              >
                <AppointmentActions walkIn={walkIn} />
              </Box>
            </Paper>
          </Grid>
        ) : null}
      </Grid>
    )
  ) : (
    <Grid item xs={12}>
      <GeneralError
        bookAnother
        BookAnotherButton={
          <Button
            fullWidth={false}
            onClick={
              isKiosk ? () => mode.resetKiosk({}, null, true, locale) : onError
            }
            variant="primary"
          >
            <FormattedMessage
              id={isKiosk ? 'Manage.restart' : 'Manage.book_another'}
            />
          </Button>
        }
      />
    </Grid>
  );
};

export default Manage;
