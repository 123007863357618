import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';
import { localizeDuration } from '../../backend/helpers/Dashboard/Durations';

const WaitTimeContext = createContext({ getServiceWaitTime: () => {} });

const WaitTimeProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [waitTimes, setWaitTimes] = useState([]);
  const [showWaitTime] = useState(window.state.settings.show_wait_time);

  const getServiceWaitTime = (serviceId, locale) => {
    if (!showWaitTime || !waitTimes.length || loading) {
      return null;
    }

    if (serviceId) {
      const start = waitTimes.find(
        (waitTime) => parseInt(waitTime.id, 10) === parseInt(serviceId, 10),
      ).expectedStart;

      return localizeDuration(start, locale);
    }

    return null;
  };

  return (
    <WaitTimeContext.Provider
      value={{ getServiceWaitTime, loading, setLoading, setWaitTimes }}
    >
      {children}
    </WaitTimeContext.Provider>
  );
};

WaitTimeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { WaitTimeContext, WaitTimeProvider };
