import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  title: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.textSizes.lg,
  },
  icon: {
    color: theme.palette.primary[400],
    display: 'inline-flex',
    marginRight: '0.875rem',
  },
}));

const CardTitle = ({ title, icon: Icon }) => {
  const theme = useTheme();
  const classes = useStyles({ theme: useTheme() });

  return (
    <Typography classes={{ root: classes.title }} component="h4" variant="h6">
      <>
        {Icon ? (
          <span className={classNames(classes.icon, { icon: classes.title })}>
            <Icon fill={theme.palette.primary[400]} />
          </span>
        ) : null}
        {title ? <FormattedMessage id={title} /> : null}
      </>
    </Typography>
  );
};

CardTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.func,
};

CardTitle.defaultProps = {
  icon: null,
};

export default CardTitle;
