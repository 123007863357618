import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import NearMe from './icons/NearMe';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap',
  },
  distance: {
    display: 'flex',
  },
  icon: {
    fill: theme.palette.neutral[300],
    height: '1.25em',
    marginRight: '0.25rem',
    width: '1.25em',
  },
}));

const LocationDistanceIndicator = ({ distance, unit }) => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <div className={classes.root}>
      <Typography classes={{ root: classes.distance }} variant="caption1">
        <>
          <NearMe classes={{ root: classes.icon }} />
          {`${distance} ${unit}`}
        </>
      </Typography>
    </div>
  );
};

LocationDistanceIndicator.propTypes = {
  distance: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
};

export default LocationDistanceIndicator;
