import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Typography from '../Typography';
import HelpText from './HelpText';
import InputErrors from './InputErrors';

const useStyles = createUseStyles((theme) => ({
  label: {
    cursor: 'pointer',
    display: 'inline-block',
    marginBottom: '0.5rem',
  },
  radioGroup: {
    display: 'inline-flex',
    flexDirection: 'column',
  },
  radioInput: {
    alignItems: 'center',
    display: 'flex',
  },
  radio: {
    marginRight: '0.75rem',
    transform: 'scale(1.5)',
    verticalAlign: 'middle',
    '&:focus': {
      borderColor: theme.palette.info[400],
      boxShadow: theme.shadows.input,
      outline: 0,
    },
  },
  text: {
    fontWeight: theme.fontWeights.normal,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.5',
    marginTop: '0.25rem',
  },
}));

const RadioInput = ({
  errors,
  helpText,
  id,
  label,
  name,
  onChange,
  required,
  options,
  value,
}) => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <fieldset className={classes.radioGroup} id={id}>
      <legend className={classes.label}>
        <Typography variant="caption1">{label}</Typography>
      </legend>
      {options.map((option) => (
        <label className={classes.radioInput} key={option.value}>
          <input
            checked={value === `${option.value}`}
            className={classes.radio}
            name={name}
            onChange={onChange}
            required={required}
            type="radio"
            value={option.value}
          />
          <Typography classes={{ root: classes.text }} variant="label">
            {option.text}
          </Typography>
        </label>
      ))}
      {errors.length > 0 ? (
        <InputErrors errors={errors} />
      ) : (
        <HelpText value={helpText} />
      )}
    </fieldset>
  );
};

RadioInput.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  helpText: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RadioInput.defaultProps = {
  errors: [],
  helpText: null,
  onChange: null,
  required: true,
  value: '',
};

export default RadioInput;
