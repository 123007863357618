import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { useVendor } from './VendorContext';

const FeatureDecisionContext = createContext({});

const useFeature = () => useContext(FeatureDecisionContext);

const FeatureDecisionProvider = ({ children }) => {
  const { vendor } = useVendor();
  const features = vendor.featureDecisions;

  return (
    <FeatureDecisionContext.Provider value={features}>
      {children}
    </FeatureDecisionContext.Provider>
  );
};

FeatureDecisionProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { FeatureDecisionContext, FeatureDecisionProvider, useFeature };
