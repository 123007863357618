import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Error from '../../shared/icons/Error';
import Info from '../../shared/icons/Info';
import Success from '../../shared/icons/Success';
import Warning from '../../shared/icons/Warning';
import Typography from './Typography';

const icons = {
  error: Error,
  info: Info,
  notice: Info,
  success: Success,
  warning: Warning,
};

const altText = {
  error: 'error',
  info: 'info',
  notice: 'info',
  success: 'success',
  warning: 'warning',
};

const useStyles = createUseStyles((theme) => ({
  root: {
    borderRadius: theme.borderRadius.default,
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0.875rem',
  },
  action: {
    alignSelf: 'center',
    marginLeft: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '85%',
  },
  doubleAction: {
    marginTop: '0.75rem',
    display: 'flex',
    marginLeft: 'auto',

    '& > span': {
      marginLeft: '3rem',
    },
  },
  secondaryText: {
    marginTop: '0.75rem',
  },
  success: {
    backgroundColor: theme.palette.success[100],
  },
  successIcon: {
    color: theme.palette.success[400],
  },
  icon: {
    fontSize: 20,
    marginRight: '0.7rem',
  },
  info: {
    backgroundColor: theme.palette.info[100],
  },
  infoIcon: {
    color: theme.palette.info[400],
  },
  error: {
    backgroundColor: theme.palette.error[50],
  },
  errorIcon: {
    color: theme.palette.error[600],
  },
  notice: {
    border: `1px solid ${theme.palette.neutral[200]}`,
  },
  noticeIcon: {
    color: theme.palette.primary[400],
  },
  warning: {
    backgroundColor: theme.palette.warning[50],
  },
  warningIcon: {
    color: theme.palette.warning[700],
  },
  white: {
    backgroundColor: theme.palette.white,
  },
}));

const Alert = ({
  action,
  dismiss,
  icon,
  message,
  secondary,
  variant,
  white,
  ...other
}) => {
  const Icon = icons[variant];
  const classes = useStyles({ theme: useTheme() });
  const intl = useIntl();

  return (
    <div
      className={classNames(
        classes.root,
        classes[variant],
        white && classes.white,
      )}
      {...other}
    >
      {icon || (
        <Icon
          altText={intl.formatMessage({
            id: `Alert.${altText[variant].toLowerCase()}`,
          })}
          classes={{
            root: classNames(classes.icon, classes[`${variant}Icon`]),
          }}
        />
      )}
      <div className={classes.container}>
        <Typography variant={secondary ? 'title' : 'regular'}>
          {message}
        </Typography>
        {secondary ? (
          <Typography
            classes={{ root: classes.secondaryText }}
            dark
            variant="regular"
          >
            {secondary}
          </Typography>
        ) : null}
      </div>
      {dismiss && action ? (
        <div className={classes.doubleAction}>
          <span>{dismiss}</span>
          <span>{action}</span>
        </div>
      ) : (
        <div>
          {dismiss ? <div className={classes.action}>{dismiss}</div> : null}
          {action ? <div className={classes.action}>{action}</div> : null}
        </div>
      )}
    </div>
  );
};

Alert.propTypes = {
  action: PropTypes.element,
  dismiss: PropTypes.element,
  icon: PropTypes.element,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.string.isRequired,
  white: PropTypes.bool,
};

Alert.defaultProps = {
  action: null,
  dismiss: null,
  icon: null,
  secondary: '',
  white: false,
};

export default Alert;
