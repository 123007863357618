import { Grid, Box } from '@material-ui/core';
import { CheckCircleOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { FULFILLMENT_METHODS } from '../../backend/constants';
import { LocaleContext } from '../contexts/LocaleContext';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  check: {
    color: theme.palette.success[400],
    fontSize: '5rem !important',
    height: '5rem',
    display: 'block',
  },
  icon: {
    color: theme.palette.primary[400],
    display: 'inline-flex',
    marginRight: '0.875rem',
  },
  marginTop: {
    marginTop: '.5rem',
  },
  container: {
    textAlign: 'center',
  },
}));

const ApproximateWait = (waitTime, isCurbside, classes) => {
  if (isCurbside) {
    return (
      <Typography className={classes.marginTop} dark variant="caption1">
        <FormattedMessage id="CallbackService.we_will_notify_you_turn" />
      </Typography>
    );
  }

  if (!!waitTime && waitTime !== '0m') {
    return (
      <Typography dark variant="caption1">
        <>
          <FormattedMessage id="CallbackService.approximately" />
          &nbsp;
          {waitTime}
        </>
      </Typography>
    );
  }

  return (
    <Typography
      bold
      classes={{ root: classes.marginTop }}
      component="div"
      dark
      variant="caption2"
    >
      <FormattedMessage id="CallbackService.you_are_next" />
    </Typography>
  );
};

const Confirmation = ({ showWaitTime, waitTime, walkIn }) => {
  const classes = useStyles({ theme: useTheme() });
  const { locale } = useContext(LocaleContext);

  const isCurbside = walkIn.fulfillment_method === FULFILLMENT_METHODS.CURBSIDE;

  const { durationText: customContent } = window.state;

  const showCustomConfirmation = customContent && customContent[locale];

  const isWaitTimeZero = waitTime === '0m';

  return (
    <Box m={2} mb={4} textAlign="center">
      <Box marginBottom={2}>
        <CheckCircleOutlined className={classes.check} />
      </Box>

      {showCustomConfirmation ? (
        <Typography variant="h5">{customContent[locale]}</Typography>
      ) : isWaitTimeZero ? null : (
        <>
          <Typography
            bold
            classes={{ root: classes.marginTop }}
            component="div"
            dark
            variant="caption2"
          >
            <FormattedMessage id="CallbackService.successfully_added" />
          </Typography>
          <Typography
            classes={{ root: classes.marginTop }}
            component="div"
            dark
            variant="caption2"
          >
            <FormattedMessage id="CallbackService.we_will_notify_you" />
          </Typography>
        </>
      )}

      {showWaitTime && !!waitTime ? (
        <Grid item xs={12}>
          {ApproximateWait(waitTime, isCurbside, classes)}
        </Grid>
      ) : null}
    </Box>
  );
};

Confirmation.propTypes = {
  showWaitTime: PropTypes.bool.isRequired,
  waitTime: PropTypes.string,
  walkIn: PropTypes.shape({ fulfillment_method: PropTypes.number }),
};

Confirmation.defaultProps = {
  waitTime: '',
  walkIn: null,
};

export default Confirmation;
