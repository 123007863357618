import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Typography from '../Typography';
import HelpText from './HelpText';
import InputErrors from './InputErrors';

const useStyles = createUseStyles((theme) => ({
  checkboxContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
  },
  checkboxInput: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    marginLeft: '0rem',
    marginRight: '0.75rem',
    width: '1rem',
    height: '1rem',
    cursor: 'pointer',
    '&:focus': {
      borderColor: theme.palette.info[400],
      boxShadow: theme.shadows.input,
      outline: 0,
    },
  },
  label: {
    cursor: 'pointer',
    display: 'inline-block',
    marginBottom: '0.5rem',
  },
  text: {
    fontWeight: theme.fontWeights.normal,
    lineHeight: '1.5',
  },
}));

const CheckboxInput = ({
  errors,
  helpText,
  id,
  label,
  name,
  onChange,
  options,
  required,
  value,
}) => {
  const classes = useStyles({ theme: useTheme() });

  const resetRequiredValidity = () => {
    // Clear the custom error message for the checkboxes
    const checkboxes = document.getElementsByName(name);
    checkboxes.forEach((checkbox) => {
      checkbox.setCustomValidity('');
    });
  };

  return (
    <fieldset className={classes.checkboxContainer} id={id}>
      <legend className={classes.label}>
        {label !== null && <Typography variant="caption1">{label}</Typography>}
      </legend>
      {options.map((option) => (
        <label className={classes.checkboxInput} key={option.value}>
          <input
            checked={value[option.value]}
            className={classes.checkbox}
            name={name}
            onChange={onChange}
            onInput={required ? resetRequiredValidity : null}
            onInvalid={(e) => {
              if (!Object.values(value).includes(true)) {
                e.target.setCustomValidity('Please check at least one option.');
              }
            }}
            required={required ? !Object.values(value).includes(true) : false}
            type="checkbox"
            value={option.value}
          />
          <Typography classes={{ root: classes.text }} variant="label">
            {option.text}
          </Typography>
        </label>
      ))}
      {errors.length > 0 ? (
        <InputErrors errors={errors} />
      ) : (
        <HelpText value={helpText} />
      )}
    </fieldset>
  );
};

CheckboxInput.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  helpText: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  required: PropTypes.bool,
  value: PropTypes.objectOf(PropTypes.bool),
};

CheckboxInput.defaultProps = {
  errors: [],
  helpText: null,
  required: false,
  value: [],
};

export default CheckboxInput;
