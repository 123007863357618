import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Typography from '../Typography';

const useStyles = createUseStyles((theme) => ({
  bold: {
    fontWeight: theme.fontWeights.medium,
  },
  root: {
    marginTop: '0.375rem',
  },
}));

const HelpText = ({ bold, value }) => {
  const classes = useStyles({ theme: useTheme() });
  return (
    value && (
      <Typography
        classes={{ root: classNames(classes.root, bold && classes.bold) }}
        component="p"
        variant="help"
      >
        {value}
      </Typography>
    )
  );
};

HelpText.propTypes = {
  bold: PropTypes.bool,
  value: PropTypes.string,
};

HelpText.defaultProps = {
  bold: false,
  value: null,
};

export default HelpText;
