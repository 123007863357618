import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import EmptyState from '../../../frontend/components/EmptyState';
import LocationListItem from '../../../frontend/components/LocationListItem';
import NoResults from '../../../shared/icons/NoResults';

const useStyles = createUseStyles({
  blank: {
    marginTop: '2.5rem',
  },
});

const LocationList = ({ locations, markers, setChosen }) => {
  const classes = useStyles();

  if (locations.length) {
    const filteredLocations = locations.filter((location) => location.within);
    if (filteredLocations.length) {
      return filteredLocations.map((location) => (
        <LocationListItem
          key={location.id}
          location={location}
          markers={markers}
          setChosen={setChosen}
        />
      ));
    }
  }
  return (
    <EmptyState
      classes={{
        overrides: classes.blank,
      }}
      icon={<NoResults />}
      title={<FormattedMessage id="LocationList.no_locations" />}
    />
  );
};

LocationList.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      within: PropTypes.number,
    }),
  ),
  markers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.func)),
  setChosen: PropTypes.func.isRequired,
};

LocationList.defaultProps = {
  locations: [],
  markers: {},
};

export default LocationList;
