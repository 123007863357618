import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => {
  const header = 4.625;
  const spaceForLogo = 1.875;
  const headerWithMargin = header + 1.25;
  const headerWithMarginAndLogo = headerWithMargin + spaceForLogo;

  return {
    root: {
      background: theme.palette.white,
      borderRadius: theme.borderRadius.default,
      boxShadow: theme.shadows.overlay,
      display: 'flex',
      flexDirection: 'column',
      height: `calc(100vh - ${headerWithMarginAndLogo}rem)`,
      marginLeft: '1.25rem',
      overflow: 'hidden',
      position: 'absolute',
      top: `${headerWithMargin}rem`,
      width: '23.75rem',
    },
  };
});

const OldMapOverlay = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

OldMapOverlay.propTypes = {
  children: PropTypes.element.isRequired,
};

export default OldMapOverlay;
