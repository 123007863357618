import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

const MeetingMethodContext = createContext({ meetingMethod: null });

const MeetingMethodProvider = ({ children }) => {
  const [meetingMethod] = useState(window.state.meeting_method);

  return (
    <MeetingMethodContext.Provider value={{ meetingMethod }}>
      {children}
    </MeetingMethodContext.Provider>
  );
};

MeetingMethodProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { MeetingMethodContext, MeetingMethodProvider };
