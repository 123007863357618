import { Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import CoconutLogo from '../../frontend/components/CoconutLogo';
import { VendorContext } from '../contexts/VendorContext';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.typography.pxToRem(theme.spacing(4)),
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Footer = () => {
  const { vendor } = useContext(VendorContext);
  const classes = useStyles();
  const showLogo = vendor.private_label === false;

  return showLogo ? (
    <Grid className={classes.root} container item justify="center" xs={12}>
      <Link
        className={classes.link}
        href="https://www.coconutsoftware.com/?utm_source=coconut-product&utm_medium=coconut-online-queue&utm_campaign=coconut-logo"
        target="_blank"
        underline="none"
      >
        <Typography align="center" color="textSecondary" variant="caption">
          <FormattedMessage id="CallbackService.footer" />
        </Typography>
        <CoconutLogo />
      </Link>
    </Grid>
  ) : null;
};

export default Footer;
