import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from '../Typography';

const useStyles = createUseStyles({
  root: {
    marginTop: '0.375rem',
  },
});

const InputErrors = ({ errors }) => {
  const classes = useStyles();

  return errors.map((error) => (
    <Typography
      classes={{ root: classes.root }}
      component="p"
      key={error}
      variant="error"
    >
      {error}
    </Typography>
  ));
};

InputErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

InputErrors.defaultProps = {
  errors: [],
};

export default InputErrors;
