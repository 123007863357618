import Echo from 'laravel-echo';
import io from 'socket.io-client';
import Item from './Item';
import Reporter from './Reporter';

const { initialState } = window;

// typical reconnection delay is 20 seconds
const RECONNECTION_DELAY = 20 * 1000;

class Websockets {
  /**
   * @returns {Echo|boolean}
   */
  static instance(should = true) {
    if (should) {
      if (this.echo) {
        return this.echo;
      }

      this.echo = new Echo({
        broadcaster: 'socket.io',
        host: window.location.origin,
        client: io,
        transports: ['websocket'],
        auth: {
          headers: {
            Authorization: `Bearer ${Item.get(initialState || {}, 'jwt')}`,
          },
        },
      });

      return this.echo;
    }

    return false;
  }

  static getSocket() {
    return Websockets.instance().connector.socket;
  }

  static onReconnected(listener) {
    Websockets.getSocket().on('reconnect', listener);
    return () => Websockets.getSocket().off('reconnect', listener);
  }

  static onReconnecting(listener) {
    Websockets.getSocket().on('reconnecting', listener);
    return () => Websockets.getSocket().off('reconnecting', listener);
  }

  static onReconnectError(listener) {
    Websockets.getSocket().on('reconnect_error', listener);
    return () => Websockets.getSocket().off('reconnect_error', listener);
  }

  static onReconnectFailed(listener) {
    Websockets.getSocket().on('reconnect_failed', listener);
    return () => Websockets.getSocket().off('reconnect_failed', listener);
  }

  static onDisconnected(listener) {
    Websockets.getSocket().on('disconnect', listener);
    return () => Websockets.getSocket().off('disconnect', listener);
  }

  /**
   * Abstraction to listening for events on a channel
   *
   * @param {string} channelId
   * @param {Map<string, (event: any) => any>} listeners
   *
   * @return {() => void} Unsubscribe function
   */
  static listenToMultipleFromChannel(channelId, listeners = new Map()) {
    if (listeners.size === 0) {
      return () => {};
    }

    const channel = Websockets.instance().channel(channelId);

    listeners.forEach((listener, eventName) => {
      channel.listen(eventName, listener);
    });

    return () => {
      listeners.forEach((_listener, eventName) => {
        channel.stopListening(eventName);
      });
    };
  }

  /**
   * Will count the disconnection time between reconnect attempts and call the provided callback
   * with a reconnection strategy until it reconnects
   *
   * @param {(resolutionStrategy: 'graceful' | 'force') => void} resolutionCallback
   * @param {{ gracefulDelta: number; forceDelta: number; }} options
   *
   * @return {() => void} Unsubscribe function
   */
  static onConnectionResolution(resolutionCallback = () => {}, options = {}) {
    const { gracefulDelta, forceDelta } = {
      gracefulDelta: 5 * 60 * 1000,
      forceDelta: 15 * 60 * 1000,
      ...options,
    };
    let hasTriedGraceful = false;
    let hasTriedForced = false;
    let firstErrorDate = null;
    let lastErrorDate = null;

    const unsubReconnected = Websockets.onReconnected(() => {
      hasTriedGraceful = false;
      hasTriedForced = false;
      firstErrorDate = null;
      lastErrorDate = null;
    });

    const unsubReconnectError = Websockets.onReconnectError(() => {
      if (hasTriedForced) {
        return;
      }

      if (!firstErrorDate) {
        firstErrorDate = Date.now();
      }
      lastErrorDate = Date.now();
      const delta = lastErrorDate - firstErrorDate;

      if (delta > gracefulDelta && !hasTriedGraceful) {
        resolutionCallback('graceful');
        hasTriedGraceful = true;
        return;
      }

      if (delta > forceDelta && !hasTriedForced) {
        resolutionCallback('force');
        hasTriedForced = true;
      }
    });

    const unsubscribeToServerKill = Websockets.onDisconnected((reason) => {
      if (reason === 'io server disconnect') {
        Reporter.exception(
          new Error('The server killed the websocket connection'),
        );
        resolutionCallback('graceful');
      }
    });

    return () => {
      unsubscribeToServerKill();
      unsubReconnected();
      unsubReconnectError();
    };
  }

  /**
   * Will call the connectionFunction to connect and reconnect to the server
   * when the connection drops for a specific amount of time or reason.
   * @param {() => void} connectionFunction
   *
   * @return {() => void} Unsubscribe function
   */
  static withConnectionResolution(connectionFunction) {
    let connectionUnsub = connectionFunction();

    const resolutionUnsub = Websockets.onConnectionResolution(
      (resolutionMethod) => {
        if (resolutionMethod === 'force') {
          Reporter.exception(
            new Error(
              'Could not reconnect gracefully to the websocket server, refreshing the page',
            ),
          );
          window.location.reload();
          return;
        }

        if (resolutionMethod === 'graceful') {
          Reporter.message(
            'Attempting to reconnect gracefully to the websocket server',
          );
          Websockets.getSocket().disconnect();
          connectionUnsub();

          setTimeout(() => {
            Websockets.getSocket().connect();
            connectionUnsub = connectionFunction();
          }, RECONNECTION_DELAY);
        }
      },
    );

    return () => {
      resolutionUnsub();
      connectionUnsub();
    };
  }
}

export default Websockets;
