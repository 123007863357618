/* eslint-disable no-bitwise */
import parse from 'parse-color';

const convert = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
  ];
};

const lighten = (color, percent) => {
  const number = parseInt(color.replace('#', ''), 16);
  const amount = Math.round(2.55 * percent);
  const red = (number >> 16) + amount;
  const blue = ((number >> 8) & 0x00ff) + amount;
  const green = (number & 0x0000ff) + amount;

  return `#${(
    0x1000000 +
    (red < 255 ? (red < 1 ? 0 : red) : 255) * 0x10000 +
    (blue < 255 ? (blue < 1 ? 0 : blue) : 255) * 0x100 +
    (green < 255 ? (green < 1 ? 0 : green) : 255)
  )
    .toString(16)
    .slice(1)}`;
};

export default {
  create() {
    const primary = window.state?.theme?.primary || '#2979FF';
    const secondary = window.state?.theme?.secondary || '#2979FF';

    const mcvFontFamily = window.state.theme?.mcv_font_family || '';
    const fontFamilies = mcvFontFamily
      ? `${mcvFontFamily}, Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif`
      : 'Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif';
    const normalWeight = window.state.theme?.mcv_font_normal_weight || 400;
    const boldWeight = window.state.theme?.mcv_font_bold_weight || 500;

    return {
      borderRadius: {
        none: '0',
        sm: '0.125rem',
        default: '0.25rem',
        full: '50%',
      },
      components: {
        icons: {
          default: {
            display: 'inline-block',
            fill: 'currentColor',
            flexShrink: 0,
            fontSize: 24,
            height: '1em',
            transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            userSelect: 'none',
            width: '1em',
          },
        },
      },
      palette: {
        black: '#212121',
        white: '#FFFFFF',
        transparent: 'transparent',
        neutral: {
          100: '#F5F5F5',
          200: '#E0E0E0',
          250: '#BDBDBD',
          300: '#9E9E9E',
          350: '#757575',
          400: '#616161',
          500: '#3C3C3C',
          600: '#383838',
          700: '#333333',
          800: 'rgba(0, 0, 0, 0.9)',
        },
        primary: {
          100: lighten(primary, 72),
          200: lighten(primary, 64),
          400: primary,
          500: lighten(primary, -24),
          600: lighten(primary, -36),
        },
        secondary: {
          50: lighten(secondary, 82),
          100: lighten(secondary, 72),
          200: lighten(secondary, 64),
          400: secondary,
          500: lighten(secondary, -24),
          600: lighten(secondary, -36),
          A400: `rgba(${parse(secondary).rgb.join(',')},0.08)`,
          A500: `rgba(${parse(secondary).rgb.join(',')},0.16)`,
        },
        success: {
          100: '#E6F3E7',
          400: '#4CAF50',
        },
        info: {
          100: '#E3F2FD',
          400: '#2979FF',
        },
        error: {
          50: '#FFEBEE',
          600: '#E53935',
        },
        warning: {
          50: '#FFF8E1',
          700: '#FFA000',
        },
        danger: {
          400: '#C62828',
        },
        text: {
          secondary: 'rgba(0, 0, 0, 0.54)',
        },
        overlays: {
          light: 'rgba(255,255,255,0.54)',
        },
      },
      shadows: {
        input: `0 0 0 3px rgba(${convert('#2979FF').join(',')}, .5)`,
        card: `0 0 0 3px rgba(${convert(secondary).join(',')}, .5)`,
        dismiss: '0 0 0 3px rgba(255,255,255,.24)',
        default: '0 1px 2px 0 rgba(0,0,0,0.24)',
        overlay: '0 1px 8px 0 rgba(0,0,0,0.24)',
        hover: '0 2px 4px 0 rgba(0,0,0,0.16)',
        xs: '0 1px 2px 0 rgba(0,0,0,0.08)',
        md: '0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.12)',
        lg: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
      },
      textSizes: {
        xxs: '0.625rem', // 10px
        xs: '.75rem', // 12px
        sm: '.875rem', // 14px
        base: '1rem', // 16px
        lg: '1.25rem', // 20px
        xl: '1.5rem', // 24px
        xxl: '2rem', // 32px
      },
      fontFamilies: {
        sansSerif: fontFamilies,
      },
      fontWeights: {
        normal: normalWeight,
        medium: boldWeight,
      },
      leading: {
        xxs: '0.8125rem', // 13px
        xs: '1rem', // 16px
        sm: '1.1875rem', // 19px
        base: '1.3125rem', // 21px
        lg: '1.625rem', // 26px
        xl: '2rem', // 32px
      },
      transitions: {
        border: 'border 0.1s',
        boxShadow: 'box-shadow 0.1s',
      },
    };
  },
};
