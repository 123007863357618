import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Typography from '../Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    appearance: 'none',
    borderRadius: theme.borderRadius.sm,
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'block',
    margin: 0,
    minWidth: '4rem',
    padding: '0.75rem',
    transition: theme.transitions.border,
    '&[disabled]': {
      borderColor: theme.palette.neutral[200],
      background: theme.palette.neutral[100],
      cursor: 'not-allowed',
      '&:focus': {
        borderColor: theme.palette.neutral[200],
        background: theme.palette.neutral[100],
      },
      '&:hover': {
        borderColor: theme.palette.neutral[200],
        background: theme.palette.neutral[100],
      },
    },
  },
  buttonText: {
    color: 'inherit',
    fontWeight: theme.fontWeights.medium,
    textTransform: 'uppercase',
  },
  fullWidth: {
    width: '100%',
  },
  override: {},
  outlined: {
    background: 'transparent',
    border: `1px solid ${theme.palette.secondary[400]}`,
    borderRadius: theme.borderRadius.default,
    '&:active': {
      background: `${theme.palette.secondary[600]} !important`,
      borderColor: `${theme.palette.secondary[600]} !important`,
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&:hover': {
      background: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&:focus': {
      backgroundColor: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      boxShadow: theme.shadows.input,
      outline: 0,
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&[disabled] $buttonText': {
      color: theme.palette.neutral[400],
    },
    '& $buttonText': {
      color: theme.palette.secondary[400],
      fontWeight: theme.fontWeights.normal,
      textTransform: 'none',
    },
  },
  primary: {
    background: theme.palette.secondary[400],
    border: 'none',
    borderRadius: theme.borderRadius.default,
    color: theme.palette.white,
    '&:focus': {
      boxShadow: theme.shadows.input,
    },
    '&:hover': {
      background: theme.palette.secondary[500],
    },
    '&:active': {
      background: theme.palette.secondary[600],
    },
  },
  secondary: {
    background: 'none',
    border: 'none',
    color: theme.palette.secondary[400],
    '&:focus': {
      background: theme.palette.neutral[200],
    },
    '&:hover': {
      background: theme.palette.neutral[200],
    },
    '&:active': {
      background: theme.palette.neutral[300],
    },
  },
  smallOutlined: {
    background: 'transparent',
    border: `1px solid ${theme.palette.secondary[400]}`,
    borderRadius: theme.borderRadius.default,
    padding: '0.5rem 0.75rem',
    '&:active': {
      background: `${theme.palette.secondary[600]} !important`,
      borderColor: `${theme.palette.secondary[600]} !important`,
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&:hover': {
      background: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&:focus': {
      backgroundColor: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      boxShadow: theme.shadows.input,
      outline: 0,
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&[disabled] $buttonText': {
      color: theme.palette.neutral[400],
    },
    '& $buttonText': {
      color: theme.palette.secondary[400],
      fontWeight: theme.fontWeights.normal,
      textTransform: 'none',
    },
  },
  smallOutlinedChip: {
    background: 'transparent',
    border: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: '1.25rem',
    padding: '0.5rem 0.75rem',
    '&:hover': {
      background: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      color: theme.palette.white,
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&:focus': {
      background: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      outline: 0,
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&[disabled] $buttonText': {
      color: theme.palette.neutral[400],
    },
    '& $buttonText': {
      color: theme.palette.secondary[400],
      fontWeight: theme.fontWeights.normal,
      textTransform: 'none',
    },
  },
  smallChip: {
    background: 'transparent',
    border: `1px solid ${theme.palette.neutral[250]}`,
    borderRadius: '1.25rem',
    color: theme.palette.secondary[400],
    padding: '0.5rem 0.75rem',
    '&:focus': {
      '& $buttonText': {
        color: theme.palette.white,
      },
      background: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      outline: 0,
    },
    '&:hover': {
      background: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&:active': {
      background: theme.palette.secondary[400],
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&[disabled] $buttonText': {
      color: theme.palette.neutral[400],
    },
    '& $buttonText': {
      color: theme.palette.secondary[400],
      fontWeight: theme.fontWeights.normal,
      textTransform: 'none',
    },
  },
  tertiary: {
    background: 'transparent',
    border: `1px solid ${theme.palette.secondary[400]}`,
    borderRadius: theme.borderRadius.default,
    '&:focus': {
      boxShadow: theme.shadows.input,
      background: theme.palette.secondary[400],
      border: 'none',
      borderRadius: theme.borderRadius.default,
      color: theme.palette.white,
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&:hover': {
      background: theme.palette.secondary[400],
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&:active': {
      background: theme.palette.secondary[600],
    },
    '&:disabled': {
      background: 'transparent',
      color: theme.palette.neutral[300],
      cursor: 'not-allowed',
    },
    '& $buttonText': {
      color: theme.palette.secondary[400],
    },
  },
  text: {
    background: 'none',
    border: 'none',
    color: theme.palette.secondary[400],
    minWidth: 0,
    padding: 0,
  },
}));

const Button = ({
  children,
  disabled,
  fullWidth,
  isLoading,
  onClick,
  type,
  variant,
  className,
  ...rest
}) => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <button
      className={classNames(
        className,
        classes.root,
        classes.override,
        classes[variant],
        fullWidth && classes.fullWidth,
      )}
      disabled={disabled}
      onClick={onClick}
      type={
        type === 'submit' ? 'submit' : type === 'reset' ? 'reset' : 'button'
      }
      {...rest}
    >
      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <Typography
          align="left"
          classes={{ root: classes.buttonText }}
          color="primary"
          variant="caption1"
        >
          {children}
        </Typography>
      )}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.oneOf([
    'outlined',
    'primary',
    'secondary',
    'smallOutlined',
    'smallOutlinedChip',
    'smallChip',
    'tertiary',
    'text',
  ]),
};

Button.defaultProps = {
  className: '',
  disabled: false,
  fullWidth: true,
  isLoading: false,
  onClick: null,
  type: 'button',
  variant: 'primary',
};

export default Button;
