export default {
    "Alert.error": "Erreur",
    "Alert.info": "Info",
    "Alert.success": "Succès",
    "Alert.warning": "Avertissement",
    "AppointmentCancelled.title": "Ce rendez-vous a été annulé",
    "BackButton.back": "Arrière",
    "BackButton.back_results": "Retour aux résultats",
    "CallbackRequest.callback_request_disabled": "Nous ne prenons actuellement aucune demande de rappel.",
    "CallbackRequest.not_available": "Indisponible",
    "CallbackService.approximately": "Environ",
    "CallbackService.approximately_x_minutes": "Environ {minutes} minutes",
    "CallbackService.callback_button": "Rappelle-moi",
    "CallbackService.callback_service": "Service de rappel {vendor}",
    "CallbackService.cancel_appointment": "Annuler le rendez-vous",
    "CallbackService.cancel_appointment_confirm": "Voulez-vous vraiment annuler ce rendez-vous ? L'annulation d'un rendez-vous est irréversible.",
    "CallbackService.cancel_callback": "Annuler le rappel",
    "CallbackService.changes_to_your_appointment": "Modifications de votre rendez-vous",
    "CallbackService.curbside.radio.car_details": "La marque, le modèle et la couleur de votre voiture",
    "CallbackService.curbside.radio.curbside": "En bordure de rue",
    "CallbackService.curbside.radio.joining_line": "Comment rejoignez-vous la ligne?",
    "CallbackService.curbside.radio.parking_details": "Numéro de place de parking",
    "CallbackService.curbside.radio.pickup_information": "Informations sur le ramassage en bordure de rue",
    "CallbackService.curbside.radio.walkin": "Entrer",
    "CallbackService.curbsideservices": "Services en bordure de rue",
    "CallbackService.email": "Courriel",
    "CallbackService.en": "Anglais",
    "CallbackService.es": "Espagnol",
    "CallbackService.first_name": "Prénom",
    "CallbackService.footer": "Alimenté par",
    "CallbackService.fr": "Français",
    "CallbackService.instructions": "Ajoutez-vous pour être vu aujourd'hui. Lorsqu'un membre du personnel est prêt à vous servir, nous vous informerons de revenir.",
    "CallbackService.join_by_phone": "Rejoindre par téléphone : <a> {number} <\/a>",
    "CallbackService.join_the_call": "Rejoignez l'appel",
    "CallbackService.ko": "coréen",
    "CallbackService.last_name": "Nom de famille",
    "CallbackService.location_closed": "Actuellement fermé",
    "CallbackService.location_closed_info": "Nos agents répondent à ces demandes pendant ces heures",
    "CallbackService.location_info": "Veuillez contacter directement l'emplacement pour le service.",
    "CallbackService.missing_phone_and_email": "Nous vous avertirons quand ce sera votre tour. Veuillez fournir un numéro de téléphone ou un courriel",
    "CallbackService.no_staff_warning": "En rejoignant la file d'attente, vous serez servi par le premier membre du personnel disponible. Il ne s'agit peut-être pas du membre du personnel, {name} que vous aviez sélectionné précédemment.",
    "CallbackService.not_taking_callbacks": "Cet emplacement ne prend pas de rappels téléphoniques pour le moment.",
    "CallbackService.not_taking_online_queue": "Cet emplacement n'accepte pas les demandes de file d'attente en ligne pour le moment.",
    "CallbackService.notes": "Remarques",
    "CallbackService.online_queuing": "{vendorName} File d'attente en ligne",
    "CallbackService.optional": "Facultatif",
    "CallbackService.phone": "Numéro de téléphone à appeler",
    "CallbackService.phone_type": "Téléphoner",
    "CallbackService.pin": "NIP : {pin}",
    "CallbackService.pl": "polonais",
    "CallbackService.pt": "Portugais",
    "CallbackService.queue_appointment_details": "Détails des rendez-vous de la file d'attente",
    "CallbackService.required": "Requis",
    "CallbackService.ru": "russe",
    "CallbackService.sms_opt_in": "Je souhaite recevoir des SMS concernant mon rendez-vous",
    "CallbackService.sms_required": "Vous recevrez des mises à jour par SMS concernant votre rendez-vous via ce numéro. Des tarifs de messagerie standard peuvent s'appliquer.",
    "CallbackService.submit_form": "Soumettre",
    "CallbackService.successfully_added": "Votre demande a bien été ajoutée",
    "CallbackService.topic": "Sélectionnez un service",
    "CallbackService.topic_prefill": "{name} , sélectionnez un service",
    "CallbackService.topic_wait_time": "{waitTime} Temps d'attente estimé",
    "CallbackService.video_type": "Vidéo",
    "CallbackService.walkinservices": "Services sans rendez-vous",
    "CallbackService.we_will_notify_you": "Nous vous informerons une fois que vous serez le prochain en ligne",
    "CallbackService.we_will_notify_you_turn": "Nous vous informerons lorsque ce sera votre tour",
    "CallbackService.well_call_you_back_at": "Nous vous appellerons à {phoneNumber}",
    "CallbackService.without_updates": "Non, enregistrez-vous sans recevoir de mises à jour",
    "CallbackService.x_minutes": "{minutes} minutes",
    "CallbackService.you_are_next": "Vous êtes le prochain",
    "CallbackService.your_appointment": "Votre rendez-vous",
    "CallbackService.zh": "Chinois",
    "CircularProgress.loading": "Chargement",
    "Convert.body": "Vous pouvez prendre rendez-vous à une date ultérieure qui pourrait être plus pratique. Si vous choisissez de continuer, vous ne perdrez votre place dans la file d'attente tant que vous terminez pas le processus de prise de rendez-vous.",
    "Convert.button": "Convertir en rendez-vous",
    "Convert.title": "Fatigué d'attendre?",
    "CountrySelectInput.label": "Pays",
    "Days.friday": "Vendredi",
    "Days.monday": "Lundi",
    "Days.saturday": "Samedi",
    "Days.sunday": "Dimanche",
    "Days.thursday": "Jeudi",
    "Days.tuesday": "Mardi",
    "Days.wednesday": "Mercredi",
    "Details.confirmation_number": "Numéro de confirmation",
    "Details.curbside_appointment_title": "Détails du rendez-vous en bordure de rue",
    "Details.curbside_details_label": "Informations sur le ramassage en bordure de rue",
    "Details.date": "Date",
    "Details.location": "Emplacement",
    "Details.phone_call": "Appel téléphonique",
    "Details.phone_number_ending_in": "Numéro de téléphone se terminant par {number}",
    "Details.queue_appointment_details": "Détails des rendez-vous de la file d'attente",
    "Details.service": "Un service",
    "Details.staff": "Personnel",
    "Details.video_call": "Appel vidéo",
    "Details.we_will_call_you": "Nous vous appellerons au numéro que vous avez fourni",
    "Details.we_will_send_link": "Nous vous enverrons un lien pour rejoindre l'appel lorsque ce sera votre tour",
    "DetailsForm.agree_tos": "En prenant rendez-vous, vous acceptez les {terms} et l’{privacy} de Coconut Software.",
    "DetailsForm.agree_tos_with_privacy": "En prenant rendez-vous, vous acceptez les {terms} et l’{privacy} de Coconut Software ainsi que l’{vendorPrivacy} de {vendor}.",
    "DetailsForm.agree_tos_with_tos": "En prenant rendez-vous, vous acceptez les {terms} et l’{privacy} de Coconut Software ainsi que les {vendorTerms} de {vendor}.",
    "DetailsForm.agree_tos_with_vendor": "En prenant rendez-vous, vous acceptez les {terms} et l’{privacy} de Coconut Software ainsi que les {vendorTerms} et l’{vendorPrivacy} de {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy": "En réservant un rendez-vous, vous acceptez les {vendorTerms} et l’{vendorPrivacy} de {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_policy": "En réservant un rendez-vous, vous acceptez l’{vendorPrivacy} de {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_tos": "En réservant un rendez-vous, vous acceptez les {vendorTerms} de {vendor}.",
    "DetailsForm.coconut_privacy_url": "https:\/\/www.coconutsoftware.com\/privacy-policy\/",
    "DetailsForm.coconut_terms_url": "https:\/\/www.coconutsoftware.com\/terms-of-use\/",
    "DetailsForm.explicit_compliance_agreement": "J'accepte",
    "DetailsForm.privacy": "avis de confidentialité",
    "DetailsForm.terms_of_use": "conditions d'utilisation",
    "DetailsForm.walkin_policy_action": "",
    "EmptyState.contact_vendor": "Veuillez réessayer ou contactez-nous",
    "Error": "Il y a eu une erreur avec votre requête",
    "Feedback.error": "Veuillez fournir une note avant de soumettre",
    "Feedback.experience": "Ton expérience",
    "Feedback.google_description": "Nous apprécions vraiment vos commentaires et nous pensons que nos autres clients en bénéficieront. Si vous avez quelques instants pour leur faire part de votre expérience avec nous via un avis Google, cliquez sur le bouton ci-dessous et nous vous redirigerons.",
    "Feedback.google_submit": "Soumettre un avis Google",
    "Feedback.neutral": "Neutre",
    "Feedback.new_title": "Retour d'information",
    "Feedback.rating": "Note (obligatoire)",
    "Feedback.response_prompt": "Dites-nous comment votre expérience aurait pu être meilleure",
    "Feedback.send": "Envoyer des commentaires",
    "Feedback.sending": "Envoi de commentaires",
    "Feedback.subtitle": "Vos commentaires sont anonymes. Nous utiliserons ces informations pour améliorer l'expérience de réservation.",
    "Feedback.success": "Commentaires envoyés avec succès, merci!",
    "Feedback.thank_you": "Merci pour vos commentaires!",
    "Feedback.title": "Comment avez-vous vécu la réservation de ce rendez-vous ?",
    "Feedback.very_dissatisfied": "Très insatisfait",
    "Feedback.very_satisfied": "Très satisfait",
    "GenericError.subtitle": "La page que vous recherchez est introuvable",
    "GenericError.title": "Oups, quelque chose s'est mal passé",
    "Instructions.title": "Instructions",
    "Location.select_location": "Sélectionnez un emplacement",
    "LocationDetails.location_details": "Détails de l'emplacement",
    "LocationDetails.select_location": "Sélectionnez cet emplacement",
    "LocationDirectionsButton.text": "Itinéraire",
    "LocationHours.closed": "Fermé",
    "LocationHours.timespan": "{open} - {closed}",
    "LocationHours.title": "Heures d'ouverture",
    "LocationList.no_locations": "Aucun emplacement trouvé",
    "LocationList.title": "Sélectionnez un emplacement",
    "LocationPhoneButton.text": "Appelez {number}",
    "LocationPhoneCallButton.text": "Appelez {number}",
    "Manage.book_another": "Prendre un autre rendez-vous",
    "Manage.restart": "Redémarrer depuis la page d'accueil",
    "Questions.missing_both": "Veuillez fournir une adresse e-mail ou un numéro de téléphone portable avant de répondre aux questions",
    "Questions.missing_email": "Veuillez fournir une adresse e-mail avant de répondre aux questions",
    "Questions.missing_phone": "Veuillez fournir un numéro de téléphone portable avant de répondre aux questions",
    "SelectInput.default": "Sélectionner",
    "Svg.altText.back": "Arrière",
    "Svg.altText.coconutLogo": "Logo du logiciel de noix de coco",
    "Svg.altText.dropdown_arrow": "Flèche déroulante",
    "Svg.altText.location_pin": "Goupille de localisation",
    "Svg.altText.search": "Chercher",
    "Svg.altText.select_language": "Choisir la langue",
    "Svg.alt_text.appointment": "Rendez-vous",
    "Svg.alt_text.back": "Arrière",
    "Svg.alt_text.cancelled": "Annulé",
    "Svg.alt_text.coconut_logo": "Logo du logiciel de noix de coco",
    "Svg.alt_text.curbside": "Icône de service en bordure de rue",
    "Svg.alt_text.dropdown_arrow": "Icône de flèche vers le bas",
    "Svg.alt_text.location_error": "Icône d'erreur de localisation",
    "Svg.alt_text.location_pin": "Emplacement",
    "Svg.alt_text.search": "Chercher",
    "Svg.alt_text.select_language": "Choisir la langue",
    "Svg.alt_text.up_arrow": "Icône flèche vers le haut",
    "Svg.alt_text.walk_in": "Icône de service à pied",
    "Ui.cancel": "Annuler le rendez-vous",
    "Ui.continue": "Continuez",
    "Ui.keep": "Garder",
    "Ui.loading": "Chargement",
    "Ui.lobby_disabled": "",
    "Ui.no_available_staff_inperson": "Cet emplacement n'accepte pas les visites en personne pour le moment",
    "Ui.no_available_staff_phone": "Cet emplacement ne prend pas de rappels téléphoniques pour le moment",
    "Ui.no_available_staff_video": "Cet emplacement ne prend pas de rappels vidéo pour le moment",
    "Ui.private_location": "Cet emplacement est privé et vous ne pouvez pas rejoindre la ligne ici",
    "Ui.search": "Chercher ...",
    "Ui.search.instructions": "Veuillez commencer à taper pour rechercher.",
    "Ui.search.plain": "Recherche"
}