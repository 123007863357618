import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const Cancelled = ({ altText, className }) => {
  const intl = useIntl();

  return (
    <svg
      className={className}
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        {altText || intl.formatMessage({ id: 'Svg.alt_text.cancelled' })}
      </title>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M9.31 17l2.44-2.44L14.19 17l1.06-1.06-2.44-2.44 2.44-2.44L14.19 10l-2.44 2.44L9.31 10l-1.06 1.06 2.44 2.44-2.44 2.44L9.31 17zM19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11z" />
    </svg>
  );
};

Cancelled.propTypes = {
  altText: PropTypes.string,
  className: PropTypes.string,
};

Cancelled.defaultProps = {
  altText: '',
  className: null,
};

export default Cancelled;
