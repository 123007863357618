import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import { debounce } from 'throttle-debounce';
import LocationSuggestion from '../../frontend/components/LocationSuggestion';
import { SHORTCUTS } from '../../frontend/constants';
import Open from '../../frontend/helpers/api/Open';
import Math from '../../frontend/helpers/Math';
import Resources from '../../frontend/helpers/Resources';
import Shortcuts from '../../frontend/helpers/Shortcuts';
import { useGoogleMapsApi } from '../../shared/contexts/GoogleMapsApiContext';
import Item from '../../shared/helpers/Item';
import TagManager from '../../shared/helpers/TagManager';
import DesktopSearch from '../components/location/DesktopSearch';
import MobileSearch from '../components/location/MobileSearch';
import { LocaleContext } from '../contexts/LocaleContext';
import { LocationContext } from '../contexts/LocationContext';
import { VendorContext } from '../contexts/VendorContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Tracker from '../helpers/Tracker';

const LocationSearch = () => {
  const mode = useContext(ViewModeContext);
  const { setLocation } = useContext(LocationContext);
  const {
    vendor,
    vendor: { location_search_radius: searchRadius },
  } = useContext(VendorContext);
  const { locale } = useContext(LocaleContext);
  const [chosen, setChosen] = useReducer((state, newState) => newState, null);
  const [{ locations, isLoading }, setLocations] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      locations: [],
      isLoading: true,
    },
  );
  const [suggestions, setSuggestions] = useReducer(
    (state, newState) =>
      newState.map((suggestion) => (
        <LocationSuggestion
          identifier={suggestion.id}
          key={suggestion.id}
          suggestion={suggestion}
        />
      )),
    [],
  );

  const { getDetails, getSuggestions } = useGoogleMapsApi();

  const Api = Open.api();

  useEffect(() => {
    const url = `${window.location.pathname}/location`;

    Tracker.view(url);
    TagManager.trackVirtualPage(
      url,
      TagManager.pageNames.LOCATION,
      vendor,
      locale,
    );

    Api.locations()
      .assigned(true)
      .sortBy('sort_order,name')
      .take(1000)
      .get()
      .then(({ data: { data } }) => {
        const items = data
          ? data.map((location) => Resources.formatLocation(location))
          : [];
        setLocations({ locations: items, isLoading: false });
      });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickAway = () => {
    setSuggestions([]);
  };

  const selectSuggestion = ({ currentTarget: { dataset } }) => {
    const { identifier } = dataset;

    setSuggestions([]);

    getDetails(identifier)
      .then(({ latitude, longitude }) => {
        setLocations({
          isLoading: false,
          locations: Item.map(locations, (location) => {
            if (location.physical) {
              const end = {
                latitude,
                longitude,
              };

              const start = {
                latitude: location.coordinates.lat,
                longitude: location.coordinates.lng,
              };

              const [distance, unit] = Math.distance(
                start,
                end,
                location.country,
              );

              location.unit = unit;
              location.distance = distance;
              location.within =
                location.distance <=
                Shortcuts.get(SHORTCUTS.WITHIN, searchRadius);
            }

            return location;
          }).sort((a, b) => (a.distance || 0) - (b.distance || 0)),
        });
      })
      .catch(() => {
        //
      });
  };

  // In order to introduce linting to all JS projects without introducing
  // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
  //
  // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
  //
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchSuggestions = useCallback(
    debounce(200, (value) => {
      if (value === '') {
        setLocations({
          isLoading: false,
          locations: Item.map(locations, (location) => {
            location.within = true;

            delete location.unit;
            delete location.distance;

            return location;
          }).sort((a, b) => {
            if (a.sortOrder !== b.sortOrder) {
              return a.sortOrder - b.sortOrder;
            }

            if (a.name < b.name) {
              return -1;
            }

            if (a.name > b.name) {
              return 1;
            }

            return 0;
          }),
        });
      } else {
        getSuggestions(value, locale)
          .then((data) => {
            setSuggestions(data);
          })
          .catch(() => {
            //
          });
      }
    }),
    [getSuggestions, locale, locations],
  );

  const props = {
    chosen,
    fetchSuggestions,
    isLoading,
    locations,
    suggestions,
    selectSuggestion,
    setChosen,
    setLocation,
  };

  return mode === MOBILE ? (
    <MobileSearch {...props} />
  ) : (
    <DesktopSearch {...props} onClickAway={onClickAway} />
  );
};

export default LocationSearch;
