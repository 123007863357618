import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const Curbside = ({ altText, fill }) => {
  const intl = useIntl();

  return (
    <svg
      fill="none"
      height="23"
      viewBox="0 0 24 23"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        {altText || intl.formatMessage({ id: 'Svg.alt_text.curbside' })}
      </title>
      <path
        d="M20.4578 3.19938C20.2133 2.49875 19.5289 2 18.7222 2H5.27778C4.47111 2 3.79889 2.49875 3.54222 3.19938L1 10.3125V19.8125C1 20.4656 1.55 21 2.22222 21H3.44444C4.11667 21 4.66667 20.4656 4.66667 19.8125V18.625H19.3333V19.8125C19.3333 20.4656 19.8834 21 20.5556 21H21.7778C22.4499 21 23 20.4656 23 19.8125V10.3125L20.4578 3.19938ZM5.27778 15.0625C4.26333 15.0625 3.44444 14.2669 3.44444 13.2813C3.44444 12.2956 4.26333 11.5 5.27778 11.5C6.29222 11.5 7.11111 12.2956 7.11111 13.2813C7.11111 14.2669 6.29222 15.0625 5.27778 15.0625ZM18.7222 15.0625C17.7078 15.0625 16.8889 14.2669 16.8889 13.2813C16.8889 12.2956 17.7078 11.5 18.7222 11.5C19.7366 11.5 20.5556 12.2956 20.5556 13.2813C20.5556 14.2669 19.7366 15.0625 18.7222 15.0625ZM3.44444 9.125L5.27778 3.78125H18.7222L20.5556 9.125H3.44444Z"
        fill={fill}
      />
    </svg>
  );
};

Curbside.propTypes = {
  altText: PropTypes.string,
  fill: PropTypes.string,
};

Curbside.defaultProps = {
  altText: '',
  fill: '#FFB400',
};

export default Curbside;
