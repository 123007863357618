import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import BackButton from '../../../frontend/components/BackButton';
import EmptyState from '../../../frontend/components/EmptyState';
import SearchableInput from '../../../frontend/components/forms/SearchableInput';
import List from '../../../frontend/components/List';
import LocationDetails from '../../../frontend/components/LocationDetails';
import LocationDistanceIndicator from '../../../frontend/components/LocationDistanceIndicator';
import MobileCard from '../../../frontend/components/mobile/Card';
import Typography from '../../../frontend/components/Typography';
import NoResults from '../../../shared/icons/NoResults';

const useStyles = createUseStyles((theme) => ({
  back: {
    background: theme.palette.white,
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: '4rem',
    width: '100%',
    padding: '1.125rem 0',
  },
  details: {
    height: '100%',
  },
  emptyPage: {
    background: theme.palette.white,
    display: 'flex',
    flexGrow: 1,
    marginTop: '0.25rem',
    padding: 0,
    textAlign: 'center',
  },
  header: {
    background: theme.palette.white,
    padding: '1.25rem',
  },
  root: {
    background: theme.palette.neutral[200],
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  title: {
    display: 'block',
    marginBottom: '1.25rem',
  },
}));

const MobileSearch = ({
  chosen,
  fetchSuggestions,
  isLoading,
  locations,
  suggestions,
  selectSuggestion,
  setChosen,
  setLocation,
}) => {
  const classes = useStyles({ theme: useTheme() });

  const content = () => {
    if (isLoading) {
      return <div />;
    }

    if (chosen) {
      return (
        <>
          <header className={classes.back}>
            <BackButton
              previous={() => setChosen(null)}
              text={<FormattedMessage id="BackButton.back_results" />}
            />
          </header>
          <LocationDetails
            classes={{ height: classes.details }}
            location={chosen}
            selectLocation={setLocation}
            setChosen={setChosen}
            showHours={false}
          />
        </>
      );
    }

    const empty = (
      <section className={classes.emptyPage}>
        <EmptyState
          icon={<NoResults />}
          subtitle={
            <FormattedMessage
              id="EmptyState.contact_vendor"
              values={{ vendor: document.title }}
            />
          }
          title={<FormattedMessage id="LocationList.no_locations" />}
        />
      </section>
    );

    if (locations.length) {
      const items = locations.filter((location) => location.within);

      const list = items.length ? (
        <List>
          {items.map((location) => (
            <MobileCard
              action={() => setChosen(location)}
              adornment={
                location.physical && location.distance ? (
                  <LocationDistanceIndicator
                    distance={location.distance.toFixed(1)}
                    unit={location.unit}
                  />
                ) : null
              }
              id={location.id}
              key={location.id}
              primary={location.name}
              secondary={location.physical ? location.formattedAddress : ''}
            />
          ))}
        </List>
      ) : (
        empty
      );

      return (
        <>
          <header className={classes.header}>
            <Typography
              classes={{ root: classes.title }}
              component="h1"
              variant="h5"
            >
              <FormattedMessage id="Location.select_location" />
            </Typography>
            <SearchableInput
              onChange={({ currentTarget: { value } }) => {
                fetchSuggestions(value);
              }}
              onClick={selectSuggestion}
              options={suggestions}
            />
          </header>
          {list}
        </>
      );
    }

    return empty;
  };

  return <div className={classes.root}>{content()}</div>;
};

MobileSearch.propTypes = {
  chosen: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  fetchSuggestions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(Object).isRequired,
  setLocation: PropTypes.func.isRequired,
  selectSuggestion: PropTypes.func.isRequired,
  setChosen: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.element).isRequired,
};

MobileSearch.defaultProps = {
  chosen: null,
};

export default MobileSearch;
