import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import { useMediaLayout } from 'use-media';

export const DESKTOP = 1;
export const MOBILE = 2;
export const TABLET = 3;
export const TABLET_MIN_WIDTH = 768;
export const DESKTOP_MIN_WIDTH = 1025;

const ViewModeContext = createContext(DESKTOP);

const ViewModeProvider = ({ children }) => {
  const tabletMatch = useMediaLayout({ minWidth: TABLET_MIN_WIDTH });
  const desktopMatch = useMediaLayout({ minWidth: DESKTOP_MIN_WIDTH });

  const matches = () => {
    if (desktopMatch) {
      return DESKTOP;
    }

    if (tabletMatch) {
      return TABLET;
    }

    return MOBILE;
  };

  return (
    <ViewModeContext.Provider value={matches()}>
      {children}
    </ViewModeContext.Provider>
  );
};

ViewModeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { ViewModeContext, ViewModeProvider };
