import {
  Button,
  ClickAwayListener,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import React, { Fragment, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { LOCALE_NAMES } from '../../shared/constants';
import { LocaleContext } from '../contexts/LocaleContext';
import DropDownArrow from './icons/DropDownArrow';

const useStyles = makeStyles(() => ({
  button: {
    color: 'black',
    textTransform: 'capitalize',
    '& svg': {
      fill: 'black',
    },
  },
  label: {
    alignItems: 'center',
    display: 'flex',
    fontWeoght: 400,
    color: window.state?.theme?.secondary || '#2979FF', // material themes not set up correctly
  },
  listItemIcon: {
    minWidth: '1.75rem',
    height: '1.5rem',
    right: '0.25rem',
    top: '0.25rem',
    position: 'relative',
    '& svg': {
      height: '1rem',
    },
  },
  listItemText: {
    paddingLeft: '1.75rem',
    height: '1.5rem',
  },
  listItemTextIcon: {
    display: 'flex',
  },
  popperIndex: {
    position: 'absolute',
    left: '-1rem !important',
    top: '100%',
    width: '10rem',
    zIndex: 9999,
    height: 'fit-content',
    overflow: 'scroll',
    maxHeight: '60vh',
  },
}));

const LanguagePicker = () => {
  const intl = useIntl();

  const { locale, locales, setLocale } = useContext(LocaleContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const toggle = (target) => setAnchorEl(anchorEl ? null : target);
  const handleLanguageChange = (loc) => {
    setAnchorEl(null);
    setLocale(loc);
  };
  const isSelected = (l) => l === locale;
  const listItemTextStyle = (l) =>
    isSelected(l) ? classes.listItemTextIcon : classes.listItemText;

  if (locales?.length === 1) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={() => toggle(null)}>
      <div>
        <Button
          className={classes.button}
          onClick={(e) => toggle(e.currentTarget)}
        >
          <Typography
            className={classes.label}
            component="h6"
            data-testid="language-picker-label"
            variant="body2"
          >
            {LOCALE_NAMES[locale]}
          </Typography>
          <DropDownArrow
            altText={intl.formatMessage({ id: 'Svg.alt_text.select_language' })}
          />
        </Button>

        <Popper
          anchorEl={anchorEl}
          className={classes.popperIndex}
          id="language-picker"
          open={open}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <List disablePadding>
                  {locales.map((loc) => (
                    <ListItem
                      button
                      data-testid={`language-picker-${loc}`}
                      key={loc}
                      onClick={() => handleLanguageChange(loc)}
                      selected={false}
                    >
                      <ListItemText
                        className={listItemTextStyle(loc)}
                        primary={
                          <>
                            {isSelected(loc) && (
                              <ListItemIcon className={classes.listItemIcon}>
                                <DoneIcon />
                              </ListItemIcon>
                            )}
                            <Typography
                              data-testid="language-picker-option"
                              variant="body2"
                            >
                              {LOCALE_NAMES[loc]}
                            </Typography>
                          </>
                        }
                        primaryTypographyProps={
                          isSelected(loc)
                            ? { className: classes.listItemTextIcon }
                            : null
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default LanguagePicker;
