/* eslint-disable jsx-a11y/label-has-for */
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Typography from '../Typography';
import HelpText from './HelpText';
import InputErrors from './InputErrors';
import TextInput from './TextInput';

const useStyles = createUseStyles((theme) => ({
  additional: {
    marginTop: '0.5rem',
  },
  container: {
    position: 'relative',
  },
  icon: {
    fill: theme.palette.black,
    pointerEvents: 'none',
    position: 'absolute',
    right: '0.5rem',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  input: {
    appearance: 'none',
    background: theme.palette.white,
    border: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: theme.borderRadius.sm,
    color: theme.palette.black,
    display: 'block',
    fontSize: theme.textSizes.base,
    padding: '0.75rem',
    paddingRight: '2rem',
    transition: theme.transitions.border,
    width: '100%',
    '&:focus': {
      borderColor: theme.palette.info[400],
      boxShadow: theme.shadows.input,
      outline: 0,
    },
    '&::-ms-expand': {
      display: 'none',
    },
  },
  error: {
    borderColor: theme.palette.danger[400],
  },
  label: {
    cursor: 'pointer',
    display: 'inline-block',
    marginBottom: '0.5rem',
  },
  optional: {
    marginLeft: '0.25rem',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
  },
}));

const SelectInput = ({
  additionalName,
  additionalValue,
  boldHelp,
  disabled,
  errors,
  firstOption,
  helpText,
  hideLabel,
  id,
  label,
  name,
  onBlur,
  onChange,
  optional,
  options,
  value,
  ...rest
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });
  const [selected, setSelected] = useState(null);
  const Label = hideLabel ? 'div' : 'label';
  const selectedOption =
    options && options.find((option) => value === String(option.value));

  const handleChange = (event) => {
    setSelected(event.target.selected);
  };

  return (
    <Label htmlFor={id}>
      <div
        className={classNames(
          classes.label,
          hideLabel && classes.visuallyHidden,
        )}
      >
        <Typography variant="caption1">{label}</Typography>
      </div>
      <div className={classes.container}>
        <select
          aria-required={!optional}
          className={classNames(
            classes.input,
            errors.length > 0 && classes.error,
          )}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange || handleChange}
          required={!optional}
          value={selected === null ? value : selected}
          {...rest}
        >
          <option value="">
            {firstOption !== null
              ? firstOption
              : intl.formatMessage({ id: 'SelectInput.default' })}
          </option>
          {options
            ? options.map((o) => {
                return (
                  <option key={o.value} value={o.value}>
                    {o.text}
                  </option>
                );
              })
            : null}
        </select>
        <ArrowDropDownIcon className={classes.icon} />
      </div>
      {selectedOption?.additional ? (
        <div className={classes.additional}>
          <TextInput
            hideLabel
            key={`${selectedOption.text}-${selectedOption.value}-additional`}
            label={intl.formatMessage({ id: 'Input.additional_input' })}
            name={additionalName}
            onBlur={onBlur}
            value={additionalValue}
          />
        </div>
      ) : null}
      {errors.length > 0 ? (
        <InputErrors errors={errors} />
      ) : (
        <HelpText bold={boldHelp} value={helpText} />
      )}
    </Label>
  );
};

SelectInput.propTypes = {
  additionalName: PropTypes.string,
  additionalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  boldHelp: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  firstOption: PropTypes.string,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      additional: PropTypes.bool,
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SelectInput.defaultProps = {
  additionalName: '',
  additionalValue: '',
  boldHelp: false,
  disabled: false,
  errors: [],
  firstOption: null,
  helpText: null,
  hideLabel: false,
  onBlur: null,
  onChange: null,
  optional: false,
  options: [],
  value: null,
};

export default SelectInput;
