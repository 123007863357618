import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { localizeDuration } from '../../backend/helpers/Dashboard/Durations';
import Websockets from '../../shared/helpers/Websockets';
import { LocaleContext } from '../contexts/LocaleContext';
import { WalkInContext } from '../contexts/WalkInContext';

const WaitTimeWebsocket = ({ children }) => {
  const { locale } = useContext(LocaleContext);
  const { walkIn, setWaitTime } = useContext(WalkInContext);

  useEffect(() => {
    Websockets.instance()
      .channel(`App.Models.Location.${walkIn?.attributes?.location_id}.public`)
      .listen('WaitTimeRecalculated', ({ data }) => {
        const {
          attributes: { expected_start: start },
        } = data.find((e) => parseInt(e.id, 10) === parseInt(walkIn.id, 10));
        setWaitTime(localizeDuration(start, locale));
      });

    return () => {
      Websockets.instance()
        .channel(
          `App.Models.Location.${walkIn?.attributes?.location_id}.public`,
        )
        .stopListening('WaitTimeRecalculated');
    };

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walkIn]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

WaitTimeWebsocket.propTypes = {
  children: PropTypes.element.isRequired,
};

export default WaitTimeWebsocket;
