export default {
    "Alert.error": "错误",
    "Alert.info": "信息",
    "Alert.success": "成功",
    "Alert.warning": "警告",
    "AppointmentCancelled.title": "此预约已取消",
    "BackButton.back": "后退",
    "BackButton.back_results": "返回结果",
    "CallbackRequest.callback_request_disabled": "我们目前不接受回叫请求。",
    "CallbackRequest.not_available": "不可用",
    "CallbackService.approximately": "大约",
    "CallbackService.approximately_x_minutes": "大约 :minutes  分钟",
    "CallbackService.callback_button": "叫我回来",
    "CallbackService.callback_service": ":vendor 回叫服务",
    "CallbackService.cancel_appointment": "取消预约",
    "CallbackService.cancel_appointment_confirm": "确定要取消此预约吗?无法撤消取消预约的操作。",
    "CallbackService.cancel_callback": "取消回叫",
    "CallbackService.changes_to_your_appointment": "预约更改",
    "CallbackService.curbside.radio.car_details": "您的汽车品牌、车型和颜色",
    "CallbackService.curbside.radio.curbside": "便捷服务",
    "CallbackService.curbside.radio.joining_line": "您将如何加入队列?",
    "CallbackService.curbside.radio.parking_details": "停车位编号",
    "CallbackService.curbside.radio.pickup_information": "便捷预约服务信息",
    "CallbackService.curbside.radio.walkin": "免预约服务",
    "CallbackService.curbsideservices": "便捷服务",
    "CallbackService.email": "电子邮件",
    "CallbackService.en": "英语",
    "CallbackService.es": "西班牙语",
    "CallbackService.first_name": "名字",
    "CallbackService.footer": "技术支持者",
    "CallbackService.fr": "法语",
    "CallbackService.instructions": "添加自己，以便加入今天的会谈。当员工准备好为您服务时，我们会通知您回来。",
    "CallbackService.join_by_phone": "通过电话加入: <a>{number}<\/a>",
    "CallbackService.join_the_call": "加入通话",
    "CallbackService.ko": "韩国人",
    "CallbackService.last_name": "姓氏",
    "CallbackService.location_closed": "当前已关闭",
    "CallbackService.location_closed_info": "我们的座席会在这些时间回复这些请求。",
    "CallbackService.location_info": "请直接联系该位置以获取服务。",
    "CallbackService.missing_phone_and_email": "轮到您时，我们会通知您。请提供电话号码或电子邮件地址",
    "CallbackService.no_staff_warning": "加入队列后，第一个有空的工作人员将为您服务。这可能不是您之前选择的工作人员{name}",
    "CallbackService.not_taking_callbacks": "此位置目前不接受电话回叫。",
    "CallbackService.not_taking_online_queue": "此位置目前不接受在线队列请求。",
    "CallbackService.notes": "注释",
    "CallbackService.online_queuing": "{vendorName} 在线排队",
    "CallbackService.optional": "可选",
    "CallbackService.phone": "您的电话号码",
    "CallbackService.phone_type": "电话",
    "CallbackService.pin": "PIN 码: {pin}",
    "CallbackService.pl": "抛光",
    "CallbackService.pt": "葡萄牙语",
    "CallbackService.queue_appointment_details": "队列预约详情",
    "CallbackService.required": "必填",
    "CallbackService.ru": "俄语",
    "CallbackService.sms_opt_in": "我希望收到预约短信",
    "CallbackService.sms_required": "您将通过此号码收到有关预约最新消息的短信。可能会按标准短信费率收费。",
    "CallbackService.submit_form": "提交",
    "CallbackService.successfully_added": "您的请求已添加成功",
    "CallbackService.topic": "选择一项服务",
    "CallbackService.topic_prefill": "{name}，请选择一项服务",
    "CallbackService.topic_wait_time": "{waitTime} 预计等待时间",
    "CallbackService.video_type": "视频",
    "CallbackService.walkinservices": "免预约服务",
    "CallbackService.we_will_notify_you": "当下一位就轮到您时，我们会通知您",
    "CallbackService.we_will_notify_you_turn": "轮到您时，我们会通知您",
    "CallbackService.well_call_you_back_at": "我们会使用 :phoneNumber 叫您回来",
    "CallbackService.without_updates": "不，签到但不更新",
    "CallbackService.x_minutes": "{minutes} 分钟",
    "CallbackService.you_are_next": "接下来就该叫您回来了。",
    "CallbackService.your_appointment": "您的预约",
    "CallbackService.zh": "中国人",
    "CircularProgress.loading": "正在加载",
    "Convert.body": "您可以在晚些更方便的时间预约。如果选择继续，您只会在完成预约流程后才失去队列中的位置。",
    "Convert.button": "转换为预约",
    "Convert.title": "厌倦了等待?",
    "CountrySelectInput.label": "国家\/地区",
    "Days.friday": "星期五",
    "Days.monday": "星期一",
    "Days.saturday": "星期六",
    "Days.sunday": "星期日",
    "Days.thursday": "星期四",
    "Days.tuesday": "星期二",
    "Days.wednesday": "星期三",
    "Details.confirmation_number": "确认号码",
    "Details.curbside_appointment_title": "便捷预约详情",
    "Details.curbside_details_label": "便捷预约服务信息",
    "Details.date": "日期",
    "Details.location": "位置",
    "Details.phone_call": "手机通话",
    "Details.phone_number_ending_in": "电话号码以 {number} 结尾",
    "Details.queue_appointment_details": "队列预约详情",
    "Details.service": "服务",
    "Details.staff": "员工",
    "Details.video_call": "视频通话",
    "Details.we_will_call_you": "我们将拨打您已提供的电话号码",
    "Details.we_will_send_link": "轮到您时，我们会向您发送一个加入通话的链接",
    "DetailsForm.agree_tos": "预约即表示您同意 Coconut Software 的 {terms} 和 {privacy}。",
    "DetailsForm.agree_tos_with_privacy": "预约即表示您同意 Coconut Software 的 {terms} 和 {privacy}，以及 {vendor} 的 {vendorPrivacy}。",
    "DetailsForm.agree_tos_with_tos": "预约即表示您同意 Coconut Software 的 {terms} 和 {privacy}，以及 {vendor} 的 {vendorTerms}。",
    "DetailsForm.agree_tos_with_vendor": "预约即表示您同意 Coconut Software 的 {terms} 和 {privacy}，以及 {vendor} 的 {vendorTerms} 和 {vendorPrivacy}。",
    "DetailsForm.agree_tos_with_vendor_full_privacy": "预约即表示您同意 {vendor} 的 {vendorTerms}和 {vendorPrivacy}。",
    "DetailsForm.agree_tos_with_vendor_full_privacy_policy": "预约即表示您同意 {vendor} 的 {vendorPrivacy}。",
    "DetailsForm.agree_tos_with_vendor_full_privacy_tos": "预约即表示您同意 {vendor} 的 {vendorTerms}。",
    "DetailsForm.coconut_privacy_url": "https:\/\/www.coconutsoftware.com\/privacy-policy\/",
    "DetailsForm.coconut_terms_url": "https:\/\/www.coconutsoftware.com\/terms-of-use\/",
    "DetailsForm.explicit_compliance_agreement": "我接受",
    "DetailsForm.privacy": "隐私声明",
    "DetailsForm.terms_of_use": "使用条款",
    "DetailsForm.walkin_policy_action": "",
    "EmptyState.contact_vendor": "请重试或联系我们",
    "Error": "您的请求有误",
    "Feedback.error": "请在提交前进行评分",
    "Feedback.experience": "您的体验",
    "Feedback.google_description": "我们非常重视您的反馈，我们相信这有助于其他客户进行选择。如果您有时间通过 Google 评价进行反馈以向其他客户分享您的体验，请点击下方按钮以重定向至相应网站。",
    "Feedback.google_submit": "提交 Google 评价",
    "Feedback.neutral": "中立",
    "Feedback.new_title": "反馈",
    "Feedback.rating": "评分 (必填)",
    "Feedback.response_prompt": "告诉我们该如何改进体验",
    "Feedback.send": "发送反馈",
    "Feedback.sending": "发送反馈",
    "Feedback.subtitle": "您的反馈是匿名的。我们将使用这些信息来改进预订体验。",
    "Feedback.success": "已成功发送反馈，谢谢!",
    "Feedback.thank_you": "感谢您的反馈!",
    "Feedback.title": "您对此次预约的感受如何?",
    "Feedback.very_dissatisfied": "非常不满",
    "Feedback.very_satisfied": "非常满意",
    "GenericError.subtitle": "找不到您要查找的页面",
    "GenericError.title": "哎呀，发生错误了",
    "Instructions.title": "说明",
    "Location.select_location": "选择一个位置",
    "LocationDetails.location_details": "位置详情",
    "LocationDetails.select_location": "选择此位置",
    "LocationDirectionsButton.text": "路线",
    "LocationHours.closed": "已关闭",
    "LocationHours.timespan": "{open} - {closed}",
    "LocationHours.title": "营业时长",
    "LocationList.no_locations": "未找到位置",
    "LocationList.title": "选择一个位置",
    "LocationPhoneButton.text": "呼叫 {number}",
    "LocationPhoneCallButton.text": "呼叫 {number}",
    "Manage.book_another": "另行预约",
    "Manage.restart": "从主页重启",
    "Questions.missing_both": "回答问题前请提供邮箱地址或手机号码",
    "Questions.missing_email": "请在回答问题前提供电子邮件地址",
    "Questions.missing_phone": "回答问题前请提供手机号码",
    "SelectInput.default": "选择",
    "Svg.altText.back": "后退",
    "Svg.altText.coconutLogo": "Coconut 软件标志",
    "Svg.altText.dropdown_arrow": "下拉箭头",
    "Svg.altText.location_pin": "位置 pin",
    "Svg.altText.search": "搜索",
    "Svg.altText.select_language": "选择语言",
    "Svg.alt_text.appointment": "预约",
    "Svg.alt_text.back": "后退",
    "Svg.alt_text.cancelled": "已取消",
    "Svg.alt_text.coconut_logo": "Coconut 软件标志",
    "Svg.alt_text.curbside": "便捷服务图标",
    "Svg.alt_text.dropdown_arrow": "向下箭头图标",
    "Svg.alt_text.location_error": "位置错误图标",
    "Svg.alt_text.location_pin": "位置",
    "Svg.alt_text.search": "搜索",
    "Svg.alt_text.select_language": "选择语言",
    "Svg.alt_text.up_arrow": "向上箭头图标",
    "Svg.alt_text.walk_in": "免预约服务图标",
    "Ui.cancel": "取消预约",
    "Ui.continue": "继续",
    "Ui.keep": "保持",
    "Ui.loading": "加载中",
    "Ui.lobby_disabled": "",
    "Ui.no_available_staff_inperson": "该地点目前不接受亲自上门",
    "Ui.no_available_staff_phone": "此地点目前不接受电话回拨",
    "Ui.no_available_staff_video": "该地点目前不接受视频回传",
    "Ui.private_location": "此位置是私人位置，您无法在此处加入队列",
    "Ui.search": "搜索…",
    "Ui.search.instructions": "请开始输入以进行搜索。",
    "Ui.search.plain": "搜索"
}