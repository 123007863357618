import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';
import { SELECTION_STORAGE_KEY } from '../../shared/constants';
import Item from '../../shared/helpers/Item';
import Storage from '../../shared/helpers/Storage';
import { DEFAULT_ATTENDEE_STATE } from '../constants';

const SelectionContext = createContext({});

const SelectionProvider = ({ children }) => {
  const getInitialState = () => {
    const attendee = window.state?.prefillClient;
    if (attendee) {
      Storage.set(SELECTION_STORAGE_KEY, {
        attendee: {
          ...DEFAULT_ATTENDEE_STATE,
          ...Item.keysToCamel(attendee),
        },
      });
    }

    return attendee;
  };

  const [selections, setSelections] = useState(getInitialState);

  return (
    <SelectionContext.Provider value={[selections, setSelections]}>
      {children}
    </SelectionContext.Provider>
  );
};

SelectionProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { SelectionContext, SelectionProvider };
