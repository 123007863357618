import { Box, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { LocaleContext } from '../contexts/LocaleContext';
import { LocationContext } from '../contexts/LocationContext';
import { ServiceContext } from '../contexts/ServiceContext';
import { VendorContext } from '../contexts/VendorContext';

const useStyles = createUseStyles((theme) => ({
  instructions: {
    fontSize: theme.textSizes.sm,
    // override the default of list-style: none for ul and ol to correctly show content from the rich text field
    '& ul': {
      listStyle: 'initial',
    },
    '& ol': {
      listStyle: 'decimal',
    },
    '& ul,ol': {
      listStylePosition: 'inside',
    },
  },
  instructionsTitle: {
    fontWeight: theme.fontWeights.medium,
    fontSize: theme.textSizes.lg,
    paddingBottom: '1rem',
  },
}));

const Instructions = ({
  bookingInstructions,
  showBookingInstructions,
  showServiceInstructions,
}) => {
  const classes = useStyles({ theme: useTheme() });

  const { locale } = useContext(LocaleContext);
  const { vendor } = useContext(VendorContext);
  const { service: selectedService } = useContext(ServiceContext);
  const { location } = useContext(LocationContext);

  const locInstructions = !!(
    bookingInstructions && bookingInstructions[locale]
  );

  const richTextContent = () => {
    let html = '';

    if (showBookingInstructions && locInstructions) {
      html += bookingInstructions[locale];
    }

    if (location?.instructions) {
      html += location.instructions;
    }

    return html;
  };

  return (
    <Grid alignItems="center" container direction="row" justify="center">
      <Grid item xs={12}>
        {showBookingInstructions && locInstructions ? (
          <div
            className={classes.instructions}
            dangerouslySetInnerHTML={{ __html: richTextContent() }}
          />
        ) : (
          <>
            {showBookingInstructions ? (
              <Typography
                classes={{ root: classes.instructionsTitle }}
                component="div"
                variant="h6"
              >
                <FormattedMessage
                  id="CallbackService.online_queuing"
                  values={{ vendorName: vendor.name }}
                />
              </Typography>
            ) : null}
            <Typography
              classes={{ root: classes.instructions }}
              component="div"
            >
              <>
                {showBookingInstructions ? (
                  <FormattedMessage id="CallbackService.instructions" />
                ) : null}
                {location?.instructions ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: location.instructions }}
                  />
                ) : null}
                {showServiceInstructions && selectedService?.instructions ? (
                  <Box mt={2}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedService.instructions,
                      }}
                    />
                  </Box>
                ) : null}
              </>
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

Instructions.propTypes = {
  bookingInstructions: PropTypes.objectOf(PropTypes.string),
  showBookingInstructions: PropTypes.bool,
  showServiceInstructions: PropTypes.bool,
};

Instructions.defaultProps = {
  bookingInstructions: null,
  showBookingInstructions: false,
  showServiceInstructions: false,
};

export default Instructions;
