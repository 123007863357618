import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import QueueAppointmentsApi from '../../shared/helpers/api/open/QueueAppointments';
import WalkInConversion from '../../shared/helpers/api/open/WalkInConversion';
import { QUEUE_APPOINTMENT_STATUSES } from '../constants';
import { useVendor } from '../contexts/VendorContext';
import { WalkInContext } from '../contexts/WalkInContext';
import ConfirmationDialog from './ConfirmationDialog';
import Button from './forms/Button';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginBottom: theme.typography.pxToRem(theme.spacing(1)),
    marginRight: theme.typography.pxToRem(theme.spacing(1)),
  },
}));

const AppointmentActions = ({ walkIn }) => {
  const intl = useIntl();
  const classes = useStyles();

  const { vendor } = useVendor();
  const { setWalkIn } = useContext(WalkInContext);

  const [cancelOpen, setCancelOpen] = useState(false);
  const [convertOpen, setConvertOpen] = useState(false);
  const [convertUrl, setConvertUrl] = useState('');

  useEffect(() => {
    if (walkIn) {
      WalkInConversion.get(walkIn.id, walkIn.confirm_code).then(({ data }) => {
        setConvertUrl(data.url);
      });
    }
  }, [walkIn]);

  const handleCancel = () => {
    const attributes = {
      status: QUEUE_APPOINTMENT_STATUSES.CANCELLED,
      confirm_code: walkIn?.attributes?.confirm_code,
    };

    return QueueAppointmentsApi.patch(walkIn?.attributes?.id, attributes).then(
      ({ data }) => {
        setWalkIn({
          ...walkIn,
          attributes: {
            ...walkIn.attributes,
            ...data?.data?.attributes,
          },
        });
      },
    );
  };

  const handleConvert = () => {
    window.location.assign(convertUrl);
  };

  return (
    <>
      <Grid container>
        <Grid className={classes.margin} item>
          <Button
            fullWidth={false}
            onClick={() => setCancelOpen(true)}
            type="submit"
            variant="smallOutlined"
          >
            <FormattedMessage id="CallbackService.cancel_appointment" />
          </Button>
        </Grid>
        {vendor.modern_client_view ? (
          <Grid className={classes.margin} item>
            <Button
              disabled={!convertUrl}
              fullWidth={false}
              onClick={() => setConvertOpen(true)}
              type="submit"
              variant="smallOutlined"
            >
              <FormattedMessage id="Convert.button" />
            </Button>
          </Grid>
        ) : null}
      </Grid>

      <ConfirmationDialog
        body={intl.formatMessage({
          id: 'CallbackService.cancel_appointment_confirm',
        })}
        cancelPrompt={intl.formatMessage({ id: 'Ui.keep' })}
        confirmPrompt={intl.formatMessage({
          id: 'CallbackService.cancel_appointment',
        })}
        isOpen={cancelOpen}
        onSubmit={handleCancel}
        setOpen={setCancelOpen}
        title={intl.formatMessage({ id: 'CallbackService.cancel_appointment' })}
      />
      <ConfirmationDialog
        body={intl.formatMessage({ id: 'Convert.body' })}
        isOpen={convertOpen}
        onSubmit={handleConvert}
        setOpen={setConvertOpen}
        title={intl.formatMessage({ id: 'Convert.title' })}
      />
    </>
  );
};

AppointmentActions.propTypes = {
  walkIn: PropTypes.shape({
    id: PropTypes.number,
    confirm_code: PropTypes.string,
    attributes: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
    ),
  }).isRequired,
};

export default AppointmentActions;
