import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import Formatter from '../helpers/Formatter';
import Timer from './icons/Timer';
import Typography from './Typography';

const useStyles = createUseStyles({
  waitTime: {
    display: 'flex',
    '& > span': {
      paddingLeft: '0.3125rem',
      paddingTop: '0.0625rem',
    },
  },
});

const WaitTime = ({ location }) => {
  const classes = useStyles();
  const intl = useIntl();

  const loading = Object.keys(location.waitTime).length === 0;
  const { wait = null } = location.waitTime;
  const hasWait = wait !== null;

  const message = loading ? (
    <FormattedMessage id="WaitTime.loading" />
  ) : hasWait ? (
    <FormattedMessage
      id="WaitTime.wait"
      values={{ wait: Formatter.duration(wait, intl) }}
    />
  ) : (
    <FormattedMessage id="WaitTime.no_walkins_currently" />
  );

  return (
    <div className={classes.waitTime} data-testid="wait-time-component">
      <Timer data-testid="timer" />
      <Typography data-testid="wait-time-text">{message}</Typography>
    </div>
  );
};

WaitTime.propTypes = {
  location: PropTypes.shape({
    waitTime: PropTypes.shape({ wait: PropTypes.number }),
  }).isRequired,
};

export default WaitTime;
