import React from 'react';

const LocationErrorIcon = () => (
  <svg
    fill="none"
    height="219"
    viewBox="0 0 195 219"
    width="195"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <mask
        height="208"
        id="mask0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="176"
        x="2"
        y="2"
      >
        <path
          d="M88.3865 2.92377C82.305 2.22991 58.781 -0.356415 35.3215 12.5967C30.337 15.3465 6.89013 28.3468 2.79599 48.6372C-2.54744 75.4749 31.7759 86.431 31.2898 114.139C30.9089 137.93 20.9151 139.704 16.8119 166.209C14.6773 180.014 21.1376 195.911 32.5468 203.107C68.077 225.458 161.327 188.445 175.891 115.396C186.53 62.1882 137.537 8.39175 88.3865 2.92377Z"
          fill="#FCFAFF"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect fill="#F9F9F9" height="214" width="200" y="-1" />
        <path
          d="M44.1074 57.3669L78.3506 66.8789V160.096L44.1074 150.584V57.3669Z"
          fill="#EDEDED"
        />
        <path
          d="M76.8926 66.8788L114.05 57.3668V150.584L76.8926 160.096V66.8788Z"
          fill="#D9D9D9"
        />
        <path
          d="M114.051 57.3668L146.837 66.8788V160.096L114.051 150.584V57.3668Z"
          fill="#EDEDED"
        />
        <path
          clipRule="evenodd"
          d="M114.09 85.4861L147.459 94.3846L146.219 99.0352L114.016 90.4478L76.8483 99.1933L44.5625 90.2868L45.8424 85.6471L76.9429 94.2265L114.09 85.4861Z"
          fill="black"
          fillOpacity="0.04"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M114.09 116.087L147.459 124.985L146.219 129.636L114.016 121.048L76.8483 129.794L44.5625 120.887L45.8424 116.248L76.9429 124.827L114.09 116.087Z"
          fill="black"
          fillOpacity="0.04"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M129.35 59.5527L129.35 153.539H124.537L124.537 59.5527H129.35Z"
          fill="#E4E4E4"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M59.4088 59.5528L59.4088 153.539L54.5957 153.539L54.5957 59.5528L59.4088 59.5528Z"
          fill="#E4E4E4"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M87.8219 63.9244L87.8219 157.911H83.0088L83.0088 63.9244H87.8219Z"
          fill="#D0D0D0"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M52.3057 55.0692C52.3057 48.7251 57.4367 43.5941 63.7808 43.5941C70.1248 43.5941 75.2558 48.7251 75.2558 55.0692C75.2558 61.9051 68.0101 71.3311 65.043 74.9211C64.3873 75.708 63.1906 75.708 62.5349 74.9211C59.5514 71.3311 52.3057 61.9051 52.3057 55.0692ZM59.6825 55.0693C59.6825 57.3315 61.5185 59.1676 63.7807 59.1676C66.043 59.1676 67.879 57.3315 67.879 55.0693C67.879 52.8071 66.043 50.9711 63.7807 50.9711C61.5185 50.9711 59.6825 52.8071 59.6825 55.0693Z"
          fill="#B0B0B0"
          fillRule="evenodd"
        />
      </g>
      <path
        d="M150.795 70.5232C158.485 62.8325 158.485 50.3637 150.795 42.673C143.105 34.9822 130.636 34.9822 122.945 42.673L116.981 36.7095C124.045 29.3965 134.504 26.4632 144.339 29.038C154.174 31.6121 161.855 39.2933 164.43 49.1289C167.004 58.9645 164.072 69.4237 156.759 76.4867L150.795 70.5232Z"
        fill="#C0C0C0"
      />
      <path
        d="M122.946 42.673C115.255 50.3638 115.255 62.8326 122.946 70.5233C130.637 78.2134 143.106 78.2134 150.796 70.5233L156.76 76.4868C145.722 87.1479 128.176 86.9954 117.325 76.144C106.474 65.2927 106.321 47.7471 116.982 36.7096L122.946 42.673Z"
        fill="#CCCCCC"
      />
      <path
        d="M150.797 42.6731C158.477 50.368 158.477 62.8278 150.797 70.5234C137.952 60.6232 131.97 54.4607 122.947 42.6731C130.642 34.9941 143.102 34.9941 150.797 42.6731Z"
        fill="#F9F9F9"
        fillOpacity="0.57"
        opacity="0.5"
      />
      <path
        d="M122.946 70.5231C115.267 62.8276 115.267 50.3677 122.946 42.6729C132.059 54.6467 138.211 60.7107 150.796 70.5231C143.101 78.2022 130.641 78.2022 122.946 70.5231Z"
        fill="#E9E9E9"
        opacity="0.5"
      />
      <path
        d="M162.732 82.4577L158.759 86.4308L152.387 80.0731C153.961 79.0265 155.426 77.8253 156.761 76.4867L162.732 82.4577Z"
        fill="#B5B4BA"
      />
      <path
        d="M156.76 76.4868C158.099 75.1516 159.3 73.6867 160.346 72.1125L166.704 78.484L162.731 82.4578L156.76 76.4868Z"
        fill="#ACABB1"
      />
      <path
        d="M162.731 82.4578L188.249 107.976C184.953 111.271 179.61 111.271 176.314 107.976L156.763 88.424L158.757 86.4309L162.731 82.4578Z"
        fill="#B0B0B0"
      />
      <path
        d="M166.704 78.484L168.697 76.4895L188.248 96.0405C191.544 99.3365 191.544 104.68 188.248 107.976L162.73 82.4578L166.704 78.484Z"
        fill="#A6A5AB"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect fill="white" height="219" width="195" />
      </clipPath>
    </defs>
  </svg>
);

export default LocationErrorIcon;
