import { AppBar, Toolbar, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import HomeButton from '../../shared/components/HomeButton';
import Detective from '../../shared/helpers/Detective';
import mode from '../../shared/helpers/Mode';
import LanguagePicker from '../components/LanguagePicker';
import { HEADER_HEIGHT } from '../constants';
import { useLocale } from '../contexts/LocaleContext';

const useStyles = createUseStyles((theme) => ({
  bar: {
    width: '100%',
    backgroundColor: theme.palette.white,
    alignItems: 'center',
    background: theme.palette.white,
    borderTop: `0.375rem solid ${theme.palette.primary[400]}`,
    boxShadow: theme.shadows.default,
    display: 'flex',
    flexShrink: 0,
    height: HEADER_HEIGHT.DESKTOP,
    justifyContent: 'center',
    position: Detective.notInternetExplorer11() ? 'sticky' : 'relative',
    top: 0,
    zIndex: 950,
  },
  headerText: {
    color: theme.palette.black,
    flexGrow: 1,
    lineHeight: '1 !important',
  },
  logo: {
    maxHeight: '2.625rem',
    maxWidth: '40vw',
    '& img': {
      maxHeight: '1.814rem',
    },
  },
  topAppBar: {
    boxShadow: 'none',
  },
  bottomAppBar: {
    bottom: '0 !important',
    boxShadow: 'none',
    height: '12px',
    minHeight: '12px',
    top: 'auto !important',
  },
  title: {
    textAlign: 'center',
    marginBottom: '2rem',
    marginTop: '2rem',
  },
  successText: {
    color: theme.palette.success[400],
  },
  home: {
    position: 'absolute',
    left: '1rem',
    top: '55%',
    transform: 'translateY(-50%)',
  },
  language: {
    position: 'absolute',
    right: '1.25rem',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  action: {
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    margin: 0,
    padding: 0,
    '&:first-child': {
      marginLeft: '1rem',
      marginRight: 'auto',
    },
    '&:last-child': {
      marginLeft: 'auto',
      marginRight: '1rem',
      opacity: 0,
      pointerEvents: 'none',
      visibility: 'hidden',
    },
    '& svg': {
      fill: theme.palette.neutral[400],
    },
    '&:focus': {
      '& svg': {
        fill: theme.palette.black,
      },
    },
  },
  homepage: {
    textDecoration: 'none',
    color: theme.palette.black,
  },
}));

const NavBar = () => {
  const classes = useStyles({ theme: useTheme() });
  const { logo } = window.state.theme;
  const { homepage, name } = window.state.vendor;
  const { locale } = useLocale();

  if (mode.isEmbedded()) {
    return null;
  }

  const logoImage = (
    <img
      alt={document.title}
      className={classes.logo}
      data-testid="logo"
      src={logo}
    />
  );

  const headerLogo =
    logo !== null ? (
      homepage && !mode.isKiosk() ? (
        <a
          href={homepage}
          rel="noreferrer"
          target="_blank"
          title={document.title}
        >
          {logoImage}
        </a>
      ) : (
        logoImage
      )
    ) : homepage && !mode.isKiosk() ? (
      <a
        className={classes.homepage}
        href={homepage}
        rel="noreferrer"
        target="_blank"
        title={document.title}
      >
        <Typography component="h2" variant="h6">
          {name}
        </Typography>
      </a>
    ) : (
      <span className={classes.homepage}>
        <Typography component="h2" variant="h6">
          {name}
        </Typography>
      </span>
    );

  const buttonClass = classNames(classes.action, classes.home);
  const homeButton = mode.isKiosk() ? (
    <HomeButton className={buttonClass} locale={locale} />
  ) : null;

  return (
    <AppBar className={classes.topAppBar} elevation={0} position="static">
      <Toolbar className={classes.bar}>
        {homeButton}
        {headerLogo}
        <div className={classes.language}>
          <LanguagePicker />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
