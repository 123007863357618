import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const Appointment = ({ altText, fill }) => {
  const intl = useIntl();

  return (
    <svg
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        {altText || intl.formatMessage({ id: 'Svg.alt_text.appointment' })}
      </title>
      <path
        clipRule="evenodd"
        d="M18 4h1a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V6a2 2 0 012-2h1V3a1 1 0 011-1 1 1 0 011 1v1h8V3a1 1 0 011-1 1 1 0 011 1v1zM8 11h8a1 1 0 011 1 1 1 0 01-1 1H8a1 1 0 01-1-1 1 1 0 011-1zm10 9H6a1 1 0 01-1-1V9h14v10a1 1 0 01-1 1zM8 15h5a1 1 0 011 1 1 1 0 01-1 1H8a1 1 0 01-1-1 1 1 0 011-1z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

Appointment.propTypes = {
  altText: PropTypes.string,
  fill: PropTypes.string,
};

Appointment.defaultProps = {
  altText: '',
  fill: '#FFB400',
};

export default Appointment;
