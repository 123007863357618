import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useReducer } from 'react';
import Typography from '../../../frontend/components/Typography';
import { MAP_OVERLAY } from '../../../frontend/constants';
import Map from '../../../frontend/helpers/Map';
import { useGoogleMapsApi } from '../../../shared/contexts/GoogleMapsApiContext';

let markers = {};

const options = {
  center: {
    // Rugby, North Dakota, yee haw (centre of North America)
    lat: 48.365691,
    lng: -99.99659,
  },
  zoom: 15,
  disableDefaultUI: true,
};

const MapContainer = ({
  locations,
  onClickMarker,
  onCreateMarker,
  singular,
}) => {
  const [anchor, setAnchor] = useReducer((state, newState) => newState, null);
  const [mapRef, setMapRef] = useReducer((state, newState) => newState, null);

  const { isLoaded } = useGoogleMapsApi();

  useEffect(() => {
    if (mapRef) {
      fitBounds(mapRef);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  const places = locations.filter(
    (location) => location.within && location.physical,
  );

  const addMarker = (id, marker) => {
    markers = { ...markers, [id]: marker };
    onCreateMarker(markers);
  };

  const fitBounds = (map) => {
    if (places.length > 1) {
      const bounds = Map.bounds();

      places.forEach((place) => {
        bounds.extend(Map.coordinates(place.coordinates));
      });

      map.fitBounds(bounds, {
        left: MAP_OVERLAY.WIDTH + MAP_OVERLAY.LEFT_MARGIN,
      });
    } else if (places.length === 1) {
      map.panTo(Map.coordinates(places[0].coordinates));

      if (singular) {
        map.setZoom(18);
      }
    }
  };

  const onLoad = useCallback((map) => {
    setMapRef(map);
    fitBounds(map);

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (places.length === 1) {
    options.center = places[0].coordinates;
  }

  return isLoaded ? (
    <GoogleMap
      clickableIcons={false}
      mapContainerStyle={{ height: '100%' }}
      onLoad={onLoad}
      options={options}
    >
      {places.map((place) => {
        const marker = markers[place.id];
        const onClick = () => onClickMarker(place);
        const onLoad = (marker) => addMarker(place.id, marker);
        const onMouseOver = () => {
          setAnchor(marker);
        };
        const onMouseOut = () => {
          setAnchor(null);
        };

        return (
          <Marker
            key={place.id}
            onClick={onClick}
            onLoad={onLoad}
            onMouseOut={onMouseOut}
            onMouseOver={onMouseOver}
            position={place.coordinates}
          >
            {anchor === marker && (
              <InfoWindow anchor={anchor}>
                <div>
                  <Typography variant="label">{place.name}</Typography>
                </div>
              </InfoWindow>
            )}
          </Marker>
        );
      })}
    </GoogleMap>
  ) : (
    <div data-testid="location-map" />
  );
};

MapContainer.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      coordinates: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
      id: PropTypes.number,
      name: PropTypes.string,
      physical: PropTypes.bool,
      within: PropTypes.number,
    }),
  ).isRequired,
  onClickMarker: PropTypes.func,
  onCreateMarker: PropTypes.func,
  singular: PropTypes.bool,
};

MapContainer.defaultProps = {
  onClickMarker: () => {},
  onCreateMarker: () => {},
  singular: false,
};

export default MapContainer;
