/* eslint-disable import/prefer-default-export */
export const LOCALES = {
  en: {
    formats: {
      dateMonthYear: 'MMMM DD, YYYY',
      human: 'EEEE, MMMM DD, YYYY - h:mm a',
      month: 'MMMM',
      monthYear: 'MMMM YYYY',
      weekdayDateMonthYear: 'EEEE, MMMM DD, YYYY',
    },
  },
  es: {
    formats: {
      dateMonthYear: 'DD [de] MMMM [de] YYYY',
      human: 'EEEE, DD [de] MMMM [de] YYYY - HH:mm',
      month: 'MMMM',
      monthYear: 'MMMM [de] YYYY',
      weekdayDateMonthYear: 'EEEE, DD [de] MMMM [de] YYYY',
    },
  },
  fr: {
    formats: {
      dateMonthYear: 'DD MMMM YYYY',
      human: 'EEEE DD MMMM YYYY - HH [h] mm',
      month: 'MMMM',
      monthYear: 'MMMM YYYY',
      weekdayDateMonthYear: 'EEEE DD MMMM YYYY',
    },
  },
  ko: {
    formats: {
      dateMonthYear: 'YYYY년 MMMM DD일',
      human: 'EEEE, YYYY년 MMMM DD일 A - h:mm',
      month: 'MMMM',
      monthYear: 'MMMM yyyy',
      weekdayDateMonthYear: 'EEEE, YYYY년 MMMM DD일',
    },
  },
  pl: {
    formats: {
      dateMonthYear: 'DD MMMM YYYY',
      human: 'EEEE, DD MMMM YYYY - HH:mm',
      month: 'MMMM',
      monthYear: 'MMMM yyyy',
      weekdayDateMonthYear: 'EEEE, DD MMMM YYYY',
    },
  },
  pt: {
    formats: {
      dateMonthYear: 'DD de MMMM de YYYY',
      human: 'EEEE, DD de MMMM de YYYY - HH:mm',
      month: 'MMMM',
      monthYear: 'MMMM yyyy',
      weekdayDateMonthYear: 'EEEE DD de MMMM de YYYY',
    },
  },
  ru: {
    formats: {
      dateMonthYear: 'DD MMMM YYYY г.',
      human: 'EEEE, DD MMMM YYYY г. - H:mm',
      month: 'MMMM',
      monthYear: 'MMMM YYYY',
      weekdayDateMonthYear: 'EEEE DD MMMM YYYY г.',
    },
  },
  zh: {
    formats: {
      dateMonthYear: 'YYYY年M月DD日',
      human: 'EEEE, YYYY年M月DD日 - Ah点mm分',
      month: 'MMMM',
      monthYear: 'MMMM yyyy',
      weekdayDateMonthYear: 'EEEE YYYY年M月DD日',
    },
  },
};
