import { Grid, Typography } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { LocationContext } from '../contexts/LocationContext';

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: theme.typography.pxToRem(theme.spacing(1.5)),
    whiteSpace: 'nowrap',
  },
  today: {
    color: blue[500],
  },
  weekday: {
    textTransform: 'capitalize',
  },
}));

const LocationHours = () => {
  const { location } = useContext(LocationContext);

  const intl = useIntl();
  const classes = useStyles();

  const days = location.future_open_hours;

  const timeSpan = (open, closed) => {
    if (open && closed) {
      return (
        <FormattedMessage
          id="LocationHours.timespan"
          values={{
            open: intl.formatTime(moment.tz(open, 'HHmmss', 'UTC').format(), {
              timeZone: 'UTC',
            }),
            closed: intl.formatTime(
              moment.tz(closed, 'HHmmss', 'UTC').format(),
              { timeZone: 'UTC' },
            ),
          }}
        />
      );
    }

    return <FormattedMessage id="LocationHours.closed" />;
  };

  return (
    <Grid>
      {days.map((day, index) => (
        <Grid
          className={classNames(classes.item, index === 0 && classes.today)}
          container
          key={day.weekday}
        >
          <Grid item xs={6}>
            <Typography className={classes.weekday}>
              <FormattedMessage id={`Days.${day.weekday.toLowerCase()}`} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{timeSpan(day.hours.start, day.hours.end)}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default LocationHours;
