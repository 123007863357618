/* eslint-disable import/prefer-default-export */
/**
 * @param {string} destination
 *
 * @returns {string}
 */
export const directions = (destination) =>
  `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
    destination,
  )}`;
