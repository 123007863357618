import { Grid, Box, Link } from '@material-ui/core';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { queueAppointmentStatuses } from '../../backend/constants';
import Detective from '../../shared/helpers/Detective';
import mode from '../../shared/helpers/Mode';
import { MEETING_METHODS, FULFILLMENT_METHODS } from '../constants';
import { LocaleContext } from '../contexts/LocaleContext';
import { LocationContext } from '../contexts/LocationContext';
import { ServiceContext } from '../contexts/ServiceContext';
import { MOBILE } from '../contexts/ViewModeContext';
import { WalkInContext } from '../contexts/WalkInContext';
import { LOCALES } from '../helpers/Dates';
import { directions } from '../helpers/Map';
import DetailItem from './DetailItem';
import Button from './forms/Button';
import Typography from './Typography';

const useStyles = createUseStyles(() => ({
  dialIn: {
    marginTop: '0.75rem',
  },
  offsetPadding: {
    paddingLeft: '2.375rem',
  },
  extraMargin: {
    marginRight: '0.5rem',
  },
}));

const AppointmentDetailsCard = ({ viewMode }) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const { walkIn } = useContext(WalkInContext);
  const { locale } = useContext(LocaleContext);
  const { location } = useContext(LocationContext);
  const { service } = useContext(ServiceContext);

  const givingFeedback =
    walkIn?.attributes?.status === queueAppointmentStatuses.COMPLETE;

  const meetingMethod = walkIn?.attributes?.meeting_method;
  const fulfillmentMethod = walkIn?.attributes?.fulfillment_method;
  const cellPhone = walkIn?.attributes?.client?.cell_phone_formatted || '';
  const carDetails = walkIn?.attributes?.car_details;
  const status = parseInt(walkIn?.attributes?.status, 10);

  const openPhoneCall = () => {
    window.open(`tel:${location.phone}`, '_self');
  };

  const openDirections = () => {
    window.open(
      directions(location.formatted_address),
      Detective.inIFrame()
        ? '_blank'
        : Detective.touchscreen()
        ? '_self'
        : '_blank',
    );
  };

  return (
    <Grid
      className={viewMode !== MOBILE ? classes.offsetPadding : null}
      container
      direction="column"
      item
      wrap="nowrap"
      xs={12}
    >
      <DetailItem
        label={<FormattedMessage id="Details.service" />}
        primary={service?.name || ''}
      />

      {fulfillmentMethod === FULFILLMENT_METHODS.CURBSIDE && carDetails ? (
        <DetailItem
          label={<FormattedMessage id="Details.curbside_details_label" />}
          secondary={<p>{carDetails}</p>}
        />
      ) : null}

      {meetingMethod === MEETING_METHODS.PHONE_CALL && !givingFeedback ? (
        <DetailItem
          label={<FormattedMessage id="Details.phone_call" />}
          primary={<FormattedMessage id="Details.we_will_call_you" />}
          secondary={
            <span
              aria-label={intl.formatMessage(
                { id: 'Details.phone_number_ending_in' },
                {
                  number: cellPhone
                    .substr(cellPhone.length - 3)
                    .split('')
                    .join(' '),
                },
              )}
              data-personal
            >
              {cellPhone}
            </span>
          }
        />
      ) : null}

      {givingFeedback ||
      meetingMethod === MEETING_METHODS.AT_BUSINESS ||
      !meetingMethod ? (
        <DetailItem
          label={<FormattedMessage id="Details.location" />}
          primary={location?.name}
          secondary={location?.formatted_address}
        />
      ) : null}

      {meetingMethod === MEETING_METHODS.VIDEO &&
      !givingFeedback &&
      !mode.isKiosk() ? (
        <DetailItem
          label={<FormattedMessage id="Details.video_call" />}
          secondary={
            <>
              {status === queueAppointmentStatuses.CONFIRMED ? (
                <p>
                  <FormattedMessage id="Details.we_will_send_link" />
                </p>
              ) : null}

              {parseInt(status, 10) === queueAppointmentStatuses.IN_PROGRESS ? (
                <>
                  <Box mt={0.5}>
                    <Button
                      fullWidth={false}
                      onClick={() => {
                        if (walkIn?.attributes?.meeting_link) {
                          window.open(walkIn.attributes.meeting_link, '_blank');
                        }
                      }}
                      variant="smallOutlined"
                    >
                      <FormattedMessage id="CallbackService.join_the_call" />
                    </Button>
                  </Box>

                  {walkIn.attributes.dial_in?.e164 ? (
                    <div className={classes.dialIn}>
                      <Typography component="p" variant="subtitle">
                        <FormattedMessage
                          id="CallbackService.join_by_phone"
                          values={{
                            a: (chunks) => (
                              <Link
                                href={`tel:${walkIn.attributes.dial_in.e164}`}
                              >
                                {chunks}
                              </Link>
                            ),
                            number: walkIn.attributes.dial_in.international,
                          }}
                        />
                      </Typography>
                      {walkIn.attributes.dial_in.pin ? (
                        <Typography component="p" variant="subtitle">
                          <FormattedMessage
                            id="CallbackService.pin"
                            values={{ pin: walkIn.attributes.dial_in.pin }}
                          />
                        </Typography>
                      ) : null}
                    </div>
                  ) : null}
                </>
              ) : null}
            </>
          }
        />
      ) : null}

      {walkIn?.attributes?.location &&
      walkIn?.attributes?.meeting_method === MEETING_METHODS.AT_BUSINESS &&
      !mode.isKiosk() ? (
        <Grid container>
          <Button
            className={classes.extraMargin}
            fullWidth={false}
            onClick={openDirections}
            variant="smallOutlined"
          >
            <FormattedMessage id="LocationDirectionsButton.text" />
          </Button>
          <Button
            fullWidth={false}
            onClick={openPhoneCall}
            variant="smallOutlined"
          >
            <FormattedMessage
              id="LocationPhoneButton.text"
              values={{ number: location.phone }}
            />
          </Button>
        </Grid>
      ) : null}

      <DetailItem
        label={<FormattedMessage id="Details.date" />}
        primary={moment(walkIn?.attributes?.created)
          .locale(locale)
          .format(LOCALES[locale].formats.dateMonthYear)}
      />

      {walkIn?.attributes?.user?.full_name ? (
        <DetailItem
          label={<FormattedMessage id="Details.staff" />}
          primary={walkIn?.attributes?.user?.full_name || ''}
        />
      ) : null}

      <DetailItem
        label={<FormattedMessage id="Details.confirmation_number" />}
        primary={walkIn?.attributes?.confirm_code || ''}
      />
    </Grid>
  );
};

AppointmentDetailsCard.propTypes = {
  viewMode: PropTypes.number.isRequired,
};

export default AppointmentDetailsCard;
