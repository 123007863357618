import React, { useMemo } from 'react';
import { useLocation } from 'react-router';

/**
 * Returns the URLSearchParams from the current url
 */
export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Get a value from the query string
 * @param {string} key
 * @returns {string | null}
 */
export function useQueryStringValue(key) {
  const urlSearchParams = useQuery();

  return useMemo(() => urlSearchParams.get(key), [urlSearchParams, key]);
}
