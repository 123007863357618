export default {
  "Alert.error": "Error",
  "Alert.warning": "Warning",
  "Alert.success": "success",
  "Alert.info": "Info",
  "AppointmentCancelled.title" : "This appointment has been cancelled",
  "BackButton.back": "Back",
  "BackButton.back_results": "Back to results",
  "CallbackRequest.callback_request_disabled": "We are currently not taking callback requests.",
  "CallbackRequest.not_available": "Not Available",
  "CallbackService.approximately": "Approximately",
  "CallbackService.x_minutes": "{minutes} minutes",
  "CallbackService.approximately_x_minutes": "Approximate wait time: {minutes}",
  "CallbackService.callback_button": "Call Me Back",
  "CallbackService.callback_service": "{vendor} Callback Service",
  "CallbackService.cancel_appointment": "Cancel Appointment",
  "CallbackService.cancel_appointment_confirm": "Are you sure you want to cancel this appointment? Cancelling an appointment cannot be undone.",
  "CallbackService.changes_to_your_appointment": "Changes to Your Appointment",
  "CallbackService.email": "Email",
  "CallbackService.en": "English",
  "CallbackService.es": "Spanish",
  "CallbackService.first_name": "First Name",
  "CallbackService.footer": "Powered By",
  "CallbackService.fr": "French",
  "CallbackService.pin": "PIN: {pin}",
  "CallbackService.join_by_phone": "Join by phone: <a>{number}</a>",
  "CallbackService.join_the_call": "Join the Call",
  "CallbackService.ko": "Korean",
  "CallbackService.last_name": "Last Name",
  "CallbackService.location_closed": "Currently Closed",
  "CallbackService.location_closed_info": "We are available to respond to these requests during these hours",
  "CallbackService.location_info": "Please contact the location directly for service.",
  "CallbackService.not_taking_callbacks": "This location is not taking {type} callbacks at this time.",
  "CallbackService.not_taking_online_queue": "This location is not taking Online Queue requests at this time.",
  "CallbackService.missing_phone_and_email": "We will notify you when it is your turn. Please provide either a phone number or an email",
  "CallbackService.notes": "Notes",
  "CallbackService.online_queuing": "{vendorName} Online Queuing",
  "CallbackService.instructions": "Add yourself to be seen today. When a staff member is ready to serve you, we’ll notify you to come back.",
  "CallbackService.optional": "Optional",
  "CallbackService.phone": "Mobile Number",
  "CallbackService.phone_type": "Phone",
  "CallbackService.pl": "Polish",
  "CallbackService.pt": "Portuguese",
  "CallbackService.required": "Required",
  "CallbackService.ru": "Russian",
  "CallbackService.sms_required": "You will receive text updates about your appointment through this number. Standard messaging rates may apply.",
  "CallbackService.sms_opt_in": "I want to receive text messages about my appointment",
  "CallbackService.submit_form": "Submit",
  "CallbackService.successfully_added": "We will be with you soon",
  "CallbackService.topic": "Select a Service",
  "CallbackService.topic_prefill": "{name}, select a service",
  "CallbackService.topic_wait_time": "{waitTime} Estimated Wait Time",
  "CallbackService.well_call_you_back_at": "We'll call you back at {phoneNumber}",
  "CallbackService.we_will_notify_you": "We will notify you once you are next up",
  "CallbackService.we_will_notify_you_turn": "We will notify you when it's your turn",
  "CallbackService.you_are_next": "You are up next",
  "CallbackService.your_appointment": "Your Appointment",
  "CallbackService.without_updates": "No, check in without getting updates",
  "CallbackService.video_type": "Video",
  "CallbackService.curbside.radio.parking_details": "Parking spot number",
  "CallbackService.curbside.radio.car_details": "Your car's make, model, and colour",
  "CallbackService.curbside.radio.pickup_information": "Curbside pickup information",
  "CallbackService.curbside.radio.joining_line": "How are you joining the line?",
  "CallbackService.curbside.radio.walkin": "Walk-In",
  "CallbackService.curbside.radio.curbside": "Curbside",
  "CallbackService.curbsideservices": "Curbside Services",
  "CallbackService.walkinservices": "Walk-In Services",
  "CallbackService.zh": "Chinese (Simplified)",
  "CircularProgress.loading": "Loading",
  "Convert.body": "You can book an appointment at a later date that might be more convenient. If you choose to continue, you will only lose your spot in line if you complete the appointment booking process.",
  "Convert.button": "Convert to Appointment",
  "Convert.title": "Tired of waiting?",
  "Days.sunday": "Sunday",
  "Days.monday": "Monday",
  "Days.tuesday": "Tuesday",
  "Days.wednesday": "Wednesday",
  "Days.thursday": "Thursday",
  "Days.friday": "Friday",
  "Days.saturday": "Saturday",
  "Details.confirmation_number": "Confirmation Number",
  "Details.curbside_appointment_title": "Curbside Appointment Details",
  "Details.curbside_details_label": "Curbside Pickup Information",
  "Details.date": "Date",
  "Details.location": "Location",
  "Details.phone_call": "Phone call",
  "Details.phone_number_ending_in": "Phone number ending in {number}",
  "Details.queue_appointment_details": "Appointment Details",
  "Details.service": "Service",
  "Details.staff": "Staff",
  "Details.video_call": "Video Call",
  "Details.we_will_call_you": "We will call you at the number you provided",
  "Details.we_will_send_link": "We will send you a link to join the call when it's your turn",
  "DetailsForm.walkin_policy_action": "joining the line",
  "DetailsForm.coconut_privacy_url": "https://www.coconutsoftware.com/privacy-policy/",
  "DetailsForm.coconut_terms_url": "https://www.coconutsoftware.com/terms-of-use/",
  "DetailsForm.privacy": "Privacy Notice",
  "DetailsForm.terms_of_use": "Terms of Use",
  "DetailsForm.agree_tos": "By {action}, you agree to Coconut Software's {terms} and {privacy}.",
  "DetailsForm.agree_tos_with_vendor": "By {action}, you agree to Coconut Software's {terms} and {privacy}, and {vendor}'s {vendorTerms} and {vendorPrivacy}.",
  "DetailsForm.agree_tos_with_privacy": "By {action}, you agree to Coconut Software's {terms} and {privacy}, and {vendor}'s {vendorPrivacy}.",
  "DetailsForm.agree_tos_with_tos": "By {action}, you agree to Coconut Software's {terms} and {privacy}, and {vendor}'s {vendorTerms}.",
  "DetailsForm.agree_tos_with_vendor_full_privacy": "By {action}, you agree to {vendor}'s {vendorTerms} and {vendorPrivacy}.",
  "DetailsForm.agree_tos_with_vendor_full_privacy_policy": "By {action}, you agree to {vendor}'s {vendorPrivacy}.",
  "DetailsForm.agree_tos_with_vendor_full_privacy_tos": "By {action}, you agree to {vendor}'s {vendorTerms}.",
  "DetailsForm.explicit_compliance_agreement": "I accept",
  "CountrySelectInput.label": "Country",
  "EmptyState.contact_vendor": "Please try again or contact us",
  "Error": "There was an error with your request",
  "Feedback.neutral": "Neutral",
  "Feedback.response_prompt": "Tell us how your experience could have been better.",
  "Feedback.send": "Send feedback",
  "Feedback.subtitle": "Your feedback is anonymous. We will use this information to improve the booking experience.",
  "Feedback.thank_you": "Thanks for your feedback!",
  "Feedback.title": "How was your experience booking this appointment?",
  "Feedback.google_description": "We truly value your feedback and we believe our other customers will benefit from it. If you have a few moments to let them know about your experience with us via a Google Review, click on the button below and we will redirect you.",
  "Feedback.google_submit": "Submit Google Review",
  "Feedback.very_dissatisfied": "Very Dissatisfied",
  "Feedback.very_satisfied": "Very Satisfied",
  "Feedback.rating": "Rating (required)",
  "Feedback.sending": "Sending Feedback",
  "Feedback.error": "Please provide a rating before submitting",
  "Feedback.success": "Feedback successfully sent, thank you!",
  "Feedback.new_title": "Feedback",
  "Feedback.experience": "Your Experience",
  "GenericError.subtitle": "The page you are looking for cannot be found",
  "GenericError.title": "Oops, something went wrong",
  "Instructions.title": "Instructions",
  "Location.select_location": "Select a Location",
  "LocationDetails.location_details": "Location Details",
  "LocationDetails.select_location": "Select this Location",
  "LocationDirectionsButton.text": "Directions",
  "LocationPhoneButton.text": "Call {number}",
  "LocationHours.closed": "Closed",
  "LocationHours.timespan": "{open} - {closed}",
  "LocationHours.title": "Hours of Operation",
  "LocationList.no_locations": "No Locations Found",
  "LocationList.title": "Select a Location",
  "LocationPhoneCallButton.text": "Call {number}",
  "Manage.book_another": "Book another appointment",
  "Manage.restart": "Restart from home page",
  "SelectInput.default": "Select",
  "Svg.alt_text.appointment": "Appointment",
  "Svg.alt_text.back": "Back",
  "Svg.alt_text.cancelled": "Cancelled",
  "Svg.alt_text.coconut_logo": "Coconut Software logo",
  "Svg.alt_text.curbside": "Curbside service icon",
  "Svg.alt_text.dropdown_arrow": "Down arrow icon",
  "Svg.alt_text.location_error": "Location error icon",
  "Svg.alt_text.location_pin": "Location \\",
  "Svg.alt_text.search": "Search",
  "Svg.alt_text.select_language": "Select language",
  "Svg.alt_text.up_arrow": "Up arrow icon",
  "Svg.alt_text.walk_in": "Walk in service icon",
  "Ui.cancel": "Cancel",
  "Ui.continue": "Continue",
  "Ui.keep": "Keep",
  "Ui.loading": "Loading",
  "Ui.no_available_staff_inperson": "This location is not taking in person walk-ins at this time",
  "Ui.no_available_staff_phone": "This location is not taking phone callbacks at this time",
  "Ui.no_available_staff_video": "This location is not taking video callbacks at this time",
  "Ui.private_location": "This location is private and you cannot join the line here",
  "Ui.search": "Search...",
  "Ui.search.instructions": "Please start typing to search.",
  "Ui.search.plain": "Search"
};
