import { Box, Grid, Typography } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import LocationPin from '../../frontend/components/icons/LocationPin';
import { LocationContext } from '../contexts/LocationContext';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: blue[50],
    borderRadius: theme.typography.pxToRem(theme.spacing(0.5)),
    padding: theme.typography.pxToRem(theme.spacing(1)),
  },
  icon: {
    marginRight: theme.typography.pxToRem(theme.spacing(1)),
    '& svg': {
      color: blue[500],
    },
  },
}));

const CurrentLocation = () => {
  const { location } = useContext(LocationContext);
  const classes = useStyles();

  return (
    <Grid container item xs={12}>
      <Grid className={classes.root} container item>
        <div className={classes.icon}>
          <LocationPin />
        </div>
        <Grid item xs={10}>
          <Typography>
            <Box fontWeight="bold">{location.name}</Box>
            <Box>{location.formatted_address}</Box>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CurrentLocation;
