import { getCountryCallingCode } from 'libphonenumber-js';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import Item from '../../../shared/helpers/Item';
import { POPULAR_COUNTRIES } from '../../constants';
import SelectInput from './SelectInput';

// The package currently being used to generate our phone number input requires this custom
// select component to be a class, otherwise it throws an error since it is using
// references at the current time. We may be able to put this into a functional
// component at a later date.
class CountrySelectInput extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  getCountries(options) {
    if (this.countries) {
      return this.countries;
    }
    const [popular, unpopular] = Item.partition(options, (option) =>
      POPULAR_COUNTRIES.includes(option.value),
    );
    this.countries = popular.concat(unpopular).map((option) => ({
      text: `${option.label.split(' (')[0]} (+${getCountryCallingCode(
        option.value,
      )})`,
      value: option.value,
    }));

    return this.countries;
  }

  handleChange({ currentTarget }) {
    const { onChange } = this.props;
    onChange(currentTarget.value);
  }

  render() {
    const { name, onBlur, options, value } = this.props;
    const countries = this.getCountries(options);

    return (
      <SelectInput
        hideLabel
        id="country-select-input"
        label={<FormattedMessage id="CountrySelectInput.label" />}
        name={name}
        onBlur={onBlur}
        onChange={this.handleChange}
        options={countries}
        value={value}
      />
    );
  }
}

CountrySelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.string.isRequired,
};

export default CountrySelectInput;
