import momentDurationSetup from 'moment-duration-format';
import moment from 'moment-timezone';

momentDurationSetup(moment);

const liveDurationFormats = {
  en: 'h[h] m[m] s[s]',
  es: 'h[h] m[m] s[s]',
  fr: 'h [h] m[m] s[s]',
  ko: 'h[시간] m[분] s[두번째]',
  pl: 'h[g] m[m] s[s]',
  'pt-br': 'h[h] m[m] s[s]',
  ru: 'h[ч] m[м] s[с]',
  'zh-cn': 'h[小时] m[分钟] s[秒]',
};

const expectedDurationFormats = {
  en: 'h[h] m[m]',
  es: 'h[h] m[m]',
  fr: 'h [h] m[m]',
  ko: 'h[시간] m[분]',
  pl: 'h[g] m[m]',
  'pt-br': 'h[h] m[m]',
  ru: 'h[ч] m[м]',
  'zh-cn': 'h[小时] m[分钟]',
};

/**
 * Return the localized duration based on passed in UTC datetime and locale.
 *
 * @param datetime string
 * @param locale   string
 * @returns {null|number|string}
 */
const localizeDuration = (datetime, locale) => {
  if (!datetime) {
    return null;
  }

  const diff = Math.max(moment.utc(datetime).diff(moment.utc(), 'minutes'), 0);

  return moment
    .duration(diff, 'minutes')
    .format(expectedDurationFormats[locale]);
};

export { expectedDurationFormats, liveDurationFormats, localizeDuration };
