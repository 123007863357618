import {
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { FULFILLMENT_METHODS } from '../../../backend/constants';

const useStyles = createUseStyles((theme) => ({
  icon: {
    paddingTop: '7px',
    fill: theme.palette.neutral[400],
  },
  selected: {
    fill: theme.palette.primary[400],
    color: theme.palette.primary[400],
  },
  formControl: {
    margin: '3 rem',
  },
  group: {
    marginLeft: '11px', // offset for MuiFormControlLabel
    marginRight: '0px',
    flexDirection: 'row !important',
  },
  spanGroup: {
    marginTop: '0.5rem',
  },
  hiddenRadio: {
    width: '0',
    padding: '0 !important',
    '& div': {
      display: 'none',
    },
  },
  labelSquare: {
    border: `1px solid ${theme.palette.neutral[500]}`,
    padding: '10px',
    height: '4.5rem',
  },
  labelSquareSelected: {
    border: `1px solid ${theme.palette.primary[400]}`,
    padding: '10px',
    height: '4.5rem',
  },
  labelSquareSelectedFocused: {
    border: `1px solid ${theme.palette.primary[400]}`,
    padding: '10px',
    height: '4.5rem',
    backgroundColor: `${theme.palette.primary[400]}${Math.floor(0.05 * 255)
      .toString(16)
      .padStart(2, 0)}`,
  },
  centerText: {
    textAlign: 'center',
    width: '5.25rem',
    paddingBottom: '5px',
  },
  message: {
    lineHeight: '1 !important',
  },
}));

const CurbsideRadio = ({ value, onChange }) => {
  const intl = useIntl();

  const useActiveElement = () => {
    const [active, setActive] = useState(document.activeElement);
    const handleFocusIn = () => {
      setActive(document.activeElement);
    };

    useEffect(() => {
      document.addEventListener('focusin', handleFocusIn);
      return () => {
        document.removeEventListener('focusin', handleFocusIn);
      };
    }, []);

    return active;
  };

  const theme = useTheme();
  const classes = useStyles({ theme });
  const input = useRef();
  const [isfocused, setIsFocused] = useState(false);
  const focusedElement = useActiveElement();
  const focusClass = isfocused
    ? classes.labelSquareSelectedFocused
    : classes.labelSquareSelected;

  useEffect(() => {
    setIsFocused(document.activeElement.name === 'fulfillment method');
  }, [focusedElement]);

  return (
    <FormControl className={classes.formControl} component="fieldset">
      <FormLabel component="legend">
        <FormattedMessage id="CallbackService.curbside.radio.joining_line" />
      </FormLabel>
      <span className={classes.spanGroup}>
        <RadioGroup
          aria-label="fulfillment method"
          className={classes.group}
          name="fulfillment method"
          onChange={onChange}
          value={value}
        >
          <FormControlLabel
            className={
              value === FULFILLMENT_METHODS.WALK_IN
                ? focusClass
                : classes.labelSquare
            }
            control={<Radio className={classes.hiddenRadio} inputRef={input} />}
            label={
              <div className={classes.centerText}>
                <svg
                  className={
                    value === FULFILLMENT_METHODS.WALK_IN
                      ? classNames(classes.icon, classes.selected)
                      : classes.icon
                  }
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>
                    {intl.formatMessage({ id: 'Svg.alt_text.walk_in' })}
                  </title>
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M13.5 5.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM9.8 8.9L7 23h2.1l1.8-8 2.1 2v6h2v-7.5l-2.1-2 .6-3C14.8 12 16.8 13 19 13v-2c-1.9 0-3.5-1-4.3-2.4l-1-1.6c-.4-.6-1-1-1.7-1-.3 0-.5.1-.8.1L6 8.3V13h2V9.6l1.8-.7" />
                </svg>
                <div
                  className={
                    value === FULFILLMENT_METHODS.WALK_IN
                      ? classNames(classes.text, classes.selected)
                      : classes.text
                  }
                >
                  <Typography color="inherit" variant="body2">
                    <FormattedMessage id="CallbackService.curbside.radio.walkin" />
                  </Typography>
                </div>
              </div>
            }
            value={FULFILLMENT_METHODS.WALK_IN}
          />
          <FormControlLabel
            className={
              value === FULFILLMENT_METHODS.CURBSIDE
                ? focusClass
                : classes.labelSquare
            }
            control={<Radio className={classes.hiddenRadio} inputRef={input} />}
            label={
              <div className={classes.centerText}>
                <svg
                  className={
                    value === FULFILLMENT_METHODS.CURBSIDE
                      ? classNames(classes.icon, classes.selected)
                      : classes.icon
                  }
                  enableBackground="new 0 0 24 24"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>
                    {intl.formatMessage({ id: 'Svg.alt_text.curbside' })}
                  </title>
                  <g>
                    <rect fill="none" height="24" width="24" y="0" />
                  </g>
                  <g>
                    <path d="M18.92,6.01C18.72,5.42,18.16,5,17.5,5h-11C5.84,5,5.29,5.42,5.08,6.01L3,12v8c0,0.55,0.45,1,1,1h1c0.55,0,1-0.45,1-1v-1 h12v1c0,0.55,0.45,1,1,1h1c0.55,0,1-0.45,1-1v-8L18.92,6.01z M7.5,16C6.67,16,6,15.33,6,14.5S6.67,13,7.5,13S9,13.67,9,14.5 S8.33,16,7.5,16z M16.5,16c-0.83,0-1.5-0.67-1.5-1.5s0.67-1.5,1.5-1.5s1.5,0.67,1.5,1.5S17.33,16,16.5,16z M5.81,10l1.04-3h10.29 l1.04,3H5.81z" />
                  </g>
                </svg>
                <div
                  className={
                    value === FULFILLMENT_METHODS.CURBSIDE
                      ? classNames(classes.text, classes.selected)
                      : classes.text
                  }
                >
                  <Typography
                    className={classes.message}
                    color="inherit"
                    variant="body2"
                  >
                    <FormattedMessage id="CallbackService.curbside.radio.curbside" />
                  </Typography>
                </div>
              </div>
            }
            value={FULFILLMENT_METHODS.CURBSIDE}
          />
        </RadioGroup>
      </span>
    </FormControl>
  );
};
CurbsideRadio.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CurbsideRadio;
