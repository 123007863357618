import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { SettingsContext } from '../contexts/SettingsContext';
import Button from './Button';
import LocationDirectionsButton from './LocationDirectionsButton';
import LocationHours from './LocationHours';
import LocationPhoneCallButton from './LocationPhoneCallButton';
import OldWaitTime from './OldWaitTime';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  address: {
    display: 'block',
    marginBottom: '1.25rem',
    marginTop: '1.75rem',
  },
  buttons: {
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    display: 'flex',
    flexDirection: 'row',
    padding: '1.25rem',
    '& > button': {
      flex: '1 0 40%',
      '&:not(:first-child)': {
        marginLeft: '0.5rem',
      },
    },
  },
  details: {
    background: theme.palette.white,
    overflow: 'auto',
  },
  height: {
    height: 'calc(100% - 3.75rem)',
  },
  hours: {
    padding: '1.5rem 1.25rem',
  },
  locationInfo: {
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    padding: '1.5rem 1.25rem',
  },
  name: {
    display: 'block',
    overflow: 'hidden',
  },
}));

const LocationDetails = ({
  classes: injectedClasses,
  location,
  selectLocation,
  showHours,
}) => {
  const classes = { ...useStyles({ theme: useTheme() }), ...injectedClasses };

  const { showWaitTime } = useContext(SettingsContext);

  return (
    <div
      className={classNames(classes.details, classes.height)}
      data-testid="location-details"
    >
      <div className={classes.locationInfo}>
        <Typography
          classes={{ root: classes.name }}
          component="h1"
          variant="h5"
        >
          {location.name}
        </Typography>
        {location.physical && showWaitTime ? (
          <OldWaitTime location={location} />
        ) : null}
        {location.physical ? (
          <Typography classes={{ root: classes.address }} variant="caption1">
            {location.formattedAddress}
          </Typography>
        ) : null}
        <Button
          data-testid="select-location-button"
          onClick={() => selectLocation(location)}
        >
          <FormattedMessage id="LocationDetails.select_location" />
        </Button>
      </div>
      <div className={classes.buttons}>
        <LocationDirectionsButton location={location} />
        <LocationPhoneCallButton location={location} />
      </div>
      {showHours ? (
        <div className={classes.hours}>
          <LocationHours hours={location.hours} />
        </div>
      ) : null}
    </div>
  );
};

LocationDetails.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.shape({
    name: PropTypes.string,
    physical: PropTypes.bool,
    formattedAddress: PropTypes.string,
    hours: PropTypes.objectOf(
      PropTypes.shape({ closed: PropTypes.string, open: PropTypes.string }),
    ),
  }).isRequired,
  selectLocation: PropTypes.func.isRequired,
  showHours: PropTypes.bool,
};

LocationDetails.defaultProps = {
  showHours: true,
};

export default LocationDetails;
