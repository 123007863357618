import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import PhoneNumberInput from 'react-phone-number-input';
import SmartInput from 'react-phone-number-input/smart-input';
import { DEFAULT_COUNTRY } from '../../constants';
import { MOBILE, ViewModeContext } from '../../contexts/ViewModeContext';
import Typography from '../Typography';
import CountrySelectInput from './CountrySelectInput';
import InputErrors from './InputErrors';

const useStyles = createUseStyles((theme) => ({
  container: {
    '& .react-phone-number-input__row': {
      display: 'flex',
      '& > div': {
        marginRight: '1rem',
      },
      '& .react-phone-number-input__phone': {
        flex: 'initial',
      },
    },
  },
  containerMobile: {
    // temporary, see commit message
    '& .react-phone-number-input__row': {
      display: 'flex',
      flexDirection: 'column',
      '& .react-phone-number-input__phone': {
        flex: 'initial',
      },
    },
  },
  input: {
    border: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: `${theme.borderRadius.sm} !important`,
    color: theme.palette.black,
    display: 'block',
    fontSize: theme.textSizes.base,
    height: 'auto !important',
    padding: '0.75rem !important',
    width: '100%',
    '&:focus': {
      borderColor: `${theme.palette.info[400]} !important`,
      boxShadow: theme.shadows.input,
      outline: 0,
    },
  },
  inputMobile: {
    // temporary, see commit message
    marginTop: '0.5rem',
  },
  error: {
    borderColor: theme.palette.danger[400],
  },
  label: {
    cursor: 'pointer',
    display: 'inline-block',
    marginBottom: '0.5rem',
  },
}));

/* eslint-disable jsx-a11y/label-has-for */
const PhoneInput = ({
  country,
  errors,
  id,
  label,
  name,
  onChange,
  value,
  required,
}) => {
  const classes = useStyles({ theme: useTheme() });

  const mode = useContext(ViewModeContext);

  const [val, setVal] = useState(null);

  const handleChange = (phone) => {
    setVal(phone);
    onChange({ [name]: phone });
  };

  return (
    <>
      <div className={classes.label}>
        <label htmlFor={id}>
          <Typography variant="caption1">{label}</Typography>
        </label>
      </div>
      <div>
        <PhoneNumberInput
          aria-required={required}
          className={
            mode === MOBILE ? classes.containerMobile : classes.container
          } // temporary, see commit message
          country={country || DEFAULT_COUNTRY}
          countrySelectComponent={CountrySelectInput}
          id={id}
          inputClassName={classNames(
            classes.input,
            mode === MOBILE && classes.inputMobile, // temporary, see commit message
            errors.length && classes.error,
          )}
          inputComponent={SmartInput}
          international={false}
          name={name}
          onChange={handleChange}
          required={required}
          value={val === null ? value : val}
        />
      </div>
      {!!errors.length && <InputErrors errors={errors} />}
    </>
  );
};

PhoneInput.propTypes = {
  country: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string]),
  required: PropTypes.bool,
};

PhoneInput.defaultProps = {
  errors: [],
  id: '',
  required: false,
  value: '',
};

export default PhoneInput;
